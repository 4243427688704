export interface ThemeColorsMap {
  [color: string]: string;
}

export interface PartnerAdTheme {
  colors?: ThemeColorsMap;
}

export interface PartnerAdThemeState {
  colors?: ThemeColorsMap;
  error?: string;
}

export const partnerAdThemeInitialState: PartnerAdThemeState = {};
