export const flagsInitialState: FlagsState = {};

// Values: boolean, number, string, json.
export type FlagValue = boolean | number | string | null;

export interface FlagValuesMap {
  [flagId: string]: FlagValue;
}

export type FlagVariationType = 'ab' | 'feature_flag';

export interface FlagVariation {
  campaignId: string;
  variationId: string;
  type: FlagVariationType;
}

export interface FlagsState {
  flagValuesMap?: FlagValuesMap;
  campaignVariations?: FlagVariation[];
  clientSideFlagsRetrieved?: boolean;
}
