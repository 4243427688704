import dynamic from 'next/dynamic';
import { type FunctionComponent } from 'react';

import { getAppSetting } from '../settings/appSettings';
import { canBeAddedReactScan } from './canBeAddedReactScan';

const Monitoring = dynamic(() =>
  import('react-scan/monitoring/next').then((mod) => mod.Monitoring),
);

export const ReactScanMonitoringScript: FunctionComponent = () => {
  const API_KEY = getAppSetting('REACT_SCAN_MONITORING_API_KEY');
  const addReactScan = canBeAddedReactScan();

  if (!addReactScan) {
    return null;
  }

  return (
    <Monitoring
      apiKey={API_KEY}
      url="https://monitoring.react-scan.com/api/v1/ingest"
    />
  );
};
