import { all, race, take } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';

import {
  CHECK_AUTH_TOKEN_ERROR,
  GET_CUSTOMER_OPTIN_STATUS_SUCCESS,
  GET_CUSTOMER_SEGMENT_SUCCESS,
} from '@/domains/customerManagement/auth/actions';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';

import rootMarketCustomDataSaga from './custom.saga.default';

export default function* rootCustomSaga() {
  let auth = yield* select(authSelector);

  if (!auth.hasRetrievedAuthStatus) {
    yield race([
      all([
        take(GET_CUSTOMER_SEGMENT_SUCCESS),
        take(GET_CUSTOMER_OPTIN_STATUS_SUCCESS),
      ]),
      take(CHECK_AUTH_TOKEN_ERROR),
    ]);
    auth = yield* select(authSelector);
  }

  const marketCustomData = yield* call(rootMarketCustomDataSaga, auth);

  return {
    ...(auth.app_cluster && { app_cluster: auth.app_cluster }),
    ...(auth.optin_status && {
      ...(auth.optin_status.email !== null && {
        is_email_consent: auth.optin_status.email,
      }),
      ...(auth.optin_status.sms !== null && {
        is_sms_consent: auth.optin_status.sms,
      }),
    }),
    ...(auth.buy_number_cluster && {
      buy_number_cluster: auth.buy_number_cluster,
    }),
    ...marketCustomData,
  };
}
