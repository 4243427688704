import { getAppSetting } from '@/domains/core/settings/appSettings';

export const getDomainEnvironment = (environment?: string) => {
  const env = environment || getAppSetting('ENVIRONMENT');

  if (['int', 'stg'].includes(env as string)) {
    return `${env}.`;
  }

  return '';
};
