import { useEffect, useLayoutEffect } from 'react';

/**
 * This hook aim to get warning free usage of useLayoutEffect when server-side rendering.
 *
 * WARNING:
 * Before using this, you should carefully think if usEffect would be enougth...
 * Don't use it if it's the case.
 *
 * @see https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a
 */
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;
