import abTastyReducer from './ABTasty/ABTasty.reducer';
import clientReducer from './client/client.reducer';
import { flagsReducer } from './flags/flags.reducer';
import pageReducer from './page/page.reducer';

const reducers = {
  client: clientReducer,
  page: pageReducer,
  flags: flagsReducer,
  ABTasty: abTastyReducer,
};

export default reducers;
