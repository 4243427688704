import { type FlagValuesMap, type FlagVariation } from './flags.state';

export const FETCH_AND_OVERRIDE_FLAG_VALUES_AND_FLAG_VARIATIONS =
  'core/flags/FETCH_AND_OVERRIDE_FLAG_VALUES_AND_FLAG_VARIATIONS';

export const FETCH_AND_OVERRIDE_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT =
  'core/flags/FETCH_AND_OVERRIDE_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT';

export const SET_FLAG_VALUES_AND_FLAG_VARIATIONS =
  'core/flags/SET_FLAG_VALUES_AND_FLAG_VARIATIONS';

export const SET_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT =
  'core/flags/SET_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT';

export const SET_FLAG_VALUES_AND_FLAG_VARIATIONS_ERROR_CLIENT =
  'core/flags/SET_FLAG_VALUES_AND_FLAG_VARIATIONS_ERROR_CLIENT';

/** @private */
export const SET_FLAG_VALUES = 'core/flags/SET_FLAG_VALUES';

type Payload<T> = Omit<T, 'type'>;

export interface FetchAndOverrideFlagValuesAndFlagVariationsAction {
  type: typeof FETCH_AND_OVERRIDE_FLAG_VALUES_AND_FLAG_VARIATIONS;
  accessToken: string | undefined;
  flagOverrideCookieValue: string | undefined;
  visitorId: string;
}

export const fetchAndOverrideFlagValuesAndFlagVariationsAction = (
  payload: Payload<FetchAndOverrideFlagValuesAndFlagVariationsAction>,
): FetchAndOverrideFlagValuesAndFlagVariationsAction => ({
  type: FETCH_AND_OVERRIDE_FLAG_VALUES_AND_FLAG_VARIATIONS,
  ...payload,
});

export interface FetchAndOverrideFlagValuesAndFlagVariationsClientAction {
  type: typeof FETCH_AND_OVERRIDE_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT;
  flagOverrideCookieValue: string | undefined;
  visitorId: string;
}

export const fetchAndOverrideFlagValuesAndFlagVariationsClientAction = (
  payload: Payload<FetchAndOverrideFlagValuesAndFlagVariationsClientAction>,
): FetchAndOverrideFlagValuesAndFlagVariationsClientAction => ({
  type: FETCH_AND_OVERRIDE_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT,
  ...payload,
});

export interface SetFlagValuesAndFlagVariationsAction {
  type: typeof SET_FLAG_VALUES_AND_FLAG_VARIATIONS;
  flagValuesMap: FlagValuesMap;
  campaignVariations: FlagVariation[];
}

export const setFlagValuesAndFlagVariationsAction = (
  payload: Payload<SetFlagValuesAndFlagVariationsAction>,
): SetFlagValuesAndFlagVariationsAction => ({
  type: SET_FLAG_VALUES_AND_FLAG_VARIATIONS,
  ...payload,
});

export interface SetFlagValuesAndFlagVariationsClientAction {
  type: typeof SET_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT;
  flagValuesMap: FlagValuesMap;
  campaignVariations: FlagVariation[];
}

export const setFlagValuesAndFlagVariationsClientAction = (
  payload: Payload<SetFlagValuesAndFlagVariationsAction>,
): SetFlagValuesAndFlagVariationsClientAction => ({
  type: SET_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT,
  ...payload,
});

export interface SetFlagValuesAndFlagVariationsClientErrorAction {
  type: typeof SET_FLAG_VALUES_AND_FLAG_VARIATIONS_ERROR_CLIENT;
  error: Error;
}

export const setFlagValuesAndFlagVariationsClientErrorAction = (
  error: Error,
): SetFlagValuesAndFlagVariationsClientErrorAction => ({
  type: SET_FLAG_VALUES_AND_FLAG_VARIATIONS_ERROR_CLIENT,
  error,
});

export interface SetFlagValues {
  type: typeof SET_FLAG_VALUES;
  values: FlagValuesMap;
}

export const setFlagValues = (payload: Payload<SetFlagValues>) => ({
  type: SET_FLAG_VALUES,
  ...payload,
});

export type FlagsAction =
  | FetchAndOverrideFlagValuesAndFlagVariationsAction
  | SetFlagValuesAndFlagVariationsAction
  | SetFlagValuesAndFlagVariationsClientAction
  | SetFlagValuesAndFlagVariationsClientErrorAction
  | SetFlagValues;
