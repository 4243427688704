import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';

export const INIT_HEADER_BANNERS = 'productDiscovery/headerBanner/INIT';

export const FETCHING_HEADER_BANNERS = 'productDiscovery/headerBanner/FETCHING';
export const FETCH_HEADER_BANNERS_SUCCESS =
  'productDiscovery/headerBanner/FETCH_SUCCESS';
export const FETCH_HEADER_BANNERS_FAILED =
  'productDiscovery/headerBanner/FETCH_FAILED';

export interface InitHeaderBanners {
  type: typeof INIT_HEADER_BANNERS;
}

export const initHeaderBanners = (): InitHeaderBanners => ({
  type: INIT_HEADER_BANNERS,
});

export interface FetchingHeaderBanners {
  type: typeof FETCHING_HEADER_BANNERS;
}

export interface FetchHeaderBannersSuccess {
  type: typeof FETCH_HEADER_BANNERS_SUCCESS;
  campaigns?: BannerCampaign[];
}

export interface FetchHeaderBannersFailed {
  type: typeof FETCH_HEADER_BANNERS_FAILED;
  error: string;
}

export type Action =
  | InitHeaderBanners
  | FetchingHeaderBanners
  | FetchHeaderBannersSuccess
  | FetchHeaderBannersFailed;
