import { useRouter } from 'next/router';
import { useEffect, type FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { URL_CHANGE } from '../RoutingBroadcast.actions';

export const RoutingBroadcaster: FunctionComponent = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    function handleRouteChange(url: string): void {
      dispatch({
        type: URL_CHANGE,
        url,
      });
    }
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [dispatch, router.events]);

  return null;
};
