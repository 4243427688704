import { combineReducers, type Reducer } from 'redux';

type ReducerValue = Record<string, Reducer> | Reducer;

type ReducerTreeValue = Record<string, Reducer> | ReducerTree;

interface ReducerTree {
  [key: string]: Reducer | ReducerTreeValue;
}

class ReducerRegistry {
  readonly registry: Map<string, ReducerValue>;
  private dirty: boolean;
  private tree: ReducerTree;

  constructor() {
    this.registry = new Map();
    this.dirty = false;
    this.tree = {};
  }

  public registerReducer(path: string, reducer: ReducerValue) {
    this.registry.set(path, reducer);
    this.dirty = true;
  }

  public deregisterReducer(path: string) {
    this.registry.delete(path);
    this.dirty = true;
  }

  public hasReducer(path: string) {
    return this.registry.has(path);
  }

  private pathToObject(pSegments: string[], assignee: ReducerValue) {
    const segments = [...pSegments];
    const segment = segments.shift();

    // if segment is the direct parent
    if (segment && segments.length === 1) {
      this.tree[segment] = { ...this.tree[segment], [segments[0]]: assignee };
    }
    // if segment is the tail, assign it
    if (segment && segments.length === 0) {
      // node already exists replace it
      this.tree[segment] = assignee;
    }
  }

  private getCombinedTree() {
    return Object.fromEntries(
      Object.entries(this.tree).map(([key, value]) => [
        key,
        typeof value === 'function'
          ? value
          : combineReducers(value as Record<string, Reducer>),
      ]),
    );
  }

  private buildReducerTree() {
    this.tree = {};
    this.registry.forEach((value, key) => {
      const keys = key.split('.');
      this.pathToObject(keys, value);
    });
  }

  get reducerTree() {
    if (this.dirty) {
      this.buildReducerTree();
      this.dirty = false;
    }
    return { ...this.tree };
  }

  get rootReducer() {
    if (this.dirty) {
      this.buildReducerTree();
      this.dirty = false;
    }
    return combineReducers(this.getCombinedTree());
  }
}

export default ReducerRegistry;
