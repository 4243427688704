import type { PageState } from '@/core/page/page.state';
import type { PageStatusCode } from '@/domains/core/page/interfaces/PageStatusCode';

export const SET_PAGE_DATA = 'core/page/SET_PAGE_DATA';
export const SET_PAGE_REFERER = 'core/page/SET_PAGE_REFERER';
export const SET_PAGE_STATUS_CODE = 'core/page/SET_PAGE_STATUS_CODE';

export type SetPageData = {
  type: typeof SET_PAGE_DATA;
} & PageState;

export interface SetPageReferer {
  type: typeof SET_PAGE_REFERER;
  referer: string;
}

export interface SetPageStatusCode {
  type: typeof SET_PAGE_STATUS_CODE;
  statusCode: PageStatusCode;
}

export type PageAction = SetPageData | SetPageReferer | SetPageStatusCode;
