import {
  call,
  put,
  race,
  select,
  take,
  takeLatest,
  type RaceEffect,
  type SelectEffect,
  type TakeEffect,
} from 'redux-saga/effects';

import {
  FETCH_AND_OVERRIDE_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT,
  setFlagValuesAndFlagVariationsClientAction,
  setFlagValuesAndFlagVariationsClientErrorAction,
  type FetchAndOverrideFlagValuesAndFlagVariationsClientAction,
} from '@/domains/core/flags/flags.actions';
import { fetchFlagValuesAndFlagVariations } from '@/domains/core/flags/utils/fetchFlagValuesAndFlagVariations';
import { parseFlagsOverrideCookieValue } from '@/domains/core/flags/utils/flagsOverrideCookie';
import logger from '@/domains/core/observability/logger';
import {
  CHECK_AUTH_TOKEN_ERROR,
  CHECK_AUTH_TOKEN_SUCCESS,
} from '@/domains/customerManagement/auth/actions';
import type { Auth } from '@/domains/customerManagement/auth/model';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';

function safeExecute<T>(fn: () => T): T | undefined {
  try {
    return fn();
  } catch (error) {
    logger.error(error);
  }
}

export function* waitForAndGetAuthSaga(): Generator<
  SelectEffect | RaceEffect<TakeEffect>,
  Auth,
  ReturnType<typeof authSelector>
> {
  let auth = yield select(authSelector);

  if (!auth.hasRetrievedAuthStatus) {
    yield race([take(CHECK_AUTH_TOKEN_SUCCESS), take(CHECK_AUTH_TOKEN_ERROR)]);

    auth = yield select(authSelector);
  }

  return auth;
}

/** @private */
export function* fetchClientFlagValuesAndFlagVariationsSaga(
  action: FetchAndOverrideFlagValuesAndFlagVariationsClientAction,
): Generator {
  try {
    const { userId } = (yield call(waitForAndGetAuthSaga)) as Auth;

    const flagValuesAndFlagVariations = (yield call(
      fetchFlagValuesAndFlagVariations,
      action.visitorId,
      userId?.toString(),
    )) as Awaited<ReturnType<typeof fetchFlagValuesAndFlagVariations>>;

    const flagOverrides =
      safeExecute(() =>
        parseFlagsOverrideCookieValue(action.flagOverrideCookieValue || '{}'),
      ) ?? {};

    yield put(
      setFlagValuesAndFlagVariationsClientAction({
        flagValuesMap: {
          ...flagValuesAndFlagVariations.flags,
          ...flagOverrides,
        },
        campaignVariations: flagValuesAndFlagVariations.campaignVariations,
      }),
    );
  } catch (error) {
    logger.error(error);
    setFlagValuesAndFlagVariationsClientErrorAction(error);
  }
}

export function* clientFlagsSaga() {
  yield takeLatest(
    FETCH_AND_OVERRIDE_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT,
    fetchClientFlagValuesAndFlagVariationsSaga,
  );
}
