import type { CustomerResponse } from '@/domains/customerManagement/customerProfile/model';
import type { CustomerProfile } from '@/domains/customerManagement/interfaces/Customer';

export const toCustomerProfile = (data: CustomerResponse): CustomerProfile => ({
  id: data.id,
  uuid: data.uuid,
  firstName: data.firstName,
  fiscalNumber: data.fiscalNumber,
  lastName: data.lastName,
  email: data.email,
  platform: data.platform,
  timeZone: data.timeZone,
  language: data.language,
  receiveOffers: data.receiveOffers,
  receiveSmsOffers: data.receiveSmsOffers,
  receiveOffersPhoneNumber: data.phone,
});
