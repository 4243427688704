import { ABTastyFormatType } from '@/domains/core/ABTasty/ABTasty.model';

export type CampaignVariationsDataFormatType = ABTastyFormatType;

export type LegacyCampaignVariationsData = {
  campaign_id: string;
  variation_id: string;
};

export type NewCampaignVariationsData = `${string}_${string}`;

export type CampaignVariationsData<
  FormatType extends CampaignVariationsDataFormatType = any,
> = {
  campaign_variations: (FormatType extends 'current'
    ? LegacyCampaignVariationsData
    : NewCampaignVariationsData)[];
};

export type CustomData = CampaignVariationsData & Record<string, unknown>;

export const customDataNewFormatFlag = 'enableCustomDataNewFormat';
export const eventsWithPurposes = 'eventsWithPurposesEnabled';
