import { useEffect } from 'react';

import { Gtm } from '@/domains/core/tracking/utils/Gtm';

export function useBatchTracking(enabled: boolean) {
  useEffect(() => {
    const abortController = new AbortController();

    if (enabled) {
      Gtm.addEventListener(
        'interaction_product detail',
        (event) => {
          window.batchSDK?.((api) => {
            api.trackEvent('visited_article', {
              attributes: {
                name: event.pr_products.at(0)?.pr_name ?? '',
                brand: event.pr_products.at(0)?.pr_brand_name ?? '',
                category:
                  event.pr_products.at(0)?.pr_category_level_name_1 ?? '',
                amount: event.pr_products.at(0)?.pr_price ?? 0,
                id: event.pr_products.at(0)?.pr_product_id ?? '-1',
                link: window.location.href,
                $label: '',
                $tags: [],
              },
            });
          });
        },
        ['personalisedAdvertising'],
        abortController.signal,
      );
      Gtm.addEventListener(
        'interaction_add to cart',
        (event) => {
          window.batchSDK?.((api) => {
            api.trackEvent('added_item_cart', {
              attributes: {
                name: event.pr_products.at(0)?.pr_name ?? '',
                brand: event.pr_products.at(0)?.pr_brand_name ?? '',
                category: event.pr_products.at(0)?.pr_category_name ?? '',
                amount: event.pr_products.at(0)?.pr_price ?? 0,
                id: event.pr_products.at(0)?.pr_product_id ?? '-1',
                link: window.location.href,
                $label: '',
                $tags: [],
              },
            });
          });
        },
        ['personalisedAdvertising'],
        abortController.signal,
      );
      Gtm.addEventListener(
        'interaction_remove from cart',
        (event) => {
          window.batchSDK?.((api) => {
            api.trackEvent('emptied_cart', {
              attributes: {
                name: event.pr_products.at(0)?.pr_name ?? '',
                id: event.pr_products.at(0)?.pr_product_id ?? '-1',
                $label: '',
                $tags: [],
              },
            });
          });
        },
        ['personalisedAdvertising'],
        abortController.signal,
      );
    }

    return () => {
      abortController.abort();
    };
  }, [enabled]);
}
