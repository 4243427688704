import type { Auth } from '@/domains/customerManagement/auth/model';

export default function rootCustomDataSaga(auth: Auth) {
  return {
    ...(auth.cluster && { b2c_cluster: auth.cluster }),
    ...(auth.suspected_pro_type && {
      suspected_pro_type: auth.suspected_pro_type,
    }),
    ...(auth.last_chance_churn && {
      last_chance_churn: auth.last_chance_churn,
    }),
    ...(auth.anti_churn && { anti_churn: auth.anti_churn }),
  };
}
