import {
  severityLowToHighOrder,
  type AlertMessage,
} from '../FlashMessages.actions';

export function insertAlertMessage(
  alertMessagesRecord: Record<number, AlertMessage[]>,
  alertMessage: AlertMessage,
): Record<number, AlertMessage[]> {
  const curPriority = severityLowToHighOrder.indexOf(alertMessage.severity);
  const newPriorityQueue = { ...alertMessagesRecord };
  if (Object.prototype.hasOwnProperty.call(alertMessagesRecord, curPriority)) {
    const foundIndex = newPriorityQueue[curPriority].findIndex(
      (existingAlertMessage) => existingAlertMessage.id === alertMessage.id,
    );
    if (foundIndex >= 0) {
      newPriorityQueue[curPriority] = [...newPriorityQueue[curPriority]];
      newPriorityQueue[curPriority] = [
        ...newPriorityQueue[curPriority],
        alertMessage,
      ];
    } else {
      newPriorityQueue[curPriority].push(alertMessage);
    }
  } else {
    newPriorityQueue[curPriority] = [alertMessage];
  }
  return newPriorityQueue;
}
