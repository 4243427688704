import type { Task } from 'redux-saga';
import { v4 } from 'uuid';

interface RegistryEntry {
  task: Task;
  subscriberIds: string[];
}

export const SAGA_INJECTED = 'sagaInjected';
export const SAGA_CANCELED_AND_REMOVED = 'sagaCanceledAndRemoved';

export type SagaEvent = SagaInjectedEvent | SagaCanceledAndRemovedEvent;

export interface SagaInjectedEvent {
  type: typeof SAGA_INJECTED;
  sagaKey: string;
}

export interface SagaCanceledAndRemovedEvent {
  type: typeof SAGA_CANCELED_AND_REMOVED;
  sagaKey: string;
}

class SagaRegistry {
  readonly registry: Map<string, RegistryEntry>;

  private runSaga: (saga: () => Generator) => Task;

  readonly sagaInjectedHandlers: Map<string, Array<() => void>> = new Map();

  readonly sagaCancelAndRemovedHandlers: Map<string, Array<() => void[]>> =
    new Map();

  constructor(
    runSaga: (saga: () => Generator) => Task,
    rootSaga: () => Generator,
  ) {
    this.registry = new Map<string, RegistryEntry>();
    this.runSaga = runSaga;
    this.injectSaga('root', rootSaga, v4());
  }

  public isInjected(key: string) {
    return this.registry.has(key);
  }

  private addSubscriber(key: string, subscriberId: string) {
    const entry = this.registry.get(key);
    if (entry !== undefined) {
      this.registry.set(key, {
        task: entry.task,
        subscriberIds: Array.from(
          new Set([...entry.subscriberIds, subscriberId]), // Add and dedup
        ),
      });
    }
  }

  public injectSaga(
    key: string,
    saga: () => Generator,
    subscriberId: string,
  ): void {
    // We won't run saga if it is already injected
    if (this.isInjected(key)) {
      // add subscriber for already injected saga
      this.addSubscriber(key, subscriberId);
    } else {
      // Sagas return task when they executed, which can be used
      // to cancel them
      // Save the task if we want to cancel it in the future
      const task = this.runSaga(saga);
      this.registry.set(key, {
        task,
        subscriberIds: [subscriberId],
      });
      this.fireEvent({ type: SAGA_INJECTED, sagaKey: key });
    }
  }

  public addEventListener(event: SagaEvent, fn: () => void) {
    switch (event.type) {
      case 'sagaInjected':
        this.addHandlerToMap(this.sagaInjectedHandlers, event.sagaKey, fn);
        break;
      case 'sagaCanceledAndRemoved':
        this.addHandlerToMap(
          this.sagaCancelAndRemovedHandlers,
          event.sagaKey,
          fn,
        );
        break;
      default:
        break;
    }
  }

  public removeEventListener(event: SagaEvent, handler: () => void) {
    switch (event.type) {
      case 'sagaInjected':
        this.removeHandlerFromMap(
          this.sagaInjectedHandlers,
          event.sagaKey,
          handler,
        );
        break;
      case 'sagaCanceledAndRemoved':
        this.removeHandlerFromMap(
          this.sagaCancelAndRemovedHandlers,
          event.sagaKey,
          handler,
        );
        break;
      default:
        break;
    }
  }

  private removeHandlerFromMap = (
    map: Map<string, Array<() => void>>,
    key: string,
    handler: () => void,
  ) => {
    const filteredHandlers = map.get(key)?.filter((h) => h !== handler);
    if (filteredHandlers?.length) {
      map.set(key, filteredHandlers);
    } else {
      map.delete(key);
    }
  };

  private addHandlerToMap = (
    map: Map<string, Array<() => void>>,
    key: string,
    handler: () => void,
  ) => {
    if (map.has(key)) {
      map.get(key)?.push(handler);
    } else {
      map.set(key, [handler]);
    }
  };

  private fireEvent(event: SagaEvent) {
    switch (event.type) {
      case 'sagaInjected':
        this.sagaInjectedHandlers.forEach((handlers, key) => {
          if (key === event.sagaKey) handlers.map((handler) => handler());
        });
        break;
      case 'sagaCanceledAndRemoved':
        this.sagaCancelAndRemovedHandlers.forEach((handlers, key) => {
          if (key === event.sagaKey) handlers.map((handler) => handler());
        });
        break;

      default:
        break;
    }
  }

  public unsubscribe(key: string, subscriberId: string) {
    const entry = this.registry.get(key);

    if (entry !== undefined) {
      const subscriberIds = [...entry.subscriberIds];
      // copy the entry
      const nextEntry = {
        task: entry.task,
        subscriberIds,
      };

      // remove subscriber
      if (subscriberIds.length && subscriberIds.includes(subscriberId)) {
        nextEntry.subscriberIds = subscriberIds.filter(
          (sub) => sub !== subscriberId,
        );
      }

      if (nextEntry.subscriberIds.length) {
        // Update the entry if there are still subscribers
        this.registry.set(key, nextEntry);
      } else {
        // Auto delete the entry if no more subscribers
        this.cancelAndRemoveSaga(key);
      }
    }
  }

  private cancelAndRemoveSaga(key: string) {
    const entry = this.registry.get(key);
    entry?.task?.cancel();
    this.registry.delete(key);
    this.fireEvent({ type: SAGA_CANCELED_AND_REMOVED, sagaKey: key });
  }
}

export default SagaRegistry;
