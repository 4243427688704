import type {
  DocumentNode,
  GraphQLError,
  OperationDefinitionNode,
} from 'graphql';
import {
  AnyVariables,
  CombinedError,
  DocumentInput,
  type TypedDocumentNode,
} from 'urql';

import {
  GraphQLClient,
  GraphQLOperationContext,
} from '@/core/graphqlClient/GraphQLClient';
import { HttpStatusCode } from '@/domains/core/httpClient/HttpStatusCode';
import logger from '@/domains/core/observability/loggers/serverSideLogger';

const gqlClient = new GraphQLClient();

export const { client } = gqlClient;

export interface GraphQLQueryError extends Error {
  name: string;
  message: string;
  graphQLErrors: Omit<GraphQLError, 'originalError'>[];
  networkError: {
    name?: string;
    message?: string;
  } | null;
  isGraphQLQueryError: true;
}

export interface QueryResult<DataType> {
  /**
   * The adjusted HTTP STATUS code, the page should display
   * It is based on the query result
   */
  statusCode: number;
  /**
   * The query result data.
   * If the query has failed it'll be null
   */
  data: DataType | null;
  /**
   * The combined errors of the query.
   */
  error: GraphQLQueryError | null;
  /**
   * Indicate if any part of the query failed.
   * You can have errors and still have data when an optional federated call fails
   */
  hasAnyError: boolean;
  /**
   * Indicated that the query fully failed and wasn't able to return any data
   */
  fullyFailed: boolean;
}

export const isGraphQLQueryError = (error: any): error is GraphQLQueryError =>
  error != null &&
  typeof error === 'object' &&
  error.isGraphQLQueryError === true;

const getCleanedUpError = (
  operation: DocumentInput,
  error?: CombinedError | undefined,
): GraphQLQueryError | null => {
  const cleanedUpError: GraphQLQueryError | null = error
    ? {
        name: error.name,
        message: error.message,
        graphQLErrors: error.graphQLErrors,
        networkError: error.networkError
          ? {
              name: error.networkError?.name,
              message: error.networkError?.message,
            }
          : null,
        isGraphQLQueryError: true,
      }
    : null;

  if (cleanedUpError) {
    logger.error(
      `Error while calling ${
        typeof operation === 'string'
          ? operation
          : JSON.stringify(
              (operation.definitions[0] as OperationDefinitionNode)?.name
                ?.value,
            )
      }: ${JSON.stringify(cleanedUpError)}`,
    );
  }

  return cleanedUpError;
};

const getHttpStatusCode = (
  data?: any,
  error?: GraphQLQueryError | null,
  defaultHttpStatusCode: HttpStatusCode = HttpStatusCode.NOT_FOUND,
) => {
  if (data) return HttpStatusCode.OK;
  if (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors.some(({ message }: { message: string }) =>
      message.includes('timeout'),
    )
  )
    return HttpStatusCode.GATEWAY_TIMEOUT_ERROR;
  if (error) return HttpStatusCode.INTERNAL_ERROR;
  return defaultHttpStatusCode;
};

export const executeQuery = async <
  Data,
  Variables extends AnyVariables = AnyVariables,
>(
  query: DocumentInput<Data, Variables>,
  variables?: Variables,
  context?: GraphQLOperationContext,
): Promise<QueryResult<Data>> => {
  const queryResult = await gqlClient.query(query, variables, context);

  const cleanedUpError = getCleanedUpError(query, queryResult?.error);

  return {
    statusCode: queryResult
      ? getHttpStatusCode(queryResult.data, cleanedUpError)
      : HttpStatusCode.NOT_FOUND,
    data: queryResult?.data || null,
    error: cleanedUpError,
    hasAnyError: !!cleanedUpError,
    fullyFailed: !!cleanedUpError && !queryResult?.data,
  };
};

export const queryData = async <
  Data,
  Variables extends AnyVariables = AnyVariables,
>(
  query: DocumentInput<Data, Variables>,
  variables?: Variables,
  headers?: HeadersInit,
) => {
  const context = headers ? { headers: { ...headers } } : undefined;

  const result = await executeQuery(query, variables, context);

  if (result?.hasAnyError) {
    throw result.error;
  }
  return result?.data;
};

export interface FetchedResult<T> {
  data?: T | null;
  statusCode: HttpStatusCode;
}

export const fetchData = async <DataType, TransformedDataType = DataType>(
  query: string | DocumentNode | TypedDocumentNode<any, any>,
  variables?: any,
  {
    acceptPartialResult = false,
    processor = (data: QueryResult<DataType>) =>
      data as unknown as QueryResult<TransformedDataType>,
    ...context
  }: {
    acceptPartialResult?: boolean;
    processor?: (
      data: QueryResult<DataType>,
    ) =>
      | QueryResult<TransformedDataType>
      | Promise<QueryResult<TransformedDataType>>;
  } & GraphQLOperationContext = {},
): Promise<FetchedResult<TransformedDataType>> =>
  executeQuery<DataType>(query, variables, context)
    .then(processor)
    .then((result) => {
      let { statusCode, data } = result;

      if (
        (acceptPartialResult && result?.fullyFailed) ||
        (!acceptPartialResult && result?.hasAnyError)
      ) {
        logger.error(JSON.stringify(result.error));
        data = null;
        statusCode = HttpStatusCode.INTERNAL_ERROR;
      } else if (
        !Object.keys(data || {}).length &&
        statusCode === HttpStatusCode.OK
      ) {
        data = null;
        statusCode = HttpStatusCode.NOT_FOUND;
      }

      return {
        data,
        statusCode,
      };
    })
    .catch((error) => {
      logger.error(error);
      return {
        data: null,
        statusCode: HttpStatusCode.INTERNAL_ERROR,
      };
    });

export const executeMutation = async <
  Data,
  Variables extends AnyVariables = AnyVariables,
>(
  mutation: DocumentInput<Data, Variables>,
  variables?: Variables,
  context?: GraphQLOperationContext,
): Promise<QueryResult<Data>> => {
  const mutationResult = await gqlClient.mutation(mutation, variables, context);

  const cleanedUpError = getCleanedUpError(mutation, mutationResult?.error);

  return {
    statusCode: mutationResult
      ? getHttpStatusCode(
          mutationResult.data,
          cleanedUpError,
          HttpStatusCode.NO_CONTENT,
        )
      : HttpStatusCode.NOT_FOUND,
    data: mutationResult?.data || null,
    error: cleanedUpError,
    hasAnyError: !!cleanedUpError,
    fullyFailed: !!cleanedUpError && !mutationResult?.data,
  };
};
