import { get, remove, set } from 'js-cookie';

import { Cookies } from '@/domains/core/httpClient/cookies';
import { getCookieDomain } from '@/domains/core/httpClient/getCookieDomain';

import { IS_LOCAL } from '../../platform/constants';
import { getAppSetting } from '../../settings/appSettings';

/**
 * Creates mm_visitor_id tracking cookie if it does not exist yet.
 * Also manages the conversion of old manomano_ab_test_id to mm_visitor_id.
 */
export async function checkVisitorIdCookie() {
  const manomanoAbTestId = get(Cookies.MANOMANO_AB_TEST_ID);

  if (manomanoAbTestId) {
    remove(Cookies.MANOMANO_AB_TEST_ID);
  } else {
    const visitorIdFromCookie = get(Cookies.MM_VISITOR_ID);
    if (visitorIdFromCookie) {
      return visitorIdFromCookie;
    }
  }

  const visitorId = manomanoAbTestId || (await import('uuid')).v4();
  const now = new Date();
  const env = getAppSetting('ENVIRONMENT');

  set(Cookies.MM_VISITOR_ID, visitorId, {
    domain: getCookieDomain(env),
    expires: new Date(now.setFullYear(now.getFullYear() + 1)),
    path: '/',
    secure: !IS_LOCAL,
  });

  if (manomanoAbTestId) {
    window.location.reload();
  }

  return visitorId;
}
