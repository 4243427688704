import type { ABTastyActions } from '@/domains/core/ABTasty/ABTasty.actions';
import type { ABTastyState } from '@/domains/core/ABTasty/ABTasty.state';

import {
  ABTASTY_SCRIPT_CANCELLED,
  ABTASTY_SCRIPT_LOADED,
  ABTASTY_SCRIPT_LOADING,
} from './ABTasty.actions';
import { ABTASTY_TAG_STATES, abTastyInitialState } from './ABTasty.state';

export default function abTastyReducer(
  state: ABTastyState = abTastyInitialState,
  action: ABTastyActions,
) {
  switch (action.type) {
    case ABTASTY_SCRIPT_LOADING:
      return {
        ...state,
        abTastyTag: ABTASTY_TAG_STATES.LOADING,
      };

    case ABTASTY_SCRIPT_LOADED:
      return {
        ...state,
        abTastyTag: ABTASTY_TAG_STATES.LOADED,
      };

    case ABTASTY_SCRIPT_CANCELLED:
      return {
        ...state,
        abTastyTag: ABTASTY_TAG_STATES.CANCELLED,
      };

    default:
      return state;
  }
}
