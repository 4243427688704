import type { AlgoliaSearchOptions } from 'algoliasearch';

import { getAppSetting } from '@/domains/core/settings/appSettings';
import { IS_CLIENT_SIDE } from '@/domains/core/settings/constants';

import type * as AlgoliaModel from '../../Listings/interfaces/algolia';
import { createClientRequester } from '../requester/requester.client';

export const getOptions = (
  timeout: number,
  proxySettings?: AlgoliaModel.ProxySettings,
  searchEngineProxyEnabled = false,
): AlgoliaSearchOptions => {
  let options: AlgoliaSearchOptions = {
    requester: createClientRequester(timeout),
  };

  if (searchEngineProxyEnabled && proxySettings) {
    const portAndColon = IS_CLIENT_SIDE ? '' : `:${proxySettings.port}`;
    options = {
      ...options,
      hosts: [
        {
          protocol: IS_CLIENT_SIDE ? '' : proxySettings.scheme,
          accept: 1,
          url: searchEngineProxyEnabled
            ? `${proxySettings.host}${portAndColon}/search-engine`
            : `${proxySettings.host}:${proxySettings.port}`,
        },
      ],
    };
  }

  return options;
};

export const getClientAppKey = (): string => {
  return getAppSetting('ALGOLIA_CLIENT_APP_KEY');
};
