import { devtoolsExchange } from '@urql/devtools';
import {
  AnyVariables,
  cacheExchange,
  Client,
  DocumentInput,
  fetchExchange,
  makeOperation,
  mapExchange,
  OperationContext,
  type ClientOptions,
} from 'urql';

import { getAppSetting } from '@/core/settings/appSettings';
import { IS_CLIENT_SIDE, MARKET } from '@/core/settings/constants';

const getGraphQLGatewayUrl = () => `${getAppSetting('GRAPHQL_GATEWAY_URL')}`;
const getGraphQLGatewayInternalUrl = () =>
  `${getAppSetting('GRAPHQL_GATEWAY_URL_INTERNAL')}`;
const getDefaultGraphQLRequestTimeout = () =>
  Number(getAppSetting('GRAPHQL_REQUEST_TIMEOUT'));

export interface GraphQLOperationContext
  extends Omit<OperationContext, '_instance' | 'url' | 'requestPolicy'> {
  headers?: HeadersInit;
  timeout?: number;
}

export class GraphQLClient {
  public client: Client;

  constructor() {
    this.client = new Client(GraphQLClient.clientOptions);
  }

  public query<Data, Variables extends AnyVariables = AnyVariables>(
    query: DocumentInput<Data, Variables>,
    variables?: Variables,
    context?: GraphQLOperationContext,
  ) {
    return this.client
      .query(query, variables, GraphQLClient.updateOperationContext(context))
      .toPromise();
  }

  public mutation<Data, Variables extends AnyVariables = AnyVariables>(
    mutation: DocumentInput<Data, Variables>,
    variables?: Variables,
    context?: GraphQLOperationContext,
  ) {
    return this.client
      .mutation(
        mutation,
        variables,
        GraphQLClient.updateOperationContext(context),
      )
      .toPromise();
  }

  public static clientFetchOptions: RequestInit = {
    headers: {
      'content-type': 'application/json',
      'apollographql-client-name': `spartacux-${MARKET}`,
      'apollographql-client-version': '1.0',
    },
    credentials: 'include',
  };

  public static curriedFetchWithTimeout(timeout: number) {
    return (url: RequestInfo | URL, opts: RequestInit | undefined) =>
      GraphQLClient.fetchWithTimeout(url, opts, timeout);
  }

  private static updateOperationContext(
    context?: GraphQLOperationContext,
  ): GraphQLOperationContext | undefined {
    const { headers, ...otherContext } = context || {};
    return context?.headers
      ? {
          ...otherContext,
          fetchOptions: {
            ...GraphQLClient.clientFetchOptions,
            headers: {
              ...GraphQLClient.clientFetchOptions.headers,
              ...headers,
            },
          },
        }
      : context;
  }

  private static fetchWithTimeout(
    url: RequestInfo | URL,
    opts: RequestInit | undefined,
    timeout: number,
  ) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    return fetch(url, { ...opts, signal: controller.signal }).finally(() => {
      clearTimeout(id);
    });
  }

  private static timeoutExchange = mapExchange({
    onOperation(operation) {
      return makeOperation(
        operation.kind,
        operation,
        'timeout' in operation.context && operation.context.timeout > 0
          ? {
              fetch: GraphQLClient.curriedFetchWithTimeout(
                operation.context?.timeout,
              ),
            }
          : undefined,
      );
    },
  });

  private static clientOptions: ClientOptions = {
    url: IS_CLIENT_SIDE
      ? getGraphQLGatewayUrl()
      : getGraphQLGatewayInternalUrl(),
    preferGetMethod: true,
    exchanges: [
      ...(process.env.NODE_ENV !== 'production' ? [devtoolsExchange] : []),
      ...(IS_CLIENT_SIDE ? [GraphQLClient.timeoutExchange] : []),
      cacheExchange,
      fetchExchange,
    ],
    fetch: GraphQLClient.curriedFetchWithTimeout(
      getDefaultGraphQLRequestTimeout(),
    ),
    fetchOptions: () => ({ ...GraphQLClient.clientFetchOptions }),
    requestPolicy: 'network-only',
  };
}
