import type { PreHeaderModel } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/HeaderLeonidas.server.model';

export const INIT_PRE_HEADERS = 'productDiscovery/preHeaders/INIT';

export const FETCHING_PRE_HEADERS = 'productDiscovery/preHeaders/FETCHING';
export const FETCH_PRE_HEADERS_SUCCESS =
  'productDiscovery/preHeaders/FETCH_SUCCESS';
export const FETCH_PRE_HEADERS_FAILED =
  'productDiscovery/preHeaders/FETCH_FAILED';

export interface InitPreHeaders {
  type: typeof INIT_PRE_HEADERS;
}

export const initPreHeaders = (): InitPreHeaders => ({
  type: INIT_PRE_HEADERS,
});

export interface FetchingPreHeaders {
  type: typeof FETCHING_PRE_HEADERS;
}

export interface FetchPreHeadersSuccess {
  type: typeof FETCH_PRE_HEADERS_SUCCESS;
  preHeaders?: PreHeaderModel[];
}

export interface FetchPreHeadersFailed {
  type: typeof FETCH_PRE_HEADERS_FAILED;
  error: string;
}

export type PreHeaderActions =
  | InitPreHeaders
  | FetchingPreHeaders
  | FetchPreHeadersSuccess
  | FetchPreHeadersFailed;
