export enum HttpHeaders {
  AUTHORIZATION = 'Authorization',
  CACHE_CONTROL = 'cache-control',
  CONTENT_TYPE = 'content-type',
  CROSS_ORIGIN_EMBEDDER_POLICY = 'Cross-Origin-Embedder-Policy',
  CROSS_ORIGIN_OPENER_POLICY = 'Cross-Origin-Opener-Policy',
  SET_COOKIE = 'Set-Cookie',
  USER_AGENT = 'user-agent',
  X_FORWARDED_HOST = 'x-forwarded-host',
  X_FORWARDED_PROTO = 'x-forwarded-proto',
  X_MM_DEVICE = 'x-mm-device',
  X_MM_MARKET = 'x-mm-market',
  X_MM_PLATFORM = 'x-mm-platform',
  X_MM_ROUTE_NAME = 'x-mm-route-name',
  X_MM_ROUTE_PATH = 'x-mm-route-path',
  X_MM_USER_AGENT = 'x-mm-user-agent',
  X_MM_IDEMPOTENCY_KEY = 'x-mm-idempotency-key',
  X_OCDN = 'x-ocdn',
  // Activate exchage token from marketplace to internal on graphql requests
  MM_EXCHANGE_TOKEN = 'mm-exchange-token',
}

export const CACHE_CONTROL_NO_CACHE =
  'private,no-cache,no-store,s-maxage=0,max-age=0,must-revalidate';

export enum X_OCDN_VALUES {
  OTHER_BOT = 'bot',
  SEA_BOT = 'sea',
  SEO_BOT = 'seo',
  USER = 'user',
}
