import {
  getPlatform,
  isB2B,
  isUkPlatform,
} from '@/domains/core/settings/utils';
import type { TaxKey } from '@/domains/productDiscovery/algolia/interfaces/product';

import type { Hit } from '../../../interfaces/algolia';

export const getBundleTaxKey = (sellerCountryId?: string): TaxKey => {
  if (!isB2B() || (isB2B() && isUkPlatform())) return 'with_vat';

  return sellerCountryId?.toLowerCase() !== getPlatform()
    ? 'with_vat'
    : 'without_vat';
};

export function generateBundlePrices(hit: Hit) {
  const taxKey = getBundleTaxKey(hit.seller_country_id);
  const main = hit.prices.per_item.actual_price[taxKey].as_float || 0;

  return {
    main,
    with_vat: hit.prices.per_item.actual_price.with_vat.as_float || 0,
    without_vat: hit.prices.per_item.actual_price.without_vat.as_float || 0,
    taxKey: getBundleTaxKey(hit.seller_country_id),
  };
}
