import { FlagValuesMap } from '@/domains/core/flags/flags.state';
import { httpClient } from '@/domains/core/httpClient/httpClient';
import { HttpStatusCode } from '@/domains/core/httpClient/HttpStatusCode';
import { isB2B } from '@/domains/core/settings/utils';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { ABTests } from '@/domains/proB2BAnimation/abtests';

import {
  getRefreshToken,
  isCurrentPageProtected,
  signIn,
  signOut,
} from '../client';
import { shouldRequestHaveCredentials } from './shouldRequestHaveCredentials';

export function httpInterceptor(flagValuesPromise: Promise<FlagValuesMap>) {
  const responseInterceptorId = httpClient.interceptors.response.use(
    undefined,
    async (error) => {
      if (
        shouldRequestHaveCredentials(error.config) &&
        error.response?.status === HttpStatusCode.UNAUTHORIZED &&
        !error.config.doNotRetryAgain
      ) {
        // If we get an unauthorized response we refresh the cookie with next-auth
        try {
          await queuedGetRefreshToken();

          return httpClient({ ...error.config, doNotRetryAgain: true });
        } catch {
          // If we can't refresh the token we logout the user
          await signOutLeading();
          const flagValues = await flagValuesPromise;
          if (isCurrentPageProtected()) {
            await signIn({
              callbackUrl: window.location.href,
              unified: !!flagValues[ABTests.PEPE_UNIFY_AUTH],
            });
          }
          return;
        }
      }

      if (
        shouldRequestHaveCredentials(error.config) &&
        error.response?.status === HttpStatusCode.UNAUTHORIZED &&
        error.config.doNotRetryAgain
      ) {
        // if multiple requests are made at the same time, we only want to
        // refresh the token by sign in flow once
        const flagValues = await flagValuesPromise;
        return isCurrentPageProtected()
          ? signInLeading(!!flagValues[ABTests.PEPE_UNIFY_AUTH])
          : signOutLeading();
      }

      throw error;
    },
  );

  return {
    responseInterceptorId,
  };
}

let isSignInInProgress = false;
async function signInLeading(unified: boolean) {
  if (!isSignInInProgress) {
    try {
      isSignInInProgress = true;
      await signIn({
        b2b: isB2B(),
        callbackUrl: window.location.href,
        unified,
      });
      isSignInInProgress = false;
    } catch (error) {
      isSignInInProgress = false;
    }
  }
}

let isSignOutInProgress = false;
async function signOutLeading() {
  if (!isSignOutInProgress) {
    try {
      isSignOutInProgress = true;
      await signOut();

      Gtm.push({
        event: 'interaction_user disconnected',
        amp_event_name: 'User disconnected',
        event_trigger: 'refresh token error',
      });

      isSignOutInProgress = false;
    } catch (error) {
      isSignOutInProgress = false;
    }
  }
}

const resolvers: { resolve: () => void; reject: (error: Error) => void }[] = [];
/**
 * Debounces the getRefreshToken function to avoid multiple calls at the same
 * time.
 */
async function queuedGetRefreshToken() {
  await new Promise<void>((resolve, reject) => {
    resolvers.push({ resolve, reject });

    if (resolvers.length === 1) {
      getRefreshToken()
        .then(() => {
          while (resolvers.length > 0) {
            resolvers.pop()?.resolve();
          }
        })
        .catch((error) => {
          while (resolvers.length > 0) {
            resolvers.pop()?.reject(error);
          }
        });
    }
  });
}
