import {
  delay,
  race,
  select,
  take,
  type CallEffect,
  type RaceEffect,
  type SelectEffect,
  type TakeEffect,
} from 'redux-saga/effects';

import {
  SET_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT,
  SET_FLAG_VALUES_AND_FLAG_VARIATIONS_ERROR_CLIENT,
  type SetFlagValuesAndFlagVariationsAction,
} from '@/domains/core/flags/flags.actions';
import {
  selectClientSideFlagsRetrieved,
  selectFlagValuesMap,
} from '@/domains/core/flags/flags.selectors';
import logger from '@/domains/core/observability/logger';
import { IS_LOCAL } from '@/domains/core/platform/constants';
import { getAppSetting } from '@/domains/core/settings/appSettings';
import { IS_CLIENT_SIDE } from '@/domains/core/settings/constants';
import { selectRequesterType } from '@/domains/core/tracking/selectors/selectRequesterType';

export const CHECK_FLAGS_DELAY_MS = 2;
export const CHECK_FLAGS_CLIENT_TIMEOUT_MS = 5000;

interface RaceReturnType {
  success?: SetFlagValuesAndFlagVariationsAction;
  error?: Error;
  timeout?: string;
}

// `getFlagValuesSaga` should be preferred over this when possible for better readability.
/**
 *  @internal this saga is used internally, instead you should use `getFlagValuesSaga`
 *  @see {@link getFlagValuesSaga} the preferred way to query flag values
 */
export function* waitForAndGetFlagValuesSaga(): Generator<
  SelectEffect | CallEffect | RaceEffect<TakeEffect | CallEffect>,
  ReturnType<typeof selectFlagValuesMap>,
  | ReturnType<typeof selectFlagValuesMap>
  | ReturnType<typeof selectRequesterType>
  | boolean
  | RaceReturnType
> {
  let flags = (yield select(selectFlagValuesMap)) as
    | Record<number, boolean>
    | undefined;

  if (flags === undefined || Object.values(flags).length === 0) {
    if (!IS_CLIENT_SIDE) {
      let timeoutReached = false;
      const timeout = setTimeout(
        () => {
          timeoutReached = true;
        },
        Number(getAppSetting('REQUEST_TIMEOUT')),
      );

      while (!timeoutReached) {
        yield delay(CHECK_FLAGS_DELAY_MS);

        flags = (yield select(selectFlagValuesMap)) as
          | Record<number, boolean>
          | undefined;

        if (flags !== undefined && Object.values(flags).length !== 0) {
          break;
        }
      }

      if (timeoutReached) {
        if (!IS_LOCAL) {
          logger.error(new Error('Timeout reached while waiting for Flags.'));
        }
      } else {
        clearTimeout(timeout);
      }
    } else if (!IS_LOCAL) {
      logger.error(new Error('Flags unavailable.'));
    }
  }

  // If we are in client side we have to wait for the client saga to be executed
  if (IS_CLIENT_SIDE) {
    const clientSideFlagsRetrieved = (yield select(
      selectClientSideFlagsRetrieved,
    )) as ReturnType<typeof selectClientSideFlagsRetrieved>;

    if (!clientSideFlagsRetrieved) {
      const { error, timeout } = (yield race({
        success: take(SET_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT),
        error: take(SET_FLAG_VALUES_AND_FLAG_VARIATIONS_ERROR_CLIENT),
        timeout: delay(CHECK_FLAGS_CLIENT_TIMEOUT_MS),
      })) as RaceReturnType;

      if (!error && !timeout) {
        flags = (yield select(selectFlagValuesMap)) as
          | Record<number, boolean>
          | undefined;
      }
    }
  }

  return flags ?? {};
}
