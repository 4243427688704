import type { Locale } from '@/domains/core/localization/interfaces/Locale';
import type { Platform } from '@/domains/core/settings/interfaces/Platform';

const platformToLocaleMap: Record<Platform, Locale> = {
  es: 'es-ES',
  fr: 'fr-FR',
  gb: 'en-GB',
  de: 'de-DE',
  it: 'it-IT',
};

export function getPlatformLocale(platform: Platform): Locale {
  return platformToLocaleMap[platform];
}
