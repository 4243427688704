import type { AxiosError } from 'axios';
import pino, { type LoggerOptions } from 'pino';

import { IS_PROD } from '../../platform/constants';

const pinoConfig: LoggerOptions = IS_PROD
  ? {
      timestamp: () => `,"time":"${new Date().toISOString()}"`,
      messageKey: 'message',
      formatters: {
        level: (level) => ({ level }),
        log: (properties: Record<any, any>) => {
          const { isAxiosError, message, stack, missingSponsoredProducts } =
            properties;
          const propertiesToSend: Record<string, any> = {
            message,
          };

          if (isAxiosError) {
            const { config, response } = properties as AxiosError;
            propertiesToSend['http.method'] = config.method;
            propertiesToSend['http.url'] = config.url;
            propertiesToSend['http.status_code'] = response?.status;
          }

          if (stack) {
            propertiesToSend['error.kind'] = properties.name;
            propertiesToSend['error.stack'] = stack;
          }

          // Stacks are very verbose so they should be logged carefully
          if (process.env.LOG_ALGOLIA_STACK_TRACES === 'true') {
            const algoliaStack =
              properties.transporterStackTrace ?? properties.stackTrace;

            if (algoliaStack) {
              propertiesToSend['error.algolia_stack'] = algoliaStack;
              propertiesToSend['error.kind'] = properties.name;
            }
          }

          if (missingSponsoredProducts) {
            propertiesToSend['details.missing_sponsored_products'] =
              missingSponsoredProducts;
          }

          return propertiesToSend;
        },
      },
      hooks: {
        logMethod(inputArgs: unknown[], method) {
          const error = inputArgs.map((error) => formatError(error));
          const msg =
            typeof inputArgs[0] === 'string'
              ? inputArgs[0]
              : (inputArgs[0] as any).message;

          const isAxios404Error = msg.includes(
            'Request failed with status code 404',
          );

          if (!isAxios404Error) {
            method.apply(this, error as any);
          }
        },
      },
    }
  : {
      transport: { target: 'pino-pretty', options: { colorize: true } },
      base: {},
      timestamp: () => `,"time":"${new Date().toISOString()}"`,
    };

const logger = pino(pinoConfig);

/*
 * Some dependencies, like NextJS, use console.error internally so we need to
 * wrap it to prevent having multiline logs in Datadog.
 */
if (IS_PROD) {
  // eslint-disable-next-line no-console
  console.error = logger.error.bind(logger);
}

function formatError(o: unknown): unknown {
  if (o instanceof Error) {
    return { message: o.message, stack: o.stack, name: o.name };
  }

  return o;
}

export default logger;
