import { HYDRATE } from '@/domains/core/applicationState/store/hydrateAction';

import {
  ADD_TO_PRODUCT_HISTORY_SUCCESS,
  FETCH_PLAYLIST_PRODUCT_HISTORY_ERROR,
  FETCH_PLAYLIST_PRODUCT_HISTORY_SUCCESS,
  FETCH_PRODUCT_HISTORY_PENDING,
  FETCH_PRODUCT_HISTORY_SUCCESS,
  GET_RECOMMENDATIONS_FROM_PRODUCT_HISTORY_SUCCESS,
  MERGE_PRODUCT_HISTORY_FAILED,
  MERGE_PRODUCT_HISTORY_SUCCESS,
  PRODUCT_HISTORY_FAILED,
  type Action,
} from './ProductHistory.actions';
import { initialState, type ProductHistoryState } from './ProductHistory.model';

export default function reducer(
  state: ProductHistoryState = initialState,
  action: Action,
): ProductHistoryState {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        fetched: false,
        fetching: false,
      };
    case FETCH_PRODUCT_HISTORY_PENDING:
      return {
        ...state,
        fetching: true,
        error: false,
        errorPlaylist: false,
      };
    case FETCH_PRODUCT_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        productHistory: action.history,
      };
    case FETCH_PLAYLIST_PRODUCT_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        playlistHistory: action.history,
      };
    case FETCH_PLAYLIST_PRODUCT_HISTORY_ERROR:
      return {
        ...state,
        fetching: false,
        errorPlaylist: action.error?.message,
      };
    case PRODUCT_HISTORY_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: action.error.message,
      };
    case MERGE_PRODUCT_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        productHistory: action.history,
      };
    case MERGE_PRODUCT_HISTORY_FAILED:
      return {
        ...state,
        fetching: false,
        productHistory: action.history,
      };
    case ADD_TO_PRODUCT_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        productHistory: action.productHistory,
        recommendationsFromProductHistory: {},
      };
    case GET_RECOMMENDATIONS_FROM_PRODUCT_HISTORY_SUCCESS:
      return {
        ...state,
        recommendationsFromProductHistory: {
          ...state.recommendationsFromProductHistory,
          [action.recommendation.engineName]: action.recommendation.modelIds,
        },
      };

    default:
      return state;
  }
}
