import { call, put, race, select, take, takeLatest } from 'typed-redux-saga';

import { authSelector } from '@/customerManagement/customerManagement.selectors';
import {
  GET_CUSTOMER_PROFILE_ERROR,
  GET_CUSTOMER_PROFILE_REQUEST,
  GET_CUSTOMER_PROFILE_SUCCESS,
  getCustomerProfileError,
  getCustomerProfileRequest,
  getCustomerProfileSuccess,
  type GetCustomerProfileError,
  type GetCustomerProfileSuccess,
} from '@/customerManagement/customerProfile/actions';
import { getCustomerProfile } from '@/customerManagement/services/msCustomer/getCustomerProfile';
import { CHECK_AUTH_TOKEN_SUCCESS } from '@/domains/customerManagement/auth/actions';
import { initialCustomerProfile } from '@/domains/customerManagement/customerProfile/model';
import { selectCustomerProfileState } from '@/domains/customerManagement/customerProfile/selectors';

export function* getCustomerProfileSaga() {
  try {
    const { userId } = yield* select(authSelector);
    if (userId) {
      const customerProfileData = yield* call(getCustomerProfile);
      if (customerProfileData) {
        yield* put(getCustomerProfileSuccess(customerProfileData));
      }
    }
  } catch (error) {
    yield* put(getCustomerProfileError());
  }
}

export default function* rootCustomerProfileSaga() {
  yield* takeLatest(GET_CUSTOMER_PROFILE_REQUEST, getCustomerProfileSaga);
}

export function* rootGetCustomerProfileSaga() {
  yield* takeLatest(
    CHECK_AUTH_TOKEN_SUCCESS,
    function* putGetCustomerProfileRequestAction() {
      yield* put(getCustomerProfileRequest());
    },
  );
}

export function* waitForAndGetCustomerProfile() {
  const { data, isFetching } = yield* select(selectCustomerProfileState);

  if (!isFetching) return data;

  const actions = [
    take<GetCustomerProfileError>(GET_CUSTOMER_PROFILE_ERROR),
    take<GetCustomerProfileSuccess>(GET_CUSTOMER_PROFILE_SUCCESS),
  ] as const;
  const [error, success] = yield* race(actions);

  if (error || !success) {
    return initialCustomerProfile;
  }

  return success.payload;
}
