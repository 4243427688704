import { httpClient } from '@/domains/core/httpClient/httpClient';
import { getAppSetting } from '@/domains/core/settings/appSettings';
import { MARKET, PLATFORM } from '@/domains/core/settings/constants';

const getProEventUrl = () =>
  `${getAppSetting('MS_API_URL')}/api/v1/pro-event/event`;

const defaults = {
  market: MARKET,
  platform_id: PLATFORM,
  channel: 'website',
};

interface PublishEvent {
  event: string;
  customerId?: number;
  visitorId?: string;
  content?: Record<string, unknown>;
}

export const publishEvent = ({
  event,
  customerId,
  visitorId,
  content = {},
}: PublishEvent): Promise<void> =>
  httpClient.post(getProEventUrl(), [
    {
      ...defaults,
      event,
      visitor_id: visitorId,
      customer_id: customerId,
      content,
    },
  ]);

interface PublishPageviewProductEvent {
  productId: number;
  customerId: number;
  categoryId: number;
  visitorId?: string;
}

export const publishPageviewProductEvent = ({
  productId,
  customerId,
  visitorId,
  categoryId,
}: PublishPageviewProductEvent): Promise<void> =>
  publishEvent({
    event: 'pageview-product',
    customerId,
    visitorId,
    content: {
      product_id: productId,
      category_id: categoryId,
    },
  });
