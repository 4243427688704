import { fetchData } from '@/domains/core/graphqlClient/queryClient';
import { httpClient } from '@/domains/core/httpClient/httpClient';
import { getAppSetting } from '@/domains/core/settings/appSettings';
import {
  ProductHistoryDocument,
  type ProductHistoryQuery,
} from '@/domains/productDiscovery/Recommendation/modules/ProductHistory/generated/productHistory.query.generated';

import type { ProductHistory } from '../ProductHistory.model';
import {
  adaptBodyToProductHistoryService,
  adaptResponseFromProductHistoryService,
} from './utils';

const PATH_MERGE = `/api/v2/product-history/merge`;
const domain = getAppSetting('MS_API_URL');

export const getProductHistoryService = async () => {
  const { data } = await fetchData<ProductHistoryQuery, ProductHistory>(
    ProductHistoryDocument,
    {},
    {
      processor: (result) => ({
        ...result,
        data:
          result.data?.productHistory?.map(({ __typename, ...rest }) => ({
            ...rest,
          })) ?? [],
      }),
    },
  );

  return data;
};

export const mergeToProductHistoryService = async (
  productHistory: ProductHistory,
): Promise<ProductHistory> => {
  const url = `${domain}${PATH_MERGE}`;
  const res = await httpClient.post(url, {
    content: adaptBodyToProductHistoryService(productHistory),
  });
  return adaptResponseFromProductHistoryService(res?.data?.content);
};
