import { NextWebVitalsMetric } from 'next/app';
import Router from 'next/router';
import {
  onCLS,
  onFCP,
  onINP,
  onLCP,
  onTTFB,
  type MetricWithAttribution,
} from 'web-vitals/attribution';

import { httpClient } from '@/core/httpClient/httpClient';
import logger from '@/core/observability/logger';
import { Metric } from '@/core/observability/webVitals/interfaces/Metric';
import { onHidden } from '@/core/observability/webVitals/onHidden';
import { IS_PROD } from '@/core/platform/constants';
import { routes } from '@/core/routing/routes/routes.default';
import { isPage } from '@/core/routing/URLParser';
import { getAppSetting } from '@/core/settings/appSettings';
import { IS_CLIENT_SIDE } from '@/core/settings/constants';
import { PLATFORM_RELEASE } from '@/core/shell/constants';

import type { BeaconRequest } from './interfaces/BeaconRequest';

type Resource = keyof typeof routes | 'resource-not-matched';

interface ReporterConfig {
  service: string;
  version: string;
  isMobile: boolean;
  reportData?: boolean;
}

export function isNextWebVitalsMetric(
  arg: MetricWithAttribution | NextWebVitalsMetric | GTMMetric,
): arg is NextWebVitalsMetric {
  return (arg as NextWebVitalsMetric).label !== undefined;
}

export function isPerformanceMetric(
  arg:
    | MetricWithAttribution
    | PerformanceEntry
    | NextWebVitalsMetric
    | GTMMetric,
): arg is PerformanceEntry {
  return (
    (arg as MetricWithAttribution | NextWebVitalsMetric).id === undefined &&
    arg.name !== 'gtmExecutionTime'
  );
}

/**
 * Send performance data back to the web-vitals service via a navigator beacon if available
 * or use a classic blocking XHR depending on browser features.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
 * @see https://git.manomano.tech/sre/web-vitals/-/blob/master/docs/api/web_vitals/beacon/v1/swagger.json
 */
const sendBeacon = async (beacon: BeaconRequest): Promise<void> => {
  const MS_API_URL = getAppSetting('MS_API_URL');

  let sent = false;

  if (typeof navigator.sendBeacon === 'function') {
    const beaconUrl = IS_PROD
      ? `${MS_API_URL}/api/v1/web-vitals/beacon`
      : '/api/v1/web-vitals/beacon';
    sent = navigator.sendBeacon(
      beaconUrl,
      new Blob([JSON.stringify(beacon)], {
        type: 'application/json',
      }),
    );
  }

  if (!sent) {
    await httpClient.post(`${MS_API_URL}/api/v1/web-vitals/beacon`, beacon, {
      keepAlive: true,
    });
  }
};

const getResource = (url: string): Resource =>
  (Object.entries(routes).find(([, pattern]) =>
    isPage(pattern, url),
  )?.[0] as keyof typeof routes) ?? 'resource-not-matched';

const getCurrentResource = () => getResource(window.location.pathname);

interface GTMMetric extends Metric {
  name: 'gtmExecutionTime';
  value: number;
}

/**
 * Inspired by https://github.com/treosh/web-vitals-reporter
 */
export class WebVitalsReporter {
  metrics: Map<string, MetricWithAttribution | NextWebVitalsMetric | GTMMetric>;

  service: string;

  version: string;

  timeToRender?: number;

  alreadySent = false;

  navigationStart: Date;

  lastRouteChange: Date;

  reportData = true;

  isMobile = false;

  static create(): WebVitalsReporter {
    return new WebVitalsReporter({
      service: 'spartacux',
      version: PLATFORM_RELEASE,
      isMobile: /iphone|ipad|ipod|android/i.test(navigator.userAgent),
    });
  }

  private constructor({
    service,
    version,
    isMobile,
    reportData = true,
  }: ReporterConfig) {
    this.service = service;
    this.version = version;
    this.reportData = reportData;
    this.metrics = new Map();
    this.lastRouteChange = global.performance.timeOrigin
      ? new Date(global.performance.timeOrigin)
      : new Date();
    this.navigationStart = this.lastRouteChange;
    this.isMobile = isMobile;
    this.trackSessionVitals();
  }

  private trackSessionVitals(): void {
    // We use the `web-vitals` library directly instead of using the `use-report-web-vitals` hook
    // (that uses it internally) due to the fact the nextjs uses an unknown version of the library
    // in its source code https://github.com/vercel/next.js/blob/canary/packages/next/src/compiled/web-vitals/web-vitals.js
    onCLS(this.submitMetric.bind(this));
    onLCP(this.submitMetric.bind(this));
    onINP(this.submitMetric.bind(this));
    onFCP(this.submitMetric.bind(this));
    onTTFB(this.submitMetric.bind(this));
  }

  /**
   * Register handlers to listen on route change and when unloading the page.
   */
  registerHandlers(): () => void {
    if (!IS_CLIENT_SIDE) {
      return () => {};
    }

    const reportOnNavigation = async () => {
      try {
        await this.report();
      } catch (error) {
        logger.error(error as Error);
      }
    };
    Router.events.on('routeChangeStart', reportOnNavigation);

    onHidden(async ({ isUnloading }) => {
      if (this.isMobile || isUnloading) {
        await this.report();
      }
    });

    return () => {
      Router.events.off('routeChangeStart', reportOnNavigation);
    };
  }

  private mapMetricEntry(
    metric:
      | NextWebVitalsMetric
      | MetricWithAttribution
      | PerformanceEntry
      | GTMMetric,
  ): Metric {
    if (isPerformanceMetric(metric) || isNextWebVitalsMetric(metric)) {
      const startTime = Math.max(
        0,
        Math.round(metric.startTime) -
          (this.lastRouteChange.getTime() - this.navigationStart.getTime()),
      );

      let value: number;
      if ('duration' in metric) {
        value = metric.duration;
      } else {
        ({ value } = metric);
      }

      if (
        metric.name === 'Next.js-hydration' ||
        metric.name === 'Next.js-render'
      ) {
        this.timeToRender = Math.round(startTime + value);
      }

      return {
        name: metric.name,
        value: Math.round(value),
        startTime,
        tags: {},
      } satisfies Metric;
    }

    const mappedMetric: Metric = {
      name: metric.name,
      value: Math.round(metric.value),
      tags: {},
    };

    if (metric.name === 'INP') {
      mappedMetric.tags.eventType = metric.attribution.interactionType;
      mappedMetric.tags.eventTarget = metric.attribution.interactionTarget;
    }

    if (metric.name === 'CLS') {
      mappedMetric.tags.eventTarget =
        metric.attribution.largestShiftTarget ?? '';
    }

    if (metric.name === 'LCP') {
      mappedMetric.tags.eventTarget = metric.attribution.element ?? '';
    }

    if (metric.name === 'gtmExecutionTime') {
      mappedMetric.tags = metric.tags;
      mappedMetric.startTime = metric.startTime;
    }

    return mappedMetric;
  }

  private autoShiftGTMMetric(
    metricsToSend: (
      | NextWebVitalsMetric
      | MetricWithAttribution
      | PerformanceEntry
      | GTMMetric
    )[],
  ): void {
    if (window.gtmMetrics.length === 0) return;

    const data = window.gtmMetrics.shift();
    if (data) {
      metricsToSend.push(
        ...data.metrics.map(
          (metrics): GTMMetric => ({ ...metrics, name: 'gtmExecutionTime' }),
        ),
      );
    }

    this.autoShiftGTMMetric(metricsToSend);
  }

  async report(): Promise<void> {
    if (!global.navigator) {
      return;
    }

    const metricsToSend: (
      | NextWebVitalsMetric
      | MetricWithAttribution
      | PerformanceEntry
      | GTMMetric
    )[] = Array.from(this.metrics.values());

    if (global.performance && !this.alreadySent) {
      global.performance
        .getEntriesByName('Next.js-before-hydration')
        .forEach((entry) => {
          metricsToSend.push(entry);
        });
    }

    if (!this.metrics.size && !window.gtmMetrics?.length) {
      return;
    }

    if (window.gtmMetrics?.length) {
      this.autoShiftGTMMetric(metricsToSend);
    }

    const resource = getCurrentResource();

    const beacon: BeaconRequest = {
      browserContext: {
        url: global.window?.location?.href,
        userAgent: global.navigator?.userAgent,
      },
      service: this.service,
      resource,
      version: this.version,
      metrics: metricsToSend.map(this.mapMetricEntry.bind(this)),
      startTime: this.lastRouteChange.toISOString(),
      duration: this.timeToRender,
    };

    this.metrics.clear();
    this.alreadySent = true;

    if (this.reportData) {
      await sendBeacon(beacon);
    }
  }

  submitMetric(metric: MetricWithAttribution): void {
    if (this.metrics.has(metric.id)) {
      this.metrics.get(metric.id)!.value += metric.delta;
    } else {
      this.metrics.set(metric.id, metric);
    }
  }

  submitNextMetric(metric: NextWebVitalsMetric): void {
    if (!this.metrics.has(metric.id)) {
      this.metrics.set(metric.id, metric);
    }
  }
}
