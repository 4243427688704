export const ABTASTY_TAG_STATES = {
  INIT: '',
  LOADING: 'loading',
  LOADED: 'loaded',
  CANCELLED: 'cancelled',
};

export interface ABTastyState {
  abTastyTag: string;
}

export const abTastyInitialState: ABTastyState = {
  abTastyTag: ABTASTY_TAG_STATES.INIT,
};
