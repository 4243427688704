import type { CommercialOperationInfo } from '@/domains/productDiscovery/CommercialAnimation/CommercialAnimation.model';
import type { AdvertiserInformation } from '@/domains/productDiscovery/CommercialAnimation/interfaces/AdvertiserInformation';
import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';

export const GET_COMMERCIAL_OPERATIONS_SUCCESS =
  'productDiscovery/CommercialAnimation/GET_COMMERCIAL_OPERATIONS_SUCCESS';
export const GET_MARKETING_CAMPAIGNS =
  'productDiscovery/Header/GET_MARKETING_CAMPAIGNS';
export const GET_MARKETING_CAMPAIGNS_SUCCESS =
  'productDiscovery/Header/GET_MARKETING_CAMPAIGNS_SUCCESS';
export const GET_MARKETING_CAMPAIGNS_FAILED =
  'productDiscovery/Header/GET_MARKETING_CAMPAIGNS_FAILED';
export const DISPLAY_ADVERTISER_INFORMATION =
  'productDiscovery/CommercialAnimation/DISPLAY_ADVERTISER_INFORMATION';
export const HIDE_ADVERTISER_INFORMATION =
  'productDiscovery/CommercialAnimation/HIDE_ADVERTISER_INFORMATION';

export interface GetCommercialOperationsSuccess {
  type: typeof GET_COMMERCIAL_OPERATIONS_SUCCESS;
  data: CommercialOperationInfo[];
}

export interface GetMarketingCampaigns {
  type: typeof GET_MARKETING_CAMPAIGNS;
}

export interface GetMarketingCampaignsSuccess {
  type: typeof GET_MARKETING_CAMPAIGNS_SUCCESS;
  marketingCampaigns: BannerCampaign[];
}

export interface GetMarketingCampaignsFailed {
  type: typeof GET_MARKETING_CAMPAIGNS_FAILED;
  error: string;
}

export interface DisplayAdvertiserInformation {
  type: typeof DISPLAY_ADVERTISER_INFORMATION;
  advertiserInformation: AdvertiserInformation;
}

export interface HideAdvertiserInformation {
  type: typeof HIDE_ADVERTISER_INFORMATION;
}

export const displayAdvertiserInformation = (
  data: AdvertiserInformation,
): DisplayAdvertiserInformation => ({
  type: DISPLAY_ADVERTISER_INFORMATION,
  advertiserInformation: data,
});

export const hideAdvertiserInformation = (): HideAdvertiserInformation => ({
  type: HIDE_ADVERTISER_INFORMATION,
});

export type Action =
  | GetCommercialOperationsSuccess
  | GetMarketingCampaigns
  | GetMarketingCampaignsSuccess
  | GetMarketingCampaignsFailed
  | DisplayAdvertiserInformation
  | HideAdvertiserInformation;
