import type { PlatformConfigState } from '@/domains/proB2BServices/platformConfig/platformConfig.state';

export const normalizePlatformConfig = (platformConfig: {
  [key: string]: any;
}): Partial<PlatformConfigState> => ({
  'sales-period': platformConfig['sales-period'] ?? null,
  b2b: platformConfig.b2b ?? null,
  'loyalty-program': platformConfig['loyalty-program'] ?? null,
  mainPrice: platformConfig['main-price'] ?? null,
  freeReturnsB2B: platformConfig['free-returns-b2b'] ?? null,
  coupon: platformConfig.coupon ?? null,
  welcomeOffer: platformConfig['welcome-offer'] ?? null,
  welcomeOfferHvc: platformConfig['welcome-offer-hvc'] ?? null,
});
