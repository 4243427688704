export const HYDRATE = 'core/applicationState/HYDRATE';

export interface HydrateAction<State = Record<string, unknown>> {
  type: typeof HYDRATE;
  state: State;
}

export function getHydrate<State = Record<string, unknown>>(
  state: State,
): HydrateAction<State> {
  return {
    type: HYDRATE,
    state,
  };
}
