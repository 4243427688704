import { delay, put, select, take } from 'typed-redux-saga';

import {
  ADD_FLASH_MESSAGE,
  CLEAR_FLASH_MESSAGE,
  SHOW_FLASH_MESSAGE,
} from '@/shoppingCart/FlashMessages/FlashMessages.actions';
import { getFlashQueue } from '@/shoppingCart/FlashMessages/selectors/getFlashQueue';

const FIVE_SECONDS = 5000;

export function* showFlashMessage() {
  yield* put({ type: SHOW_FLASH_MESSAGE });
  yield* delay(FIVE_SECONDS);
  yield* put({ type: CLEAR_FLASH_MESSAGE });
}

export function* watchFlashMessage() {
  while (true) {
    const flashQueue = yield* select(getFlashQueue);

    // empty whats in the queue first
    if (flashQueue.length > 0) {
      while (true) {
        const queue = yield* select(getFlashQueue);
        if (queue.length === 0) {
          break;
        }
        yield showFlashMessage();
      }
    }

    // wait until we get a new ADD_FLASH_MESSAGE action
    yield* take(ADD_FLASH_MESSAGE);
    yield showFlashMessage();
  }
}
