import {
  ADD_FLASH_MESSAGE,
  CLEAR_FLASH_MESSAGE,
  type AlertMessage,
  type AlertMessageActions,
} from './FlashMessages.actions';
import {
  flashMessageInitialState,
  type FlashMessageState,
} from './FlashMessages.interfaces';
import { insertAlertMessage } from './utils/insertAlertMessage';
import { shiftQueue } from './utils/shiftQueue';

export default function flashMessageReducer(
  state: FlashMessageState = flashMessageInitialState,
  action: AlertMessageActions,
): FlashMessageState {
  switch (action.type) {
    case ADD_FLASH_MESSAGE: {
      const { alertMessages } = state;
      const newAlertMessages: Record<number, AlertMessage[]> =
        insertAlertMessage(alertMessages, action.alertMessage);
      return { ...state, alertMessages: newAlertMessages };
    }

    case CLEAR_FLASH_MESSAGE: {
      const { alertMessages } = state;
      const newAlertMessages: Record<number, AlertMessage[]> =
        shiftQueue(alertMessages);
      return { ...state, alertMessages: newAlertMessages };
    }

    default:
      return state;
  }
}
