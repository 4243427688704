import { call } from 'typed-redux-saga';

import { waitForAndGetFlagValuesSaga } from '@/domains/core/flags/sagas/waitForAndGetFlagValues.saga';

/**
 * Waits for flags and returns a list of flag values corresponding to those flags
 *
 * Unlike a simple selector, this will wait for flags if not yet retrieved.
 */
export function* getFlagValuesSaga(...flags: string[]) {
  const flagValuesMap = yield* call(waitForAndGetFlagValuesSaga);

  return flags.map((flag) => flagValuesMap[flag]);
}
