import { normalizePlatformConfig } from '@/domains/proB2BServices/platformConfig/utils';

import {
  FAILED_CONFIG,
  INIT_CONFIG,
  SET_CONFIG,
  type Action,
} from './platformConfig.action';
import {
  platformConfigInitialState,
  type PlatformConfigState,
} from './platformConfig.state';

const reducer = (
  state: PlatformConfigState = platformConfigInitialState,
  action: Action,
): PlatformConfigState => {
  switch (action.type) {
    case INIT_CONFIG: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SET_CONFIG:
      return {
        ...state,
        ...normalizePlatformConfig(action.payload),
        isLoading: false,
      };
    case FAILED_CONFIG:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
