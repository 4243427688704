/**
 * The JavaScript function tries to load remote JavaScript resources that are blocked by Adblockers. If the loading fails, it is assumed that an adblocker is present. If the loading succeeds, there is no adblocker installed.
 *
 * @see https://github.com/NikolaiT/adblock-detect-javascript-only/tree/master
 */

import { getCurrentBaseUrl } from '@/domains/core/platform/getCurrentBaseUrl';

interface Block {
  url: string;
  id: string;
}

export function detectAdBlocker(): Promise<boolean> {
  const adblockTests: Record<string, Block> = {
    // https://github.com/uBlockOrigin/uAssets/blob/master/filters/filters-2022.txt
    // if you are testing this locally, you can use the following URL:
    // https://www.manomano.fr instead of getCurrentBaseUrl()
    uBlockOrigin: {
      url: `${getCurrentBaseUrl()}/data/yzfdmoan.js`,
      id: '837jlaBksSjd9jh',
    },
    // https://github.com/easylist/easylist/blob/master/easylist/easylist_general_block.txt
    adblockPlus: {
      url: `${getCurrentBaseUrl()}/data/utep_ad.js`,
      id: 'hfuBadsf3hFAk',
    },
  };

  function canLoadRemoteScript(obj: Block) {
    return new Promise((resolve) => {
      const script = document.createElement('script');

      script.onload = function onLoad() {
        if (document.getElementById(obj.id)) {
          resolve(false);
        } else {
          resolve(true);
        }
      };

      script.onerror = function onError() {
        resolve(true);
      };

      script.src = obj.url;
      document.body.appendChild(script);
    });
  }

  return new Promise((resolve) => {
    const promises = [
      canLoadRemoteScript(adblockTests.uBlockOrigin),
      canLoadRemoteScript(adblockTests.adblockPlus),
    ];

    Promise.all(promises)
      .then((results) => {
        resolve(results.some((e) => e === true));
      })
      .catch(() => {
        resolve(false);
      });
  });
}
