import type { Request, Requester } from '@algolia/requester-common';

import { send } from './requester.common';

export const createClientRequester = (timeout: number): Requester => ({
  send(request: Request) {
    return send(request, {
      timeout,
      // NOTE: We set withCredentials to false to prevent sending cookies to Algolia
      // and avoid "431 request header field too large" errors
      withCredentials: false,
    });
  },
});
