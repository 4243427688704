import fetchRefererIdsWithRefererSdk from '@core/referer-sdk';

import logger from '@/domains/core/observability/logger';
import { getAppSetting } from '@/domains/core/settings/appSettings';
import type { PlatformEnvironment } from '@/domains/core/settings/interfaces/PlatformEnvironment';

import { detectAdBlocker } from './detectAdBlocker';
import { detectCrawler } from './detectCrawler';

/**
 * Page data that will not change between first request (SSR mode) and page
 * changes (SPA mode).
 */
export interface RefererIdsFromSdk {
  trackingCampaign: string;
  trackingCategory: string;
  trackingChannel: string;
  rawRefererId: string;
  refererId: string;
}
export interface PageInitialData {
  environment: PlatformEnvironment | '';
  hasAdBlockerPromise: Promise<boolean> | undefined;
  isCrawler: boolean | undefined;
  refererIdsPromise: Promise<RefererIdsFromSdk | undefined> | undefined;
}

/**
 * Retrieves data that will not change between pages in SPA mode.
 */
export function getPageInitialData(
  initialPageType: string,
  requesterType: string,
  isRefererSdkCookieUsingDisabled: boolean,
): PageInitialData {
  const initialData = {
    environment: '',
    hasAdBlockerPromise: undefined,
    isCrawler: undefined,
    refererIdsPromise: undefined,
  } as PageInitialData;

  try {
    initialData.environment = getAppSetting(
      'PLATFORM_ENVIRONMENT',
    ) as PlatformEnvironment;
    // No yield there to avoid missing page view data events
    initialData.hasAdBlockerPromise = detectAdBlocker();
    initialData.isCrawler = detectCrawler();
    initialData.refererIdsPromise = fetchRefererIdsWithRefererSdk(
      getAppSetting('MS_API_URL'),
      {
        pageType: initialPageType,
        requesterType,
      },
      isRefererSdkCookieUsingDisabled,
    );
  } catch (error) {
    logger.error(error);
  }

  return initialData;
}
