import {
  HYDRATE,
  HydrateAction,
} from '@/core/applicationState/store/hydrateAction';
import { partnerAdThemeSelector } from '@/sellerAdsManagement/PartnerAdTheme/hooks/usePartnerAdTheme';
import {
  partnerAdThemeInitialState,
  PartnerAdThemeState,
} from '@/sellerAdsManagement/PartnerAdTheme/interfaces/partnerAdTheme';
import {
  Action,
  FETCH_PARTNER_AD_THEME_FAILURE,
  FETCH_PARTNER_AD_THEME_SUCCESS,
} from '@/sellerAdsManagement/PartnerAdTheme/PartnerAdTheme.actions';

export function partnerAdThemeReducer(
  state: PartnerAdThemeState = partnerAdThemeInitialState,
  action: Action | HydrateAction,
): PartnerAdThemeState {
  switch (action.type) {
    case FETCH_PARTNER_AD_THEME_SUCCESS:
      return {
        colors: action.colors,
      };

    case FETCH_PARTNER_AD_THEME_FAILURE:
      return {
        error: action.error,
      };

    case HYDRATE:
      return {
        ...state,
        colors: partnerAdThemeSelector(action.state)?.colors,
      };

    default:
      return state;
  }
}
