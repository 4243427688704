import type { ActionCreator } from 'redux';

import type { ActionWithPayload } from '@/domains/core/applicationState/interfaces';

import type { SelfCareInvoice } from './interfaces/SelfCareInvoice';

export const GET_SELF_CARE_INVOICE_REQUEST =
  'octopus/GET_SELF_CARE_INVOICE_REQUEST';
export const GET_SELF_CARE_INVOICE_SUCCESS =
  'octopus/GET_SELF_CARE_INVOICE_SUCCESS';
export const GET_SELF_CARE_INVOICE_FAILED =
  'octopus/GET_SELF_CARE_INVOICE_FAILED';

export type GetSelfCareInvoiceRequest = ActionWithPayload<
  { orderId: number },
  typeof GET_SELF_CARE_INVOICE_REQUEST
>;

export type GetSelfCareInvoiceSuccess = ActionWithPayload<
  { selfCareInvoice: SelfCareInvoice },
  typeof GET_SELF_CARE_INVOICE_SUCCESS
>;

export type GetSelfCareInvoiceFailed = ActionWithPayload<
  { error: string },
  typeof GET_SELF_CARE_INVOICE_FAILED
>;

export type SelfCareInvoiceAction =
  | GetSelfCareInvoiceRequest
  | GetSelfCareInvoiceSuccess
  | GetSelfCareInvoiceFailed;

export const getSelfCareInvoice: ActionCreator<GetSelfCareInvoiceRequest> = (
  orderId: number,
) => ({
  type: GET_SELF_CARE_INVOICE_REQUEST,
  payload: {
    orderId,
  },
});
