import { AxiosRequestConfig } from 'axios';

import { getDomains } from '@/domains/core/platform/domains/getDomains';
import { getAppSetting } from '@/domains/core/settings/appSettings';

const listServersMustIncludeCredentials = () =>
  [
    getAppSetting('MS_API_URL')?.toString(),
    getDomains().domainBase,
  ] as string[];

export const shouldRequestHaveCredentials = (config: AxiosRequestConfig) => {
  // If the URL is missing, credentials are not required
  if (!config.url) return false;

  // Explicitly respect the `withCredentials` flag if provided
  if (config.withCredentials !== undefined) {
    return config.withCredentials;
  }

  // Check if the URL matches any of the partial URLs requiring credentials
  return listServersMustIncludeCredentials().some((partialUrl) =>
    config.url?.includes(partialUrl),
  );
};
