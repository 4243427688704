/* eslint-disable camelcase */
export type IsAuthenticated = boolean;
export type IsB2B = boolean;
export type UserId = number | null;
export type HasRetrievedAuthStatus = boolean;
export type HasRetrievedCustomerSegments = boolean;
export type Email = string | null;

export const CustomerProfile = {
  ELECTRICIAN: 'ELECTRICIAN',
  PLUMBER: 'PLUMBER',
  ELECTRICIAN_PLUMBER: 'ELECTRICIAN_PLUMBER',
  GENERALIST: 'GENERALIST',
  CARPENTER: 'CARPENTER',
  PAINTER: 'PAINTER',
} as const;
export type CustomerProfile = keyof typeof CustomerProfile;

export const CustomerCluster = {
  PROSPECTS: 'PROSPECTS',
  OCC_B_NUMB_1: 'OCC_B_NUMB_1',
  OCC_B_NUMB_2: 'OCC_B_NUMB_2',
  OCC_B_NUMB_3: 'OCC_B_NUMB_3',
  OCC_B_NUMB_4: 'OCC_B_NUMB_4',
  RECURRING: 'RECURRING',
  FREQUENT: 'FREQUENT',
  LOYAL: 'LOYAL',
} as const;
export type CustomerCluster = keyof typeof CustomerCluster;

export const BuyNumberCluster = {
  PROSPECTS: 'PROSPECTS',
  OCC_B_NUMB_1: 'OCC_B_NUMB_1',
  OCC_B_NUMB_2: 'OCC_B_NUMB_2',
  OCC_B_NUMB_3: 'OCC_B_NUMB_3',
  OCC_B_NUMB_4: 'OCC_B_NUMB_4',
  WAVERING: 'WAVERING',
  RECURRING: 'RECURRING',
  FREQUENT: 'FREQUENT',
  LOYAL: 'LOYAL',
} as const;
export type BuyNumberCluster = keyof typeof BuyNumberCluster;

export const B2BIntention = {
  CONVERTED: 'CONVERTED',
  LEAD: 'LEAD',
  UNKNOWN: 'UNKNOWN',
} as const;
export type B2BIntention = keyof typeof B2BIntention;

export const B2BSuspectedPro = {
  CONVERTED: 'CONVERTED',
  LEAD: 'LEAD',
  REFUSED: 'REFUSED',
} as const;
export type B2BSuspectedPro = keyof typeof B2BSuspectedPro;

export const SuspectedProType = {
  VERY_HIGH_SUSPECTED_PRO: 'VERY_HIGH_SUSPECTED_PRO',
  HIGH_SUSPECTED_PRO: 'HIGH_SUSPECTED_PRO',
  MEDIUM_SUSPECTED_PRO: 'MEDIUM_SUSPECTED_PRO',
  NOT_SUSPECTED_PRO: 'NOT_SUSPECTED_PRO',
} as const;
export type SuspectedProType = keyof typeof SuspectedProType;

export const AudienceGroup = {
  CORPORATE: 'CORPORATE',
  CONSTRUCTION: 'CONSTRUCTION',
  UNKNOWN: 'UNKNOWN',
} as const;
export type AudienceGroup = keyof typeof AudienceGroup;

export const LoyaltyLevel = {
  GOLD: 'GOLD',
  SILVER: 'SILVER',
  BRONZE: 'BRONZE',
  UNDEFINED: 'UNDEFINED',
} as const;
export type LoyaltyLevel = keyof typeof LoyaltyLevel;

export const LoyaltyHvcLevel = {
  MEMBER: 'MEMBER',
  TOP_MEMBER: 'TOP_MEMBER',
  OPT_OUT: 'OPT_OUT',
} as const;
export type LoyaltyHvcLevel = keyof typeof LoyaltyHvcLevel;

export const AppCluster = {
  WEB_TO_APP_PROSPECT: 'WEB_TO_APP_PROSPECT',
} as const;
export type AppCluster = keyof typeof AppCluster;

export const LastChanceChurn = {
  ONE_PURCHASE: 'ONE_PURCHASE',
  MEDIUM_PURCHASE: 'MEDIUM_PURCHASE',
  HIGH_PURCHASE: 'HIGH_PURCHASE',
} as const;
export type LastChanceChurn = keyof typeof LastChanceChurn;

export const AntiChurn = {
  ONE_PURCHASE_ALL: 'ONE_PURCHASE_ALL',
  ONE_PURCHASE_ACTIVE: 'ONE_PURCHASE_ACTIVE',
  ONE_PURCHASE_INACTIVE: 'ONE_PURCHASE_INACTIVE',
  MEDIUM_PURCHASES_ALL: 'MEDIUM_PURCHASES_ALL',
  MEDIUM_PURCHASES_ACTIVE: 'MEDIUM_PURCHASES_ACTIVE',
  MEDIUM_PURCHASES_INACTIVE: 'MEDIUM_PURCHASES_INACTIVE',
  HIGH_PURCHASES_ALL: 'HIGH_PURCHASES_ALL',
  HIGH_PURCHASES_ACTIVE: 'HIGH_PURCHASES_ACTIVE',
  HIGH_PURCHASES_INACTIVE: 'HIGH_PURCHASES_INACTIVE',
} as const;
export type AntiChurn = keyof typeof AntiChurn;

export const B2BSubscription = {
  YEARLY: 'YEARLY',
  MONTHLY: 'MONTHLY',
} as const;
export type B2BSubscription = keyof typeof B2BSubscription;

export const CustomerValue = {
  VERY_HIGH: 'VERY_HIGH',
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
} as const;
export type CustomerValue = keyof typeof CustomerValue;

export interface CustomerSegment {
  b2b_intention: B2BIntention | null;
  b2b_welcome: boolean | null;
  b2b_suspected_pro: B2BSuspectedPro | null;
  suspected_pro_type: SuspectedProType | null;
  b2b_vat_modal: boolean | null;
  profile: CustomerProfile | null;
  cluster: CustomerCluster | null;
  buy_number_cluster: BuyNumberCluster | null;
  audience_group: AudienceGroup | null;
  loyalty_level: LoyaltyLevel | null;
  loyalty_hvc_level: LoyaltyHvcLevel | null;
  app_cluster: AppCluster | null;
  last_chance_churn: LastChanceChurn | null;
  anti_churn: AntiChurn | null;
  b2b_subscription: B2BSubscription | null;
  customer_value: CustomerValue | null;
}

export interface CustomerSegmentFromGQL {
  b2b_intention?: string;
  b2b_welcome?: boolean;
  b2b_suspected_pro?: string;
  suspected_pro_type?: string;
  b2b_vat_modal?: boolean;
  profile?: string;
  cluster?: string;
  buy_number_cluster?: string;
  audience_group?: string;
  loyalty_level?: string;
  loyalty_hvc_level?: string;
  app_cluster?: string;
  last_chance_churn?: string;
  anti_churn?: string;
  b2b_subscription?: string;
  customer_value?: string;
}

export interface CustomerOptinStatus {
  email: boolean | null;
  sms: boolean | null;
}

export interface Auth extends CustomerSegment {
  isAuthenticated: IsAuthenticated;
  isAuthenticating?: boolean;
  isB2B: IsB2B;
  isGuest: boolean;
  isB2C: boolean;
  userId: UserId;
  hasRetrievedAuthStatus: HasRetrievedAuthStatus;
  hasRetrievedCustomerSegments: HasRetrievedCustomerSegments;
  email: Email;
  given_name: string | null;
  optin_status: CustomerOptinStatus;
}

export const initialState: Auth = {
  isB2B: false,
  isGuest: false,
  isB2C: false,
  isAuthenticated: false,
  userId: null,
  hasRetrievedAuthStatus: false,
  hasRetrievedCustomerSegments: false,
  email: null,
  isAuthenticating: true,
  b2b_intention: B2BIntention.UNKNOWN,
  b2b_welcome: false,
  b2b_suspected_pro: null,
  suspected_pro_type: null,
  b2b_vat_modal: null,
  profile: null,
  cluster: null,
  buy_number_cluster: null,
  audience_group: null,
  loyalty_level: null,
  loyalty_hvc_level: null,
  app_cluster: null,
  last_chance_churn: null,
  anti_churn: null,
  given_name: null,
  b2b_subscription: null,
  optin_status: {
    email: null,
    sms: null,
  },
  customer_value: null,
};
