import type { Reducer } from 'redux';

import {
  UPDATE_CUSTOMER_PROFILE_SUCCESS,
  type MyAccountAction,
} from '@/domains/care/myAccount/myAccount.actions';

import {
  GET_CUSTOMER_PROFILE_ERROR,
  GET_CUSTOMER_PROFILE_REQUEST,
  GET_CUSTOMER_PROFILE_SUCCESS,
  type CustomerProfileActions,
} from './actions';
import {
  customerProfileInitialState,
  type CustomerProfileState,
} from './model';

const customerProfileReducer: Reducer<
  CustomerProfileState,
  CustomerProfileActions | MyAccountAction
> = (state = customerProfileInitialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_CUSTOMER_PROFILE_SUCCESS:
      return {
        data: action.payload,
        isFetching: false,
        hasError: false,
      };
    case GET_CUSTOMER_PROFILE_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
      };
    case UPDATE_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.customerData,
        },
        isFetching: false,
        hasError: false,
      };
    default: {
      return state;
    }
  }
};

export default customerProfileReducer;
