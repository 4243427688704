import {
  SET_FLAG_VALUES,
  SET_FLAG_VALUES_AND_FLAG_VARIATIONS,
  SET_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT,
  SET_FLAG_VALUES_AND_FLAG_VARIATIONS_ERROR_CLIENT,
  type FlagsAction,
} from './flags.actions';
import { flagsInitialState, type FlagsState } from './flags.state';

export function flagsReducer(
  state: FlagsState = flagsInitialState,
  action: FlagsAction,
): FlagsState {
  switch (action.type) {
    case SET_FLAG_VALUES_AND_FLAG_VARIATIONS:
      return {
        ...state,
        flagValuesMap: action.flagValuesMap,
        campaignVariations: action.campaignVariations,
      };

    case SET_FLAG_VALUES_AND_FLAG_VARIATIONS_CLIENT:
      return {
        ...state,
        flagValuesMap: action.flagValuesMap,
        campaignVariations: action.campaignVariations,
        clientSideFlagsRetrieved: true,
      };

    case SET_FLAG_VALUES_AND_FLAG_VARIATIONS_ERROR_CLIENT:
      return {
        ...state,
        clientSideFlagsRetrieved: true,
      };

    case SET_FLAG_VALUES:
      return {
        ...state,
        flagValuesMap: action.values,
      };

    default:
      return state;
  }
}
