import { FlagVariation } from '@/domains/core/flags/flags.state';
import {
  CampaignVariationsData,
  CampaignVariationsDataFormatType,
} from '@/domains/core/tracking/customData/custom.model';

type Formatters = {
  [key in CampaignVariationsDataFormatType]: (
    campaignVariations: FlagVariation[],
  ) => CampaignVariationsData<key>['campaign_variations'];
};

const algo: Formatters = {
  current: (campaignVariations) => {
    return campaignVariations
      .filter(({ type }) => type === 'ab')
      .map(({ campaignId, variationId }) => ({
        campaign_id: campaignId.slice(-7),
        variation_id: variationId.slice(-3),
      }));
  },
  next: (campaignVariations) => {
    return campaignVariations
      .filter(({ type }) => type === 'ab')
      .map(
        ({ campaignId, variationId }) =>
          `${campaignId.slice(-7)}_${variationId.slice(-3)}` as `${string}_${string}`,
      );
  },
};

export function createCampaignVariationsData<
  Format extends CampaignVariationsDataFormatType,
>(
  format: Format,
  campaignVariations: FlagVariation[],
): CampaignVariationsData<Format> {
  return {
    campaign_variations: algo[format](campaignVariations),
  };
}
