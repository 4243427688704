import type { AlertMessage } from '../FlashMessages.actions';

export function shiftQueue(
  alertMessagesObject: Record<number, Array<AlertMessage>>,
): any {
  const newAlertMessagesObj = { ...alertMessagesObject };
  const keys: string[] = Object.keys(alertMessagesObject).sort(
    (a, b) => parseInt(b, 10) - parseInt(a, 10),
  );
  if (keys.length > 0) {
    const firstKey: number = parseInt(keys[0], 10);
    if (newAlertMessagesObj[firstKey].length > 0) {
      newAlertMessagesObj[firstKey].shift();
    }
    if (newAlertMessagesObj[firstKey].length === 0) {
      delete newAlertMessagesObj[firstKey];
    }
  }
  return newAlertMessagesObj;
}
