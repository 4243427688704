import type { SpartacuxCustomerManagementState } from '../customerManagement.model';
import {
  customerProfileInitialState,
  initialCustomerProfile,
  type CustomerProfileData,
  type CustomerProfileState,
} from './model';

export const selectCustomerProfileState = (
  state: SpartacuxCustomerManagementState,
): CustomerProfileState =>
  state.customerManagement?.customerProfile ?? customerProfileInitialState;

export const selectCustomerProfile = (
  state: SpartacuxCustomerManagementState,
): CustomerProfileData =>
  state.customerManagement?.customerProfile?.data ?? initialCustomerProfile;

export const selectIsFetching = (state: SpartacuxCustomerManagementState) =>
  state.customerManagement?.customerProfile?.isFetching;

export const selectHasError = (state: SpartacuxCustomerManagementState) =>
  state.customerManagement?.customerProfile?.hasError;
