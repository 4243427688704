import { PLATFORMS } from '../../settings/constants';
import { getPlatform, getTLD } from '../../settings/utils';
import { DEVSPACE_HOST, IS_DEVSPACE, MULTI_BRANCH_SLUG } from '../constants';
import { getDomainEnvironment } from './getDomainEnvironment';

const adviceDomains = {
  [PLATFORMS.FRANCE]: 'conseil',
  [PLATFORMS.GERMANY]: 'tipp',
  [PLATFORMS.GREAT_BRITAIN]: 'advice',
  [PLATFORMS.ITALY]: 'consigli',
  [PLATFORMS.SPAIN]: 'consejos',
};

export function getDomains() {
  const mainDomain = `${getDomainEnvironment()}manomano${getTLD()}`;

  if (IS_DEVSPACE) {
    const devspaceHost = DEVSPACE_HOST;
    return {
      domainBase: mainDomain,
      domainAdvice: `${adviceDomains[getPlatform()]}-${devspaceHost}.${mainDomain}`,
      domainPro: `pro-${devspaceHost}.${mainDomain}`,
      domainWww: `www-${devspaceHost}.${mainDomain}`,
    };
  }

  return {
    domainBase: mainDomain,
    domainAdvice: `${MULTI_BRANCH_SLUG ? `${MULTI_BRANCH_SLUG}-` : ''}${
      adviceDomains[getPlatform()]
    }.${mainDomain}`,
    domainPro: `${
      MULTI_BRANCH_SLUG ? `${MULTI_BRANCH_SLUG}-` : ''
    }pro.${mainDomain}`,
    domainWww: `${
      MULTI_BRANCH_SLUG ? `${MULTI_BRANCH_SLUG}-` : ''
    }www.${mainDomain}`,
  };
}
