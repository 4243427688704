import type { Currency } from '@/domains/core/localization/interfaces/Currency';
import type { Platform } from '@/domains/core/settings/interfaces/Platform';

const platformMap: { [key in Platform]: Currency } = {
  es: 'EUR',
  fr: 'EUR',
  gb: 'GBP',
  de: 'EUR',
  it: 'EUR',
};

export function getPlatformCurrency(platform: Platform): Currency {
  return platformMap[platform];
}
