import type { ActionWithPayload } from '../applicationState/interfaces';

export const TRACKING_DONE = 'core/tracking/TRACKING_DONE';
export const TRACKING_ERROR = 'core/tracking/TRACKING_ERROR';
export const SET_VISITOR_ID = 'core/tracking/SET_VISITOR_ID';
export const SET_REQUESTER_TYPE = 'core/tracking/SET_REQUESTER_TYPE';
export const GET_REQUESTER_TYPE = 'core/tracking/GET_REQUESTER_TYPE';

export type TrackingVisitorIdSuccessAction = ActionWithPayload<
  {
    visitorId?: string;
  },
  typeof SET_VISITOR_ID
>;

export interface GetRequesterType {
  type: typeof GET_REQUESTER_TYPE;
}

export type SetRequesterType = ActionWithPayload<
  {
    requesterType?: string;
  },
  typeof SET_REQUESTER_TYPE
>;

export type TrackingRequesterTypeAction = GetRequesterType | SetRequesterType;
