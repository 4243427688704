import { HydrateAction } from '@/domains/core/applicationState/store/hydrateAction';
import type { ProcessedRecommendation } from '@/productDiscovery/Recommendation/modules/ProductHistoryRecommendation/RecommendationsFromProductHistory.model';

import type { PlaylistHistory, ProductHistory } from './ProductHistory.model';

export const PRODUCT_HISTORY_FAILED =
  'productDiscovery/Recommendations/ProductHistory/PRODUCT_HISTORY_FAILED';

export interface ProductHistoryFailed {
  type: typeof PRODUCT_HISTORY_FAILED;
  error: Error;
}

export const productHistoryFailed = (error: Error): ProductHistoryFailed => ({
  type: PRODUCT_HISTORY_FAILED,
  error,
});

// FETCH_PRODUCT_HISTORY
export const FETCH_PRODUCT_HISTORY =
  'productDiscovery/Recommendations/ProductHistory/FETCH_PRODUCT_HISTORY';
export const FETCH_PRODUCT_HISTORY_SUCCESS =
  'productDiscovery/Recommendations/ProductHistory/FETCH_PRODUCT_HISTORY_SUCCESS';
export const FETCH_PLAYLIST_PRODUCT_HISTORY_SUCCESS =
  'productDiscovery/Recommendations/ProductHistory/FETCH_PLAYLIST_PRODUCT_HISTORY_SUCCESS';
export const FETCH_PLAYLIST_PRODUCT_HISTORY_ERROR =
  'productDiscovery/Recommendations/ProductHistory/FETCH_PLAYLIST_PRODUCT_HISTORY_ERROR';
export const FETCH_PRODUCT_HISTORY_PENDING =
  'productDiscovery/Recommendations/ProductHistory/FETCH_PRODUCT_HISTORY_PENDING';

export interface FetchProductHistory {
  type: typeof FETCH_PRODUCT_HISTORY;
}

export const fetchProductHistory = (): FetchProductHistory => ({
  type: FETCH_PRODUCT_HISTORY,
});

export interface FetchProductHistoryPending {
  type: typeof FETCH_PRODUCT_HISTORY_PENDING;
}

export const fetchProductHistoryPending = (): FetchProductHistoryPending => ({
  type: FETCH_PRODUCT_HISTORY_PENDING,
});

export interface FetchProductHistorySuccess {
  type: typeof FETCH_PRODUCT_HISTORY_SUCCESS;
  history: ProductHistory;
}

export const fetchProductHistorySuccess = (
  history: ProductHistory,
): FetchProductHistorySuccess => ({
  type: FETCH_PRODUCT_HISTORY_SUCCESS,
  history,
});

export interface FetchPlaylistProductHistorySuccess {
  type: typeof FETCH_PLAYLIST_PRODUCT_HISTORY_SUCCESS;
  history: any;
}

export const fetchPlaylistProductHistorySuccess = (
  history: PlaylistHistory[],
): FetchPlaylistProductHistorySuccess => ({
  type: FETCH_PLAYLIST_PRODUCT_HISTORY_SUCCESS,
  history,
});

export interface FetchPlaylistProductHistoryError {
  type: typeof FETCH_PLAYLIST_PRODUCT_HISTORY_ERROR;
  error: Error;
}

export const fetchPlaylistProductHistoryError = (
  error: Error,
): FetchPlaylistProductHistoryError => ({
  type: FETCH_PLAYLIST_PRODUCT_HISTORY_ERROR,
  error,
});

// MERGE_PRODUCT_HISTORY

export const MERGE_PRODUCT_HISTORY =
  'productDiscovery/Recommendations/ProductHistory/MERGE_PRODUCT_HISTORY';
export const MERGE_PRODUCT_HISTORY_SUCCESS =
  'productDiscovery/Recommendations/ProductHistory/MERGE_PRODUCT_HISTORY_SUCCESS';
export const MERGE_PRODUCT_HISTORY_FAILED =
  'productDiscovery/Recommendations/ProductHistory/MERGE_PRODUCT_HISTORY_FAILED';

export interface MergeProductHistoryFailed {
  type: typeof MERGE_PRODUCT_HISTORY_FAILED;
  history: ProductHistory;
}

export const mergeProductHistoryFailed = (
  history: ProductHistory,
): MergeProductHistoryFailed => ({
  type: MERGE_PRODUCT_HISTORY_FAILED,
  history,
});

export interface MergeProductHistorySuccess {
  type: typeof MERGE_PRODUCT_HISTORY_SUCCESS;
  history: ProductHistory;
}

export const mergeProductHistorySuccess = (
  history: ProductHistory,
): MergeProductHistorySuccess => ({
  type: MERGE_PRODUCT_HISTORY_SUCCESS,
  history,
});

// ADD_PRODUCT_TO_PRODUCT_HISTORY

export const ADD_TO_PRODUCT_HISTORY =
  'productDiscovery/Recommendations/ProductHistory/ADD_TO_PRODUCT_HISTORY';
export const ADD_TO_PRODUCT_HISTORY_SUCCESS =
  'productDiscovery/Recommendations/ProductHistory/ADD_TO_PRODUCT_HISTORY_SUCCESS';

export interface AddProductToProductHistory {
  type: typeof ADD_TO_PRODUCT_HISTORY;
  productId: number;
}

export const addProductToProductHistory = (
  productId: number,
): AddProductToProductHistory => ({
  type: ADD_TO_PRODUCT_HISTORY,
  productId,
});

export interface AddProductToProductHistorySuccess {
  type: typeof ADD_TO_PRODUCT_HISTORY_SUCCESS;
  productHistory: ProductHistory;
}

export const addProductToProductHistorySuccess = (
  productHistory: ProductHistory,
): AddProductToProductHistorySuccess => ({
  type: ADD_TO_PRODUCT_HISTORY_SUCCESS,
  productHistory,
});

export const GET_RECOMMENDATIONS_FROM_PRODUCT_HISTORY_SUCCESS =
  'productDiscovery/Recommendations/GET_RECOMMENDATIONS_FROM_PRODUCT_HISTORY_SUCCESS';

export interface GetModelIdsRecommendationsFromProductHistorySuccess {
  type: typeof GET_RECOMMENDATIONS_FROM_PRODUCT_HISTORY_SUCCESS;
  recommendation: ProcessedRecommendation;
}

export const getModelIdsRecommendationsFromProductHistorySuccess = (
  recommendation: ProcessedRecommendation,
): GetModelIdsRecommendationsFromProductHistorySuccess => ({
  type: GET_RECOMMENDATIONS_FROM_PRODUCT_HISTORY_SUCCESS,
  recommendation,
});

export type Action =
  | HydrateAction
  | FetchProductHistory
  | FetchPlaylistProductHistorySuccess
  | FetchPlaylistProductHistoryError
  | FetchProductHistorySuccess
  | FetchProductHistoryPending
  | ProductHistoryFailed
  | AddProductToProductHistory
  | AddProductToProductHistorySuccess
  | MergeProductHistoryFailed
  | MergeProductHistorySuccess
  | GetModelIdsRecommendationsFromProductHistorySuccess;
