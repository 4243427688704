import {
  AntiChurn,
  AppCluster,
  AudienceGroup,
  B2BIntention,
  B2BSubscription,
  B2BSuspectedPro,
  BuyNumberCluster,
  CustomerCluster,
  CustomerProfile,
  CustomerSegment,
  CustomerSegmentFromGQL,
  CustomerValue,
  LastChanceChurn,
  LoyaltyHvcLevel,
  LoyaltyLevel,
  SuspectedProType,
} from '../auth/model';

export const convertCustomerSegmentFromGQL = (
  input: CustomerSegmentFromGQL,
): CustomerSegment => ({
  b2b_intention: input.b2b_intention
    ? B2BIntention[input.b2b_intention as keyof typeof B2BIntention]
    : null,
  b2b_welcome: input.b2b_welcome ?? null,
  b2b_suspected_pro: input.b2b_suspected_pro
    ? B2BSuspectedPro[input.b2b_suspected_pro as keyof typeof B2BSuspectedPro]
    : null,
  suspected_pro_type: input.suspected_pro_type
    ? SuspectedProType[
        input.suspected_pro_type as keyof typeof SuspectedProType
      ]
    : null,
  b2b_vat_modal: input.b2b_vat_modal ?? null,
  profile: input.profile
    ? CustomerProfile[input.profile as keyof typeof CustomerProfile]
    : null,
  cluster: input.cluster
    ? CustomerCluster[input.cluster as keyof typeof CustomerCluster]
    : null,
  buy_number_cluster: input.buy_number_cluster
    ? BuyNumberCluster[
        input.buy_number_cluster as keyof typeof BuyNumberCluster
      ]
    : null,
  audience_group: input.audience_group
    ? AudienceGroup[input.audience_group as keyof typeof AudienceGroup]
    : null,
  loyalty_level: input.loyalty_level
    ? LoyaltyLevel[input.loyalty_level as keyof typeof LoyaltyLevel]
    : null,
  loyalty_hvc_level: input.loyalty_hvc_level
    ? LoyaltyHvcLevel[input.loyalty_hvc_level as keyof typeof LoyaltyHvcLevel]
    : null,
  app_cluster: input.app_cluster
    ? AppCluster[input.app_cluster as keyof typeof AppCluster]
    : null,
  last_chance_churn: input.last_chance_churn
    ? LastChanceChurn[input.last_chance_churn as keyof typeof LastChanceChurn]
    : null,
  anti_churn: input.anti_churn
    ? AntiChurn[input.anti_churn as keyof typeof AntiChurn]
    : null,
  b2b_subscription: input.b2b_subscription
    ? B2BSubscription[input.b2b_subscription as keyof typeof B2BSubscription]
    : null,
  customer_value: input.customer_value
    ? CustomerValue[input.customer_value as keyof typeof CustomerValue]
    : null,
});
