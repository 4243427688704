import {
  DISPLAY_ADVERTISER_INFORMATION,
  GET_COMMERCIAL_OPERATIONS_SUCCESS,
  GET_MARKETING_CAMPAIGNS,
  GET_MARKETING_CAMPAIGNS_FAILED,
  GET_MARKETING_CAMPAIGNS_SUCCESS,
  HIDE_ADVERTISER_INFORMATION,
  type Action,
} from '@/domains/productDiscovery/CommercialAnimation/CommercialAnimation.actions';
import {
  initialState,
  type CommercialAnimationState,
} from '@/domains/productDiscovery/CommercialAnimation/CommercialAnimation.model';

export function reducer(
  state: CommercialAnimationState = initialState,
  action: Action,
): CommercialAnimationState {
  switch (action.type) {
    case GET_COMMERCIAL_OPERATIONS_SUCCESS:
      return {
        ...state,
        commercialOperations: {
          items: action.data,
          fetched: true,
        },
      };

    case GET_MARKETING_CAMPAIGNS:
      return {
        ...state,
        marketingCampaigns: {
          ...state.marketingCampaigns,
          fetching: true,
        },
      };

    case GET_MARKETING_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        marketingCampaigns: {
          fetching: false,
          items: action.marketingCampaigns,
        },
      };

    case GET_MARKETING_CAMPAIGNS_FAILED:
      return {
        ...state,
        marketingCampaigns: {
          fetching: false,
          error: action.error,
        },
      };

    case DISPLAY_ADVERTISER_INFORMATION:
      return {
        ...state,
        advertiserInformation: {
          data: action.advertiserInformation,
        },
      };

    case HIDE_ADVERTISER_INFORMATION:
      return {
        ...state,
        advertiserInformation: {
          data: {
            dsaPopup: false,
            userSegments: false,
          },
        },
      };

    default:
      return state;
  }
}
