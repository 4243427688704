export const ALGOLIA_BOOSTING_SCORES = {
  MODEL_ID: 200,
  GOOD_DEALS_PODIUM: 150,
  SPONSORED: 75,
};

export const ALGOLIA_MAX_QUERY_LENGTH = 500;
export const TECHNICAL_FILTER_URL_PREFIX = 'cat_attr_';
export const TECHNICAL_FILTER_ALGOLIA_ATTRIBUTE = 'catalog_attribute';
export const TECHNICAL_FILTER_ALGOLIA_ATTRIBUTE_PIM = 'pim_attribute';
export const URL_MULTIPLE_FILTER_VALUE_SEPARATOR = '__';
export const LISTING_LAYOUT_ID = 'listing-layout';

export const NB_FACETS_VISIBLE = 7;

export const MAX_NB_PAGES = 100;
export const MAX_NB_PAGES_MASSLANDING = 10;
export const NB_PRODUCTS_PER_PAGE = 60;
export const NB_PRODUCTS_PER_PAGE_ALTERNATE = 48;
export const NB_PRODUCTS_PER_PAGE_BOTTOM_BANNER_DISPLAY = 24;
export const NB_PRODUCT_CARD_SKELETONS = 8;

export const NB_PRODUCT_CARD_WITHOUT_LAZY_IMG = 4;
export const NB_PRODUCT_CARD_AFTER_TIPSHEET = 4;

export const PAGE_TYPE = {
  SEARCH: 'search',
  GOOD_DEALS: 'promo_hub',
  MASS_LANDING: 'mass_landing',
  COMMERCIAL_OPERATION: 'commercial-operation-listing',
  BRAND: 'brand',
  CATEGORY: 'category_listing',
};

export const NB_SPONSORED_PRODUCTS_TO_DISPLAY = 8;
export const NB_SPONSORED_PRODUCTS_CONFIG = 9;

// Technical Filters
export const TECHNICAL_FILTERS_KEY = 'technicalFilters';

// Enum of string ids for new Feature Flags
export enum StringFeatureFlags {
  DISABLE_SPONSORED_FOR_CRAWLERS = 'disableSponsoredProductForCrawlers',
  LOG_SPONSORED_PRODUCTS_NOT_DISPLAYED = 'logSponsoredProductsNotDisplayed',
  CIRCUIT_BREAKER = 'circuitBreaker',
  BULK_LABEL = 'bulkPurchaseB2C', // even if the FF is called B2C, it's used for B2B as well
  X_RAY_LISTINGS_INSPECTOR = 'XRAY',
}

// AB tests
export enum StringABTests {
  INSPIRATIONAL_PRODUCT_CARDS = 'inspirationalProductCards',
  PIM_ATTRIBUTES = 'pimAttributes',
  MERGED_FILTERS = 'mergedFilters',
  PRO_PRICE_ADVANTAGE_LABEL = 'proPriceAdvantageLabel',
  PRO_PRICE_ADVANTAGE_LABEL_FLAGSHIP_ENABLED = 'proPriceAdvantageLabelFlagshipEnabled',
  RERANKING_SEARCH_AND_ML = 'rerankingSearchAndML',
  BRAND_FILTER_REVAMP = 'brandFilterRevamp',
  ALTERNATE_TITLE_IN_PC = 'alternateTitle',
  TECHNICAL_INFO_IN_PC = 'technicalInfoInPC',
  HIDE_SPONSORED_BRAND_ABTEST = 'promoteAnimateHideSponsoredBrand',
  REMOVE_FILTERS_RELATED_SCROLL = 'removeFiltersRelatedScroll',
}

// Retail Price Time Left Label
export const NB_OF_DAYS_TIME_LEFT_TO_DISPLAY_LABEL = 6;
export const NB_OF_HOURS_TO_DISPLAY_IN_DAYS = 3 * 24;
export const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
export const ONE_HOUR_IN_MILLISECONDS = 1000 * 60 * 60;

// TTL
/** 3 days */
export const SEA_BOT_S_MAX_AGE = 60 * 60 * 24 * 3;
