import { type GetCustomerProfileQuery } from '@/domains/customerManagement/services/msCustomer/queries/generated/getCustomerProfile.query.generated';

import { CustomerProfile } from '../interfaces/Customer';

type Concrete<Type> = {
  [Property in keyof Type]-?: Type[Property];
};

export type CustomerResponse = Concrete<GetCustomerProfileQuery>['customer'];

type Nullable<T> = { [K in keyof T]: T[K] | null };
export type CustomerProfileData = Nullable<CustomerProfile>;

export interface CustomerProfileState {
  data: CustomerProfileData;
  isFetching: boolean;
  hasError: boolean;
}

export const initialCustomerProfile: CustomerProfileData = {
  id: null,
  uuid: null,
  firstName: null,
  lastName: null,
  email: null,
  platform: null,
  timeZone: null,
  language: null,
  fiscalNumber: null,
  receiveOffers: null,
  receiveSmsOffers: null,
  receiveOffersPhoneNumber: null,
};

export const customerProfileInitialState: CustomerProfileState = {
  data: initialCustomerProfile,
  isFetching: false,
  hasError: false,
};
