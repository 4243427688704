import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useIsomorphicLayoutEffect } from '@/core/ui/hooks/useIsomorphicLayoutEffect';

let isNonShallowNavigation = false;
let shouldCleanStyles = true;

export function useSsrStylesCleaner() {
  const router = useRouter();

  useEffect(() => {
    if (!shouldCleanStyles) {
      return;
    }

    function handleRouteChangeStart(
      _: unknown,
      { shallow }: { shallow: boolean },
    ) {
      isNonShallowNavigation = !shallow;
    }

    function handleRouteChangeComplete() {
      isNonShallowNavigation = false;
    }

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);

  useIsomorphicLayoutEffect(() => {
    if (shouldCleanStyles && isNonShallowNavigation) {
      shouldCleanStyles = false;
      try {
        document.querySelectorAll('style[data-n-p]').forEach((element) => {
          element.parentNode?.removeChild(element);
        });
      } catch {
        // Ignored
      }
    }
  });
}
