import { getPlatformCurrency } from '@/domains/core/localization/utils/getPlatformCurrency';
import { getPlatformLocale } from '@/domains/core/localization/utils/getPlatformLocale';
import type { Platform } from '@/domains/core/settings/interfaces/Platform';

/**
 * @deprecated use getPlatform() in utils.ts instead
 */
export const PLATFORM = process.env.NEXT_PUBLIC_PLATFORM as Platform;
/**
 * @deprecated use getLocale() in utils.ts instead
 */
export const LOCALE = getPlatformLocale(PLATFORM);
/**
 * @deprecated use getCurrency() in utils.ts instead
 */
export const CURRENCY = getPlatformCurrency(PLATFORM);

export const PLATFORMS: {
  [key: string]: Platform;
} = {
  FRANCE: 'fr',
  GERMANY: 'de',
  GREAT_BRITAIN: 'gb',
  ITALY: 'it',
  SPAIN: 'es',
};

export const TLDS: {
  [key in Platform]: string;
} = {
  fr: '.fr',
  de: '.de',
  gb: '.co.uk',
  it: '.it',
  es: '.es',
};

/**
 * @deprecated use getTLD() in utils.ts instead
 */
export const TLD = TLDS[PLATFORM];

export const VATNumber = 'P. IVA FR19752979930';

/**
 * @deprecated use getMarket() in utils.ts instead
 */
export const MARKET = process.env.NEXT_PUBLIC_MARKET as 'b2b' | 'b2c';

/**
 * @deprecated use isB2B() in utils.ts instead
 */
export const IS_B2B = MARKET === 'b2b';

export const IS_CLIENT_SIDE = typeof window !== 'undefined';

/**
 * @see {@link https://www.cambiaresearch.com/articles/15/javascript-char-codes-key-codes}
 * @see {@link https://keycode.info/}
 */
export const KEYMAP: {
  [key: string]: number;
} = Object.freeze({
  BACKSPACE: 8,
  DELETE: 46,
  DOWN_ARROW: 40,
  END: 35,
  ENTER: 13,
  ESCAPE: 27,
  HOME: 36,
  LEFT_ARROW: 37,
  PAGE_DOWN: 34,
  PAGE_UP: 33,
  RIGHT_ARROW: 39,
  SPACE: 32,
  TAB: 9,
  UP_ARROW: 38,
});
