import {
  getCsrfToken,
  signIn as nextAuthSignIn,
  signOut as nextAuthSignOut,
  SignInAuthorizationParams,
  SignInOptions,
  SignOutParams,
  SignOutResponse,
} from 'next-auth/react';

import internalRoutes from '@/config/next/routes/internalRoutes.json';
import { httpClient } from '@/domains/core/httpClient/httpClient';
import {
  InternalRoute,
  InternalRoutes,
} from '@/domains/core/routing/routes/interfaces';
import { routes } from '@/domains/core/routing/routes/routes.default';
import { isPage } from '@/domains/core/routing/URLParser';
import { IS_CLIENT_SIDE } from '@/domains/core/settings/constants';
import { getLocale, isB2B } from '@/domains/core/settings/utils';

import {
  b2bKeycloakProviderId,
  b2cKeycloakProviderId,
  SESSION_UPDATE_TYPES,
  unifiedKeycloakProviderId,
} from './constants';

export async function getRefreshToken() {
  return httpClient.post('/api/auth/session', {
    csrfToken: await getCsrfToken(),
    data: {
      type: SESSION_UPDATE_TYPES.REFRESH_TOKEN,
    },
  });
}

export const signIn = async (
  options: SignInOptions & {
    b2b?: boolean;
    provider?: string;
    // Temporal mandatory param. The value must follow the abtest value for pepeUnifyAuth
    unified: boolean;
  },
  authorizationParams?: SignInAuthorizationParams,
) => {
  const { b2b = isB2B(), provider, unified, ...restOptions } = options ?? {};
  const queryParams = new URLSearchParams(authorizationParams);
  queryParams.set('ui_locales', getLocale());
  if (provider) queryParams.set('kc_idp_hint', provider);

  let clientProvider = b2b ? b2bKeycloakProviderId : b2cKeycloakProviderId;
  if (unified) {
    clientProvider = unifiedKeycloakProviderId;
  }

  nextAuthSignIn(clientProvider, restOptions, queryParams);
};

export const signOut = async (
  params: Omit<SignOutParams, 'redirect'> = {},
): Promise<SignOutResponse> => {
  const [signOutData] = await Promise.all([
    nextAuthSignOut({
      ...params,
      redirect: false,
    }),
    httpClient.post(`${routes.logout}?no_redirect=true`).catch(() => {}),
  ]);

  return signOutData;
};

export const isCurrentPageProtected = (): boolean => {
  if (!IS_CLIENT_SIDE) {
    throw new Error(
      "isCurrentPageProtected can't be called on the server side",
    );
  }

  return Object.entries(internalRoutes as InternalRoutes)
    .filter(
      (
        internalRoute,
      ): internalRoute is [keyof typeof routes, InternalRoute] => {
        const [key, value] = internalRoute;
        return key in routes && !!value.restricted;
      },
    )
    .map(([key]) => routes[key])
    .some((route) => isPage(route, window.location.pathname));
};
