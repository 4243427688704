import type { CoreState } from '@/domains/core/core.state';

import type {
  ProductHistory,
  ProductHistoryState,
} from './ProductHistory.model';

export const productHistoryFetchedSelector = (state: {
  productDiscovery: {
    productHistory: ProductHistoryState;
  };
}) => state.productDiscovery?.productHistory?.fetched;

export const productHistorySelector = (state: {
  productDiscovery: {
    productHistory: ProductHistoryState;
  };
}): ProductHistory => state.productDiscovery?.productHistory?.productHistory;

export const selectCategoryId = (state: { core: CoreState }) =>
  state?.core?.page?.pageCustomData?.categoryId;
