import { getAppSetting } from '@/domains/core/settings/appSettings';
import { UserId } from '@/domains/customerManagement/auth/model';

const hashString = async (str: string, salt: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(str + salt);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

export const hashedUserId = async (userId: UserId) => {
  if (!userId) return null;

  const salt = getAppSetting('BATCH_HASHING_SALT');
  const hash = await hashString(`${userId}`, salt);
  return hash;
};
