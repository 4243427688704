import { call, race, select, take } from 'typed-redux-saga';

import {
  ABTastyFormatType,
  ABTastySagaResult,
  Formatters,
} from '@/domains/core/ABTasty/ABTasty.model';
import { selectABTasty } from '@/domains/core/ABTasty/selectors/selectABTasty';
import { getAppSetting } from '@/domains/core/settings/appSettings';
import { waitForAndGetConsents } from '@/domains/legal/GDPR/utils/waitForAndGetConsents';

import {
  ABTASTY_SCRIPT_CANCELLED,
  ABTASTY_SCRIPT_LOADED,
  ABTASTY_TAG_EXECUTED,
} from './ABTasty.actions';
import { ABTASTY_TAG_STATES } from './ABTasty.state';
import {
  processABTests,
  processABTestsNext,
  processPersoCampaignABTasty,
  processPersoCampaignABTastyNext,
} from './utils';

const formatters: Formatters = {
  current: {
    formatAbTests: processABTests,
    formatPersoCampaign: processPersoCampaignABTasty,
  },
  next: {
    formatAbTests: processABTestsNext,
    formatPersoCampaign: processPersoCampaignABTastyNext,
  },
} as const;

export default function* rootABTastySaga<FormatType extends ABTastyFormatType>(
  format: FormatType,
): Generator<any, ABTastySagaResult<FormatType>, any> {
  const failState = (cause = '') => ({
    ab_tests: [],
    perso_campaign: [],
    ab_tasty_fail: cause,
  });

  const AB_TASTY_ID = getAppSetting('EXTERNAL_TAG_AB_TASTY_ID');

  if (!AB_TASTY_ID) {
    return failState('no id');
  }

  let abTastyState = yield* select(selectABTasty);

  if (abTastyState.abTastyTag === ABTASTY_TAG_STATES.CANCELLED) {
    return failState('cancelled');
  }

  const consents = yield* call(waitForAndGetConsents);

  if (
    !(
      consents.includes('statistics') &&
      consents.includes('personalisedAdvertising')
    )
  ) {
    return failState('no consent');
  }

  abTastyState = yield* select(selectABTasty);

  if (
    abTastyState.abTastyTag === ABTASTY_TAG_STATES.INIT ||
    abTastyState.abTastyTag === ABTASTY_TAG_STATES.LOADING
  ) {
    yield* race([take(ABTASTY_SCRIPT_LOADED), take(ABTASTY_SCRIPT_CANCELLED)]);
  }

  abTastyState = yield select(selectABTasty);

  if (abTastyState.abTastyTag === ABTASTY_TAG_STATES.CANCELLED) {
    return failState('cancelled bis');
  }

  if (window.lockABTastyTag && window.unlockABTasty) {
    window.unlockABTasty();
  } else if (window.ABTastyReload) {
    window.ABTastyReload();
  } else {
    return failState('unknown error');
  }

  yield* take(ABTASTY_TAG_EXECUTED);

  const history = window.ABTasty?.getCampaignHistory();
  const allTests = window.ABTasty?.accountData.tests;

  const { formatAbTests, formatPersoCampaign } = formatters[format];

  return {
    ab_tests: history && allTests ? formatAbTests(history, allTests) : [],
    perso_campaign:
      history && allTests ? formatPersoCampaign(history, allTests) : [],
  };
}
