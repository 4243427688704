export const enum merchandisingLabels {
  PROMO = 'promo',
  COUPON = 'coupon',
  IS_ECO_RESPONSABLE = 'is_eco_responsible',
  IS_LOCAL = 'is_local',
  TOP_SALE = 'top_sale',
  BLACKFRIDAY = 'blackfriday',
  SALES = 'sales',
  MANOMANODAYS = 'manomanodays',
  PRO_PRICE_ADVANTAGE = 'proPriceAdvantage',
  SPONSORED = 'sponsored',
  LOWERED_PRICE = 'loweredprice',
}

export type MerchandisingLabel = `${merchandisingLabels}`;
