import { httpClient } from '@/domains/core/httpClient/httpClient';
import type { PageStatusCode } from '@/domains/core/page/interfaces/PageStatusCode';

// Not in an env variable because it is handled directly by Next
const SEO_TRACKING_URL = '/api/v1/client-page-change';

export function sendSeoTrackingData(
  referer: string,
  statusCode: PageStatusCode | undefined,
): Promise<void> {
  const queryParams = {
    code: String(statusCode || '-1'),
    method: 'GET',
    to: window.location.href,
  } as Record<string, string>;

  if (referer) {
    queryParams.from = referer;
  }

  return httpClient.get(
    `${SEO_TRACKING_URL}?${new URLSearchParams(queryParams).toString()}`,
  );
}
