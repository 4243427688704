import { getAppSetting } from '../settings/appSettings';
import { PlatformEnvironment } from '../settings/interfaces/PlatformEnvironment';

export const canBeAddedReactScan = () => {
  const platformEnvironment = getAppSetting(
    'PLATFORM_ENVIRONMENT',
  ) as PlatformEnvironment;
  const API_KEY = getAppSetting('REACT_SCAN_MONITORING_API_KEY');

  if (!API_KEY && API_KEY === '') {
    return false;
  }

  if (platformEnvironment === 'staging') {
    return true;
  }

  return false;
};
