import { type Router, type RouterEvent } from 'next/router';
import { useEffect, useMemo, useRef } from 'react';

import { getHydrate } from '@/domains/core/applicationState/store/hydrateAction';
import StoreManager from '@/domains/core/applicationState/store/StoreManager';
import { mergeStates } from '@/domains/core/applicationState/util/state';

export const useHydrateManager = (
  storeManager: StoreManager,
  initialReduxState: Record<string, unknown>,
  reducersPaths: string[],
  router?: Router,
) => {
  const mustHydrate = useRef(false);

  useEffect(() => {
    const handleRouteChangeStart = (
      _: RouterEvent,
      { shallow }: { shallow: boolean },
    ) => {
      mustHydrate.current = !shallow;
    };

    router?.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router?.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router?.events]);

  useMemo(() => {
    if (mustHydrate.current) {
      const stateToHydrate = mergeStates(
        storeManager.store.getState(),
        initialReduxState,
        reducersPaths,
      );

      storeManager.store.dispatch(getHydrate(stateToHydrate));
      mustHydrate.current = false;
    }
  }, [initialReduxState, storeManager, reducersPaths]);
};
