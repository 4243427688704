import type { PageProps } from '@/core/page/interfaces/PageProps';

export const pageInitialState = {
  pageName: '',
  pageType: '',
  pageViewCustomData: {},
  pageCustomData: {},
};

export type PageState = Pick<
  PageProps,
  'pageName' | 'pageType' | 'pageViewCustomData' | 'pageCustomData'
> & {
  /**
   * Full URL of the previous page.
   *
   * @example https://www.manomano.fr/mentions-legales
   */
  referer?: string;
  statusCode?: PageProps['statusCode'];
};
