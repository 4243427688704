import { ThemeColorsMap } from '@/sellerAdsManagement/PartnerAdTheme/interfaces/partnerAdTheme';

export const INIT_PARTNER_AD_THEME =
  'sellerAdsManagement/INIT_PARTNER_AD_THEME';
export const FETCH_PARTNER_AD_THEME_SUCCESS =
  'sellerAdsManagement/FETCH_PARTNER_AD_THEME_SUCCESS';
export const FETCH_PARTNER_AD_THEME_FAILURE =
  'sellerAdsManagement/FETCH_PARTNER_AD_THEME_FAILURE';

export interface InitPartnerAdTheme {
  type: typeof INIT_PARTNER_AD_THEME;
}

export interface FetchPartnerAdThemeSuccess {
  type: typeof FETCH_PARTNER_AD_THEME_SUCCESS;
  colors: ThemeColorsMap;
}

export interface FetchPartnerAdThemeFailure {
  type: typeof FETCH_PARTNER_AD_THEME_FAILURE;
  error: string;
}

export const initPartnerAdTheme = () => ({
  type: INIT_PARTNER_AD_THEME,
});

export const fetchPartnerAdThemeSuccess = (colors: ThemeColorsMap) => ({
  type: FETCH_PARTNER_AD_THEME_SUCCESS,
  colors,
});

export const fetchPartnerAdThemeFailure = (error: string) => ({
  type: FETCH_PARTNER_AD_THEME_FAILURE,
  error,
});

export type Action =
  | InitPartnerAdTheme
  | FetchPartnerAdThemeSuccess
  | FetchPartnerAdThemeFailure;
