import type { Platform } from '@/domains/core/settings/interfaces/Platform';

const platformToTimezoneMap: { [key in Platform]: string } = {
  es: 'Europe/Madrid',
  fr: 'Europe/Paris',
  gb: 'Europe/London',
  de: 'Europe/Berlin',
  it: 'Europe/Rome',
};

export const getPlatformTimezone = (platform: Platform) =>
  platformToTimezoneMap[platform];
