import { INIT_CLIENT, type Action } from './client.action';
import { clientInitialState, type ClientState } from './client.state';

const reducer = (
  state: ClientState = clientInitialState,
  action: Action,
): ClientState => {
  switch (action.type) {
    case INIT_CLIENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
