import type { ClientState } from './client.state';

export const INIT_CLIENT = 'core/client/INIT_CLIENT';

export interface InitClientAction {
  type: typeof INIT_CLIENT;
  payload: ClientState;
}

export type Action = InitClientAction;
