import { ActionWithRequiredPayload } from '@/domains/core/applicationState/interfaces';
import { CustomerOptinStatus } from '@/domains/customerManagement/auth/model';
import type {
  ApiCustomerProfile,
  CustomerProfile,
  UpdateEmail,
  UpdatePassword,
} from '@/domains/customerManagement/interfaces/Customer';
import { ChannelsSubscriptions } from '@/domains/retention/UserProfiling/interfaces/Channel';
import type { AlertMessage } from '@/domains/shoppingCart/FlashMessages/FlashMessages.actions';

const ACTIONS_DOMAIN = 'myAccount';

export const UPDATE_CUSTOMER_PROFILE =
  `${ACTIONS_DOMAIN}/UPDATE_CUSTOMER_PROFILE` as const;
export const UPDATE_CUSTOMER_PROFILE_SUCCESS =
  `${ACTIONS_DOMAIN}/UPDATE_CUSTOMER_PROFILE_SUCCESS` as const;
export const UPDATE_CUSTOMER_PROFILE_FAILED =
  `${ACTIONS_DOMAIN}/UPDATE_CUSTOMER_PROFILE_FAILED` as const;

export const RESET_ERROR = `${ACTIONS_DOMAIN}/RESET_ERROR` as const;

export const UPDATE_CREDENTIALS =
  `${ACTIONS_DOMAIN}/UPDATE_CREDENTIALS` as const;
export const UPDATE_CREDENTIALS_FAILED =
  `${ACTIONS_DOMAIN}/UPDATE_CREDENTIALS_FAILED` as const;

export const UPDATE_OPTIN_STATUS =
  `${ACTIONS_DOMAIN}/UPDATE_OPTIN_STATUS` as const;
export const UPDATE_OPTIN_STATUS_SUCCESS =
  `${ACTIONS_DOMAIN}/UPDATE_OPTIN_STATUS_SUCCESS` as const;
export const UPDATE_OPTIN_STATUS_FAILED =
  `${ACTIONS_DOMAIN}/UPDATE_OPTIN_STATUS_FAILED` as const;

export interface UpdateCustomerProfileAction {
  type: typeof UPDATE_CUSTOMER_PROFILE;
  payload: {
    customerData: Partial<ApiCustomerProfile>;
    skipCustomerProfileReloading: boolean;
    successMessage?: AlertMessage;
    onSuccess?: () => void;
  };
}

export const updateCustomerProfileAction = (
  customerData: Partial<ApiCustomerProfile>,
  {
    successMessage,
    onSuccess,
    skipCustomerProfileReloading = false,
  }: {
    skipCustomerProfileReloading?: boolean;
    successMessage?: AlertMessage;
    onSuccess?: () => void;
  } = {},
): UpdateCustomerProfileAction => ({
  type: UPDATE_CUSTOMER_PROFILE,
  payload: {
    customerData,
    skipCustomerProfileReloading,
    successMessage,
    onSuccess,
  },
});

export interface UpdateCustomerProfileSuccess {
  type: typeof UPDATE_CUSTOMER_PROFILE_SUCCESS;
  payload: {
    skipCustomerProfileReloading: boolean;
    customerData?: Partial<ApiCustomerProfile>;
  };
}

export const updateCustomerProfileSuccess = (
  customerData?: Partial<CustomerProfile>,
  skipCustomerProfileReloading = false,
): UpdateCustomerProfileSuccess => ({
  type: UPDATE_CUSTOMER_PROFILE_SUCCESS,
  payload: { skipCustomerProfileReloading, customerData },
});

export interface UpdateCustomerProfileFailed {
  type: typeof UPDATE_CUSTOMER_PROFILE_FAILED;
  payload: string;
}

export const updateCustomerProfileFailed = (
  errorMessage: string,
): UpdateCustomerProfileFailed => ({
  type: UPDATE_CUSTOMER_PROFILE_FAILED,
  payload: errorMessage,
});

export interface ResetError {
  type: typeof RESET_ERROR;
}

export const resetError = (): ResetError => ({
  type: RESET_ERROR,
});

export interface UpdateCredentialsAction {
  type: typeof UPDATE_CREDENTIALS;
  payload: {
    credentials: UpdateEmail | UpdatePassword;
    skipCustomerProfileReloading: boolean;
    successMessage?: AlertMessage;
    onSuccess?: () => void;
  };
}

export interface UpdateCredentialsFailed {
  type: typeof UPDATE_CREDENTIALS_FAILED;
  payload: string;
}

export const updateCredentialsAction = (
  credentials: UpdateEmail | UpdatePassword,
  {
    successMessage,
    onSuccess,
    skipCustomerProfileReloading = false,
  }: {
    skipCustomerProfileReloading?: boolean;
    successMessage?: AlertMessage;
    onSuccess?: () => void;
  } = {},
): UpdateCredentialsAction => ({
  type: UPDATE_CREDENTIALS,
  payload: {
    credentials,
    skipCustomerProfileReloading,
    successMessage,
    onSuccess,
  },
});

export const updateCredentialsFailed = (
  errorMessage: string,
): UpdateCredentialsFailed => ({
  type: UPDATE_CREDENTIALS_FAILED,
  payload: errorMessage,
});

export interface UpdateOptinStatusPayload {
  optinStatus: Omit<ChannelsSubscriptions, 'source'>;
  onSuccess?: () => void;
  successMessage?: AlertMessage;
}

export interface UpdateOptinStatusAction
  extends ActionWithRequiredPayload<
    UpdateOptinStatusPayload,
    typeof UPDATE_OPTIN_STATUS
  > {}

export const updateOptinStatusAction = (
  payload: UpdateOptinStatusPayload,
): UpdateOptinStatusAction => ({
  type: UPDATE_OPTIN_STATUS,
  payload,
});

export interface UpdateOptinStatusSuccess
  extends ActionWithRequiredPayload<
    CustomerOptinStatus,
    typeof UPDATE_OPTIN_STATUS_SUCCESS
  > {}

export const updateOptinStatusSuccess = (
  payload: CustomerOptinStatus,
): UpdateOptinStatusSuccess => ({
  type: UPDATE_OPTIN_STATUS_SUCCESS,
  payload,
});

export interface UpdateOptinStatusFailed
  extends ActionWithRequiredPayload<
    string,
    typeof UPDATE_OPTIN_STATUS_FAILED
  > {}

export const updateOptinStatusFailed = (
  errorMessage: string,
): UpdateOptinStatusFailed => ({
  type: UPDATE_OPTIN_STATUS_FAILED,
  payload: errorMessage,
});

export type MyAccountAction =
  | UpdateCustomerProfileAction
  | UpdateCustomerProfileSuccess
  | UpdateCustomerProfileFailed
  | ResetError
  | UpdateCredentialsAction
  | UpdateCredentialsFailed
  | UpdateOptinStatusAction
  | UpdateOptinStatusSuccess
  | UpdateOptinStatusFailed;
