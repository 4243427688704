import {
  FETCH_HEADER_BANNERS_FAILED,
  FETCH_HEADER_BANNERS_SUCCESS,
  FETCHING_HEADER_BANNERS,
} from '@/domains/productDiscovery/HeaderLeonidas/components/HeaderBanner/HeaderBanners.actions';
import {
  FETCH_PRE_HEADERS_FAILED,
  FETCH_PRE_HEADERS_SUCCESS,
  FETCHING_PRE_HEADERS,
} from '@/domains/productDiscovery/HeaderLeonidas/components/PreHeader/PreHeader.actions';

import {
  GET_HEADER_INIT,
  GET_HEADER_INIT_FAILURE,
  GET_HEADER_INIT_SUCCESS,
  GET_HEADER_MENU,
  GET_HEADER_MENU_FAILED,
  GET_HEADER_MENU_SUCCESS,
  GET_MENU_CAMPAIGNS_FAILED,
  GET_MENU_CAMPAIGNS_SUCCESS,
  GET_QUICK_ACCESS_LINKS,
  GET_QUICK_ACCESS_LINKS_FAILURE,
  GET_QUICK_ACCESS_LINKS_SUCCESS,
  type Action,
} from './Header.actions';
import { initialState, type HeaderState } from './interfaces/Header.model';

export function reducer(
  state: HeaderState = initialState,
  action: Action,
): HeaderState {
  switch (action.type) {
    case GET_HEADER_MENU:
      return {
        ...state,
        menu: {
          ...state.menu,
          category: {
            ...state.menu?.category,
            fetching: true,
          },
        },
      };

    case GET_HEADER_MENU_SUCCESS:
      return {
        ...state,
        menu: {
          ...state.menu,
          category: {
            fetching: false,
            items: action.data.categories_tree,
          },
        },
      };

    case GET_HEADER_MENU_FAILED:
      return {
        ...state,
        menu: {
          ...state.menu,
          category: {
            fetching: false,
            error: action.error,
          },
        },
      };

    case GET_HEADER_INIT:
      return {
        ...state,
        quickAccessLinks: {
          ...state.quickAccessLinks,
          fetching: true,
        },
        menu: {
          ...state.menu,
          category: {
            ...state.menu?.category,
            fetching: true,
          },
        },
      };

    case GET_HEADER_INIT_SUCCESS:
      return {
        ...state,
        quickAccessLinks: {
          fetching: false,
          data: action.data.quickAccessLinks,
        },
        menu: {
          ...state.menu,
          category: {
            fetching: false,
            items: action.data.categoriesTree,
          },
        },
      };

    case GET_HEADER_INIT_FAILURE:
      return {
        ...state,
        quickAccessLinks: {
          fetching: false,
          error: action.error,
        },
        menu: {
          ...state.menu,
          category: {
            fetching: false,
            error: action.error,
          },
        },
      };

    case GET_QUICK_ACCESS_LINKS:
      return {
        ...state,
        quickAccessLinks: {
          ...state.quickAccessLinks,
          fetching: true,
        },
      };

    case GET_QUICK_ACCESS_LINKS_SUCCESS:
      return {
        ...state,
        quickAccessLinks: {
          fetching: false,
          data: action.data,
        },
      };

    case GET_QUICK_ACCESS_LINKS_FAILURE:
      return {
        ...state,
        quickAccessLinks: {
          fetching: false,
          error: action.error,
        },
      };

    case FETCHING_HEADER_BANNERS:
      return {
        ...state,
        headerBanners: {
          ...state.headerBanners,
          fetching: true,
        },
      };

    case FETCH_HEADER_BANNERS_SUCCESS:
      return {
        ...state,
        headerBanners: {
          fetching: false,
          data: action.campaigns,
        },
      };

    case FETCH_HEADER_BANNERS_FAILED:
      return {
        ...state,
        headerBanners: {
          fetching: false,
          error: action.error,
        },
      };

    case FETCHING_PRE_HEADERS:
      return {
        ...state,
        preHeaderGql: {
          ...state.preHeaderGql,
          fetched: false,
        },
      };

    case FETCH_PRE_HEADERS_SUCCESS:
      return {
        ...state,
        preHeaderGql: {
          fetched: true,
          data: action.preHeaders,
        },
      };

    case FETCH_PRE_HEADERS_FAILED:
      return {
        ...state,
        preHeaderGql: {
          fetched: true,
          error: action.error,
        },
      };

    case GET_MENU_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        menu: {
          ...state.menu,
          campaigns: {
            ...state.menu?.campaigns,
            [action.categoryId]: action.campaigns,
          },
        },
      };

    case GET_MENU_CAMPAIGNS_FAILED:
      return {
        ...state,
        menu: {
          ...state.menu,
          campaigns: {
            ...state.menu?.campaigns,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
}
