import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import type {
  PreHeaderModel,
  QuickAccessLinkModel,
} from '@/domains/productDiscovery/HeaderLeonidas/interfaces/HeaderLeonidas.server.model';

import type { MenuCategory } from './MenuTree.model';

export const initialState: HeaderState = { preHeaderGql: { fetched: false } };

// Menu Families used by old B2C, B2B and leonidas
interface MenuCategories {
  fetching: boolean;
  error?: string;
  items?: MenuCategory[];
}

export interface LeonidasQuickAccessLinks {
  fetching: boolean;
  error?: string;
  data?: QuickAccessLinkModel[];
}

export interface PreHeaderGql {
  fetched: boolean;
  error?: string;
  data?: PreHeaderModel[];
}

interface LeonidasHeaderBanners {
  fetching: boolean;
  error?: string;
  data?: BannerCampaign[];
}

export interface HeaderState {
  menu?: {
    category?: MenuCategories;
    campaigns?: {
      [key: number]: BannerCampaign[];
      error?: string;
    };
  };

  quickAccessLinks?: LeonidasQuickAccessLinks;
  preHeaderGql?: PreHeaderGql;
  headerBanners?: LeonidasHeaderBanners;
}
