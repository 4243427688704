type StateType = string | number | boolean | null | StateType[] | StateRootType;

type StateRootType = { [key: string]: StateType };

function isRecordStateType(
  state: StateType,
): state is { [key: string]: StateType } {
  return typeof state === 'object' && state !== null && !Array.isArray(state);
}

export function getStateFromPath(
  key: string,
  state: StateRootType | Record<string, unknown>,
): StateType {
  const segments = key.split('.');
  let returnValue = state as StateType;

  segments.forEach((segment) => {
    if (!isRecordStateType(returnValue)) {
      throw new Error(`Cannot get state from path ${key}`);
    }

    returnValue = returnValue[segment];
  });

  return returnValue;
}

export function mergeStates(
  target: StateType,
  source: StateRootType | Record<string, unknown>,
  paths: string[],
): StateType {
  const state = source as StateType;
  const resultState = structuredClone(target);

  paths.forEach((path) => {
    const pathArray = path.split('.');
    const pathValue = pathArray.reduce((acc, key) => {
      if (!isRecordStateType(acc)) {
        throw new Error(`Cannot merge state at path ${path}`);
      }
      return acc[key];
    }, state);

    let pathToSetNewValue = resultState;
    pathArray.forEach((key, index) => {
      if (!isRecordStateType(pathToSetNewValue)) {
        throw new Error(`Cannot merge state at path ${path}`);
      }
      if (index === pathArray.length - 1) {
        pathToSetNewValue[key] = pathValue;
        return;
      }
      pathToSetNewValue = pathToSetNewValue[key];
    });
    pathToSetNewValue = pathValue;
  });

  return resultState;
}
