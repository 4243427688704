import {
  isB2B,
  isFrancePlatform,
  isGermanyPlatform,
  isUkPlatform,
} from '@/core/settings/utils';
import type {
  ProductHit,
  RawPricesByType,
  RawPriceType,
} from '@/domains/productDiscovery/algolia/interfaces/hit';
import { processRetailPriceTimeLeftLabel } from '@/productDiscovery/CommercialAnimation/utils/commercialOperations.processor';

import { formatToISODate } from '../Listings/developerTools/utils/formatters';
import type {
  DetailedPrices,
  InspectListingProperties,
  Product,
  TaxKey,
} from './interfaces/product';

export const hitToProduct = (
  hit: ProductHit,
): Product & InspectListingProperties =>
  sanitize({
    productId: hit.objectID,
    articleId: hit.article_id,
    defaultTitle: hit.default_title,
    hasFreeDelivery: hit.has_free_delivery,
    hasRelayDelivery: hit.has_relay_delivery,
    has1dayDelivery: hit.has_1day_delivery,
    modelId: hit.model_id,
    sellerName: hit.seller_name,
    isMmf: hit.is_mmf,
    isLocal: isFrancePlatform() && hit.is_local,
    isEcoResponsible: hit.is_eco_responsible,
    brandName: hit.brand_name,
    banner: hit.banner,
    bannerAlternate: hit['banner.alternate'],
    prices: generatePrices(hit),
    commercialOperationIds: processCommercialOperationIds(hit),
    rating: hit.rating,
    ratingCount: hit.rating_count,
    title: hit.title,
    alternateTitle: hit.alternate_title,
    imageFullpath: hit.image_fullpath,
    sellerId: hit.seller_id,
    sellerCountryId: hit.seller_country_id,
    isSellerB2b: hit.is_seller_b2b === '1',
    isCompetitive: hit.is_competitive,
    brandId: hit.brand_id,
    brandImageFullpath: hit.brand_image_fullpath,
    url: hit.url,
    modelsCount: hit.models_count,
    categoriesL0: hit['categories.l0'],
    categoriesL1: hit['categories.l1'],
    categoriesL2: hit['categories.l2'],
    categoriesL3: hit['categories.l3'],
    categoriesLastId: hit['categories.last_id'],
    categoriesL0Id: hit['categories.l0.id'],
    categoriesL1Id: hit['categories.l1.id'],
    categoriesLast: hit['categories.last'],
    categoriesL2Id: hit['categories.l2.id'],
    categoriesL3Id: hit['categories.l3.id'],
    categoriesLastIds: hit['categories.last.id'],
    hasSample: hit.has_sample,
    hasProConditions: hit.has_pro_conditions,
    bestOffer: hit.best_offer,
    isBestQualityOffer: hit.is_best_quality_offer,
    indexable: hit.indexable,
    /* eslint-disable no-underscore-dangle */
    highlightResult: hit?._highlightResult?.title &&
      hit._highlightResult.default_title && {
        title: hit._highlightResult.title,
        defaultTitle: hit._highlightResult.default_title,
      },
    /* eslint-enable no-underscore-dangle */
    coupon: hit.coupon,
    bulk: hit.bulk,
    isB2b: hit.is_b2b,
    offerId: hit.offer_id,
    pimMasterProductId: hit.pim_master_product_id,
    pimOfferId: hit.pim_offer_id,
    catalogAttribute: hit.catalog_attribute,
    proAdvantagePercentage: hit.pro_advantage_percentage,
    technicalInfoValues: hit.top_attribute_values,
    ...(hit.commercial_animation && {
      commercialAnimation: {
        tags: hit.commercial_animation.tags || [],
        promotionEndDate: hit.commercial_animation.promotion_end_at || null,
        retailPriceTimeLeftLabel: hit.commercial_animation.promotion_end_at
          ? processRetailPriceTimeLeftLabel(
              hit.commercial_animation.promotion_end_at,
            )
          : null,
      },
    }),
    ...(hit.from_custom_query && { fromCustomQuery: hit.from_custom_query }),
    // eslint-disable-next-line no-underscore-dangle
    ...(hit._rankingInfo && {
      rerankingInfo: {
        // eslint-disable-next-line no-underscore-dangle
        dsReranking: hit._rankingInfo?.promoted || false,
        // eslint-disable-next-line no-underscore-dangle
        algoliaReranking: hit._rankingInfo?.promotedByReRanking || false,
      },
    }),
    ...(hit.document_update_time && {
      documentUpdateTime: formatToISODate(hit.document_update_time),
    }),
    ...(hit.document_full_update_time && {
      documentFullUpdateTime: formatToISODate(hit.document_full_update_time),
    }),
  });

const getPriceByKey = (
  hit: ProductHit,
  key: RawPriceType,
): RawPricesByType | undefined => {
  switch (key) {
    case 'per_item':
      return hit.prices.per_item;

    default:
      return undefined;
  }
};

export function getTaxKey(): TaxKey {
  return isB2B() && !isUkPlatform() ? 'without_vat' : 'with_vat';
}

export const invertedTaxMap: Record<TaxKey, TaxKey> = {
  without_vat: 'with_vat',
  with_vat: 'without_vat',
};

function getMainPrice(main: number, hit: ProductHit): number {
  return main * (hit.min_quantity || 1);
}

function getSecondaryPrice(secondary: number | undefined): number | null {
  return secondary || null;
}

function getRetailPrice(
  retail: number | undefined,
  hit: ProductHit,
): number | null {
  return retail ? retail * (hit.min_quantity || 1) : null;
}

function getDiscountPrice(key: RawPriceType, hit: ProductHit): string | null {
  return (key && hit.prices[key]?.discount) || null;
}

function generateB2CPrices(hit: ProductHit): DetailedPrices {
  const main = hit.prices.per_item.actual_price.with_vat.as_float;

  const unit =
    hit.prices.main_price === 'per_m2' || hit.prices.main_price === 'per_l'
      ? hit.prices[hit.prices.main_price]?.unit || null
      : null;

  let retail: number | undefined;
  let secondary: number | undefined;

  if (hit.prices.main_price === 'per_item') {
    retail = getPriceByKey(hit, hit.prices.main_price)?.retail_price?.with_vat
      .as_float;
    secondary = getPriceByKey(hit, hit.prices.secondary_price)?.actual_price
      .with_vat.as_float;
  } else if (
    hit.prices.main_price === 'per_m2' ||
    hit.prices.main_price === 'per_l'
  ) {
    retail = hit.prices.per_item.retail_price?.with_vat.as_float;
    secondary =
      hit.prices[hit.prices.main_price]?.actual_price.with_vat.as_float;
  }

  const hasNoVATForDE =
    isGermanyPlatform() &&
    hit['categories.last_id'] === 2257 &&
    main === hit.prices.per_item.actual_price.without_vat.as_float;

  return {
    main: getMainPrice(main, hit),
    retail: getRetailPrice(retail, hit),
    secondary: getSecondaryPrice(secondary),
    discount: getDiscountPrice(hit.prices.main_price, hit),
    unit,
    hasNoVATForDE,
  };
}

function generateB2BPrices(hit: ProductHit): DetailedPrices {
  const taxKey = getTaxKey();
  const invertedTaxKey = invertedTaxMap[taxKey];

  const main = hit.prices.per_item.actual_price[taxKey].as_float;
  let retail: number | undefined;
  let secondary: number | undefined;

  if (hit.prices.main_price === 'per_item') {
    retail = getPriceByKey(hit, hit.prices.main_price)?.retail_price?.[taxKey]
      .as_float;
    secondary = getPriceByKey(hit, hit.prices.main_price)?.actual_price[
      invertedTaxKey
    ].as_float;
  } else if (
    hit.prices.main_price === 'per_m2' ||
    hit.prices.main_price === 'per_l'
  ) {
    retail = hit.prices.per_item.retail_price?.[taxKey].as_float;
    secondary = hit.prices.per_item.actual_price?.[invertedTaxKey].as_float;
  }

  const hasNoVATForDE =
    isGermanyPlatform() &&
    hit['categories.last_id'] === 2257 &&
    hit.prices.per_item.actual_price.with_vat.as_float ===
      hit.prices.per_item.actual_price.without_vat.as_float;

  return {
    main: getMainPrice(main, hit),
    retail: getRetailPrice(retail, hit),
    secondary: getSecondaryPrice(secondary),
    discount: getDiscountPrice(hit.prices.main_price, hit),
    hasNoVATForDE,
  };
}

function generatePrices(hit: ProductHit): DetailedPrices {
  if (isB2B()) {
    return generateB2BPrices(hit);
  }

  return generateB2CPrices(hit);
}

function sanitize(
  obj: Product & InspectListingProperties,
): Product & InspectListingProperties {
  const sanitized = { ...obj };
  Object.keys(sanitized).forEach(
    (key) =>
      sanitized[key as keyof Product] === undefined &&
      delete sanitized[key as keyof Product],
  );

  return { ...sanitized };
}

function processCommercialOperationIds(hit: ProductHit): number[] {
  return (
    hit.commercial_operation_ids?.reduce<number[]>((ids, id) => {
      if (typeof id === 'string' && !id.includes('_')) {
        const parsedId = parseInt(id, 10);
        if (parsedId) {
          ids.push(parsedId);
        }
      } else if (typeof id === 'number') {
        ids.push(id);
      }
      return ids;
    }, []) || []
  );
}
