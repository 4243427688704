/* eslint-disable camelcase */
import {
  all,
  call,
  CallEffect,
  delay,
  put,
  PutEffect,
  race,
  select,
  take,
  takeEvery,
  type Effect,
} from 'redux-saga/effects';

import rootInvoiceSelfCareStatusSaga from '@/domains/care/onlineSupport/SelfCareInvoicePage/tracking/invoiceSelfCareStatus.saga';
import {
  ABTastyFormatType,
  ABTastySagaResult,
} from '@/domains/core/ABTasty/ABTasty.model';
import { isMobileAppUserAgentSelector } from '@/domains/core/client/client.selectors';
import { pageSelector } from '@/domains/core/core.selectors';
import { getFlagValuesSaga } from '@/domains/core/flags/sagas/getFlagValues.saga';
import { HttpStatusCode } from '@/domains/core/httpClient/HttpStatusCode';
import logger from '@/domains/core/observability/logger';
import { SET_PAGE_DATA } from '@/domains/core/page/page.actions';
import type { PageState } from '@/domains/core/page/page.state';
import type { PlatformEnvironment } from '@/domains/core/settings/interfaces/PlatformEnvironment';
import {
  getLocale,
  getMarket,
  getPlatform,
} from '@/domains/core/settings/utils';
import { PLATFORM_RELEASE } from '@/domains/core/shell/constants';
import { createCampaignVariationsData } from '@/domains/core/tracking/customData/custom.util';
import { selectRequesterType } from '@/domains/core/tracking/selectors/selectRequesterType';
import { selectVisitorId } from '@/domains/core/tracking/selectors/selectVisitorId';
import {
  SET_VISITOR_ID,
  TRACKING_DONE,
  TRACKING_ERROR,
} from '@/domains/core/tracking/tracking.actions';
import { PAGE_TYPE_PAYMENT } from '@/domains/customerFinanceOperations/constants/PageType';
import {
  CHECK_AUTH_TOKEN_ERROR,
  CHECK_AUTH_TOKEN_SUCCESS,
} from '@/domains/customerManagement/auth/actions';
import type {
  Auth,
  CustomerValue,
} from '@/domains/customerManagement/auth/model';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';
import {
  waitForAndGetConsents,
  type Consent,
} from '@/domains/legal/GDPR/utils/waitForAndGetConsents';
import { PAGE_TYPE_DELIVERY } from '@/domains/productDelivery/constants/PageType';

import rootABTastySaga from '../ABTasty/ABTasty.saga';
import { selectFlagVariations } from '../flags/flags.selectors';
import { type FlagVariation } from '../flags/flags.state';
import { getAppSetting } from '../settings/appSettings';
import {
  customDataNewFormatFlag,
  type CustomData,
} from './customData/custom.model';
import rootCustomDataSaga from './customData/custom.saga';
import { checkVisitorIdCookie } from './utils/checkVisitorIdCookie';
import {
  getPageInitialData,
  type PageInitialData,
  type RefererIdsFromSdk,
} from './utils/getPageInitialData';
import { getGoogleClickIdentifierFromSessionCookie } from './utils/googleClickIdentifier/getGoogleClickIdentifierFromSessionCookie';
import { Gtm } from './utils/Gtm';
import { sendSeoTrackingData } from './utils/sendSeoTrackingData';

const OK_STATUS_CODE = 200;
const RETRIEVE_DATA_TIMEOUT_MS = 10000;

/**
 * Event sent to GTM data layer that will contain page information.
 */
interface PageViewEvent {
  custom?: CustomData;
  customer_value?: CustomerValue;
  error_type?: string;
  event: 'pageview';
  has_blocker: boolean | undefined;
  is_crawler: 'true' | 'false' | '';
  is_guest: 'true' | 'false' | '';
  is_logged_in: 'true' | 'false' | '';
  is_marketing_consent: boolean;
  is_preferences_consent: boolean;
  is_statistics_consent: boolean;
  is_email_consent?: boolean;
  is_sms_consent?: boolean;
  is_web_view: boolean;
  locale_id: string;
  market: 'b2b' | 'b2c';
  page_name: string;
  page_type: string;
  platform_id: string;
  publisher_id?: string;
  raw_referer_id: string;
  referer?: string;
  release_number: string;
  release_tag: string;
  tracking_category?: string;
  tracking_channel?: string;
  tracking_campaign?: string;
  user_id: string;
  visit_referer_id: string;
  web_environment: PlatformEnvironment | '';
  /** Google CLick IDentifier */
  gclid?: string;
}

/**
 * Retrieves data that will change for each page in SPA mode.
 */
export function* pageChangeSaga(
  {
    environment,
    hasAdBlockerPromise,
    isCrawler,
    refererIdsPromise,
  }: PageInitialData,
  firstPage: boolean,
  formatType: ABTastyFormatType,
): Generator<any, any, any> {
  try {
    const auth = (yield select(authSelector)) as Auth;
    const waitForPageDataEffects: { [key: string]: Effect } = {
      refererIds: call(() => refererIdsPromise),
      customData: call(rootCustomDataSaga),
      invoiceSelfCareStatus: call(rootInvoiceSelfCareStatusSaga),
    };

    if (!firstPage) {
      waitForPageDataEffects.abtastyCustomData = call(
        rootABTastySaga,
        formatType,
      );
      Gtm.handlePageChange();
    }

    if (!auth.hasRetrievedAuthStatus) {
      waitForPageDataEffects.waitForAuthStatus = race([
        take(CHECK_AUTH_TOKEN_SUCCESS),
        take(CHECK_AUTH_TOKEN_ERROR),
      ]);
    }

    // Waits for all necessary data to be available or timeout to be reached
    const result = yield race({
      waitForPageData: all(waitForPageDataEffects),
      waitForTimeout: delay(RETRIEVE_DATA_TIMEOUT_MS),
    });

    if (result.waitForTimeout) {
      logger.error(
        new Error('Timeout reached when retrieving page view data.'),
      );
    }

    // Waits for the useEffect inside the usePageStatusCode to be executed not
    // to lose the status code.
    yield call(() => new Promise((resolve) => setTimeout(resolve, 0)));

    const consents = (yield call(waitForAndGetConsents)) as Consent[];

    const {
      pageName,
      pageType,
      pageViewCustomData,
      referer,
      statusCode = HttpStatusCode.OK,
    } = (yield select(pageSelector)) as PageState;

    if (
      !firstPage &&
      ![PAGE_TYPE_DELIVERY, PAGE_TYPE_PAYMENT, 'order_recap'].includes(pageType)
    ) {
      Gtm.resetDataLayer();
    }

    const {
      hasRetrievedAuthStatus,
      isAuthenticated,
      userId,
      isB2B,
      isGuest,
      customer_value,
    } = (yield select(authSelector)) as Auth;
    const isMobileApp: boolean = yield select(isMobileAppUserAgentSelector);
    const hasBlocker = (yield call(() => hasAdBlockerPromise)) as
      | boolean
      | undefined;

    const refererIds = result.waitForPageData.refererIds as
      | RefererIdsFromSdk
      | undefined;

    const { is_email_consent, is_sms_consent, ...customDataSegmentation } =
      result.waitForPageData.customData;
    const customData = {
      ...customDataSegmentation,
      ...result.waitForPageData.invoiceSelfCareStatus,
    } as CustomData;

    let abtastyCustomData: ABTastySagaResult<typeof formatType> | undefined;
    if (firstPage) {
      const { waitForABTastyData } = (yield race({
        waitForABTastyData: call(rootABTastySaga, formatType),
        waitForABTastyTimeout: delay(RETRIEVE_DATA_TIMEOUT_MS),
      })) as {
        waitForABTastyData?: ABTastySagaResult<typeof formatType>;
        waitForABTastyTimeout?: true;
      };

      abtastyCustomData = waitForABTastyData;
    } else {
      abtastyCustomData = {
        ...result.waitForPageData.abtastyCustomData,
      };
    }

    const campaignVariations: FlagVariation[] =
      yield select(selectFlagVariations);

    const visitorId = yield select(selectVisitorId);

    const campaignVariationsData = createCampaignVariationsData(
      formatType,
      campaignVariations,
    );

    const event: PageViewEvent = {
      custom: {
        ...(isB2B && { is_new_pro: true }),
        ...customData,
        ...pageViewCustomData,
        ...campaignVariationsData,
        ...abtastyCustomData,
        visitor_id: visitorId,
      },
      event: 'pageview',
      has_blocker: hasBlocker,
      customer_value:
        hasRetrievedAuthStatus && customer_value ? customer_value : undefined,
      is_crawler:
        isCrawler !== undefined ? (String(isCrawler) as 'true' | 'false') : '',
      is_email_consent,
      is_guest: hasRetrievedAuthStatus
        ? (String(isGuest) as 'true' | 'false')
        : '',
      is_logged_in: hasRetrievedAuthStatus
        ? (String(isAuthenticated) as 'true' | 'false')
        : '',
      is_marketing_consent: consents.includes('personalisedAdvertising'),
      is_preferences_consent: consents.includes('userPreferences'),
      is_sms_consent,
      is_statistics_consent: consents.includes('statistics'),
      is_web_view: isMobileApp,
      locale_id: getLocale(),
      market: getMarket(),
      page_name: pageName,
      page_type: pageType,
      platform_id: getPlatform(),
      publisher_id: getPublisherIdFromQueryString(),
      raw_referer_id: refererIds?.rawRefererId ? refererIds.rawRefererId : '',
      referer: document.referrer,
      release_number: PLATFORM_RELEASE,
      release_tag: getAppSetting('VERSION'),
      user_id: hasRetrievedAuthStatus && userId !== null ? String(userId) : '',
      visit_referer_id: refererIds?.refererId ? refererIds.refererId : '',
      web_environment: environment,
    };

    if (refererIds?.trackingCampaign) {
      event.tracking_campaign = refererIds.trackingCampaign;
    }
    if (refererIds?.trackingCategory) {
      event.tracking_category = refererIds.trackingCategory;
    }
    if (refererIds?.trackingChannel) {
      event.tracking_channel = refererIds.trackingChannel;
    }

    if (statusCode !== OK_STATUS_CODE) {
      event.error_type = String(statusCode);
    }

    event.gclid = getGoogleClickIdentifierFromSessionCookie();

    Gtm.push(event);

    // On SSR mode the SEO tracking will be managed by the CDN directly so we
    // mustn't call the endpoint.
    if (referer !== undefined) {
      yield call(sendSeoTrackingData, referer, statusCode);
    }

    yield put({ type: TRACKING_DONE });
  } catch (error) {
    logger.error(error);
    yield put({ type: TRACKING_ERROR });
  }
}

/**
 * Retrieves necessary page information and sends it to GTM data layer for
 * tracking purposes.
 */
export function* pageViewSaga(): Generator<any, any, any> {
  const { pageType } = (yield select(pageSelector)) as PageState;
  /*TODO: this was a call to 'areFeatureFlagsEnabledSaga' replaced with the value of the flag/abtest. Consider removing this call when you modify the file.*/
  const [isRefererSdkCookieUsingDisabled] = [false];
  const [isNewFormatEnabled] = yield call(
    getFlagValuesSaga,
    customDataNewFormatFlag,
  );
  const requesterType = yield select(selectRequesterType);
  const initialData = getPageInitialData(
    pageType,
    requesterType,
    isRefererSdkCookieUsingDisabled,
  );

  yield all([
    call(
      pageChangeSaga,
      initialData,
      true,
      isNewFormatEnabled ? 'next' : 'current',
    ),
    takeEvery(
      SET_PAGE_DATA,
      pageChangeSaga,
      initialData,
      false,
      isNewFormatEnabled ? 'next' : 'current',
    ),
  ]);
}

/**
 * Retrieve the visitorId
 */
export function* visitorIdSaga(): Generator<
  CallEffect<string> | PutEffect,
  void,
  string
> {
  try {
    const visitorId = yield call(checkVisitorIdCookie);
    yield put({ type: SET_VISITOR_ID, payload: { visitorId } });
  } catch (error) {
    logger.error(error);
  }
}

export function getPublisherIdFromQueryString(): string | undefined {
  const pageSearchParams = new URLSearchParams(window.location.search);
  if (pageSearchParams.has('publisher_id')) {
    const value = pageSearchParams.get('publisher_id');
    if (value !== null) {
      // isNaN only accepts numbers : we convert value to number using '+' then use isNaN
      return Number.isNaN(+value) ? undefined : value;
    }
  }

  return undefined;
}

export default function* rootTrackingSaga() {
  yield all([take(SET_PAGE_DATA), call(visitorIdSaga)]);
  yield call(pageViewSaga);
}
