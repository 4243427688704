export const IS_DEVSPACE = process.env.NEXT_PUBLIC_DEVSPACE === 'true';
export const DEVSPACE_HOST = process.env.NEXT_PUBLIC_DEVSPACE_HOST;
export const DEVSPACE_URL = `https://${DEVSPACE_HOST}.${process.env.NEXT_PUBLIC_DEVSPACE_ENV}.manomano.${process.env.NEXT_PUBLIC_PLATFORM}`;

export const IS_LOCAL =
  (process.env.NODE_ENV === 'development' ||
    process.env.ENVIRONMENT === 'dev') &&
  !IS_DEVSPACE;

// Do we really need the prod mode with devspace ?
export const IS_PROD = process.env.NODE_ENV === 'production' || IS_DEVSPACE;

export const MULTI_BRANCH_SLUG = `${
  process.env.NEXT_PUBLIC_MULTI_BRANCH_SLUG || ''
}`;
