import { GCLID_COOKIE_NAME } from './constants';

/**
 * Get the Google Click Identifier session cookie and return the value if it is
 * valid.
 * If the cookie value has his expiration date expired, it will be removed.
 * @returns The gclid value if it is valid.
 */
export const getGoogleClickIdentifierFromSessionCookie = ():
  | string
  | undefined => {
  const gclidCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${GCLID_COOKIE_NAME}=`));
  const value = gclidCookie ? gclidCookie.split('=')[1] : undefined;
  if (value) {
    try {
      const { gclid, exp } = JSON.parse(value);
      if (gclid && exp && Date.now() < exp) {
        return gclid;
      } else {
        removeGoogleClickIdentifierSessionCookie();
      }
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};

export const removeGoogleClickIdentifierSessionCookie = (): void => {
  document.cookie = `${GCLID_COOKIE_NAME}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
};
