import { IS_LOCAL } from '../platform/constants';
import { getDomainEnvironment } from '../platform/domains/getDomainEnvironment';
import { getTLD } from '../settings/utils';

export const getCookieDomain = (env?: string) => {
  if (IS_LOCAL) {
    return 'localhost';
  }

  return `.${getDomainEnvironment(env)}manomano${getTLD()}`;
};
