import type { Action } from 'redux';

type Handlers<State, Types extends Record<string, any>> = {
  readonly [T in keyof Types]?: (
    state: State,
    // @ts-expect-error remove this createReducer and use redux toolkit
    action: Action<T> & { payload: Types[T] },
  ) => State;
};

const createReducer =
  <State, Types extends Record<string, any>>(
    initialState: State,
    handlers: Handlers<State, Types>,
  ) =>
  (state = initialState, action: { type: keyof Types; payload: any }) => {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      const handler = handlers[action.type];
      if (handler) {
        return handler(state, action);
      }
      return state;
    }
    return state;
  };

export default createReducer;
