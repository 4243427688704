import type { CustomerProfile } from '../interfaces/Customer';

export const domain = 'customerManagement/customerProfile';

export const GET_CUSTOMER_PROFILE_REQUEST =
  `${domain}/GET_CUSTOMER_PROFILE_REQUEST` as const;
export const GET_CUSTOMER_PROFILE_SUCCESS =
  `${domain}/GET_CUSTOMER_PROFILE_SUCCESS` as const;
export const GET_CUSTOMER_PROFILE_ERROR =
  `${domain}/GET_CUSTOMER_PROFILE_ERROR` as const;

export interface GetCustomerProfileRequest {
  type: typeof GET_CUSTOMER_PROFILE_REQUEST;
}

export interface GetCustomerProfileSuccess {
  type: typeof GET_CUSTOMER_PROFILE_SUCCESS;
  payload: CustomerProfile;
}

export interface GetCustomerProfileError {
  type: typeof GET_CUSTOMER_PROFILE_ERROR;
}

export const getCustomerProfileRequest = (): GetCustomerProfileRequest => ({
  type: GET_CUSTOMER_PROFILE_REQUEST,
});

export const getCustomerProfileSuccess = (
  customerProfile: CustomerProfile,
): GetCustomerProfileSuccess => ({
  type: GET_CUSTOMER_PROFILE_SUCCESS,
  payload: customerProfile,
});

export const getCustomerProfileError = (): GetCustomerProfileError => ({
  type: GET_CUSTOMER_PROFILE_ERROR,
});

export type CustomerProfileActions =
  | GetCustomerProfileRequest
  | GetCustomerProfileSuccess
  | GetCustomerProfileError;
