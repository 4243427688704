import { Provider } from './interfaces';

export const b2cKeycloakProviderId: Provider = 'keycloak-b2c';
export const b2bKeycloakProviderId: Provider = 'keycloak-b2b';
export const unifiedKeycloakProviderId: Provider = 'keycloak';

/**
 * Used by mobile webview and BO impersonates
 */
export const TokenCredentialProviderId: Provider = 'tokenCredentials';
export const AuthProviderBase = 'openid email profile';
export const AuthProviderLoggedUserScope = `${AuthProviderBase} usermano`;
export const AuthProviderGuestScope = `${AuthProviderBase} guest`;

export const REFRESH_ACCESS_TOKEN_ERROR = 'RefreshAccessTokenError';
export const MISSING_TOKEN = 'MissingToken';

export const FIVE_MINUTES_IN_SECONDS = 5 * 60;
export const FIFTEEN_MINUTES_IN_SECONDS = 15 * 60;
export const FIFTEEN_DAYS_IN_SECONDS = 15 * 24 * 60 * 60;

export const ONE_YEAR_IN_MILLISECONDS = 365 * 24 * 60 * 60 * 1000;
export const SESSION_UPDATE_TYPES = {
  ROLE: 'role',
  REFRESH_TOKEN: 'refresh',
} as const;
