import logger from '@/domains/core/observability/logger';
import { getAppSetting } from '@/domains/core/settings/appSettings';

/**
 * Cleans old cookies on client side.
 *
 * COOKIES_TO_CLEAN environment variable must be set with cookie names separated
 * by commas (,).
 */
export function cleanOldCookies(): void {
  try {
    getAppSetting('COOKIES_TO_CLEAN')
      .split(',')
      .filter((cookie) => cookie && document.cookie.includes(`${cookie}=`))
      .forEach((cookie) => {
        document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });
  } catch (error) {
    logger.error(error);
  }
}
