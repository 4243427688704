import type { AppSettingName, AppSettings } from './interfaces';

// On server-side, the settings are in the process.env objects, so we consume
// them.
// On client-side, the settings are injected from the SSR data and read with the
// getAppSetting/getAppSettings methods.
export function getAppSetting<Name extends AppSettingName>(
  name: Name,
): AppSettings[Name] | string {
  if (typeof window === 'undefined') {
    // As the env name can be suffixed with _SERVER and _CLIENT, we provide some
    // backup solutions.
    return (
      process.env[`${name}_SERVER`] ||
      process.env[name] ||
      // We want to load appSettingDefinitions only on server-side
      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      require('./appSettingDefinitions').appSettingDefinitions[name]
        .defaultValue
    );
  }
  const appSettings = window.__NEXT_DATA__?.props?.pageProps?.appSettings;

  if (appSettings === undefined) {
    throw new Error('App settings not found.');
  }
  return appSettings[name];
}
