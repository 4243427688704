import { createSelector } from 'reselect';

import { FlagValuesMap, FlagVariation } from '@/domains/core/flags/flags.state';

import type { SpartacuxCoreState } from '../core.state';

const selectFlagsState = (state: SpartacuxCoreState) => state.core.flags;

const DEFAULT_FLAG_VALUES_MAP: FlagValuesMap = {};

export const selectFlagValuesMap = createSelector(
  [selectFlagsState],
  (flagsState) => flagsState?.flagValuesMap ?? DEFAULT_FLAG_VALUES_MAP,
);

const DEFAULT_FLAG_VARIATIONS: FlagVariation[] = [];

export const selectFlagVariations = createSelector(
  [selectFlagsState],
  (flagsState) => flagsState?.campaignVariations ?? DEFAULT_FLAG_VARIATIONS,
);

export const selectClientSideFlagsRetrieved = createSelector(
  [selectFlagsState],
  (flagsState) => flagsState?.clientSideFlagsRetrieved,
);
