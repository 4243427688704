import type { InsightsClient } from 'search-insights';
import type { InsightsSearchClickEvent } from 'search-insights/dist/click';
import type { InsightsSearchConversionEvent } from 'search-insights/dist/conversion';

import { waitForAndGetConsents } from '@/domains/legal/GDPR/utils/waitForAndGetConsents';

import { getClientAppKey } from '../settings/settings.client';
import { getAppId } from '../settings/settings.common';

interface CvrQueryData {
  queryID: string;
  index: string;
}

const ALGOLIA_QUERY_DATA_LOCALSTORAGE_KEY = 'mm_algolia_query_data';

export class AlgoliaSearchInsights {
  private static SearchInsights?: InsightsClient;

  private static getAlgoliaEventType(
    type: 'ctr' | 'cvr',
  ): 'clickedObjectIDsAfterSearch' | 'convertedObjectIDsAfterSearch' {
    return type === 'cvr'
      ? 'convertedObjectIDsAfterSearch'
      : 'clickedObjectIDsAfterSearch';
  }

  private static async getSearchInsightsClient(): Promise<InsightsClient> {
    if (this.SearchInsights === undefined) {
      this.SearchInsights = (await import('search-insights')).default;
      this.SearchInsights('init', {
        appId: getAppId(),
        apiKey: getClientAppKey(),
      });
    }
    return this.SearchInsights;
  }

  static async send(
    type: 'ctr' | 'cvr',
    event: InsightsSearchClickEvent | InsightsSearchConversionEvent,
  ): Promise<void> {
    const consents = await waitForAndGetConsents();
    if (consents.includes('userPreferences')) {
      const SearchInsights = await this.getSearchInsightsClient();
      SearchInsights(AlgoliaSearchInsights.getAlgoliaEventType(type), event);
    }
  }

  static checkRequiredFields(fields: any[]) {
    return !fields.some((field: any) => !field);
  }

  static calculateAbsolutePosition(
    position: number,
    currentPage: number,
    productsPerPage: number,
  ): number {
    // Algolia tracking requires absolute position, 1-based
    return (currentPage - 1) * productsPerPage + position + 1;
  }

  // @see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
  static async calculateUserIdHash(
    str: string,
    algo = 'SHA-256',
  ): Promise<string> {
    const msgUint8 = new TextEncoder().encode(str);
    const hashBuffer = await window.crypto.subtle.digest(algo, msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  }

  static setCvrQueryData(queryData: CvrQueryData): void {
    window.localStorage.setItem(
      ALGOLIA_QUERY_DATA_LOCALSTORAGE_KEY,
      JSON.stringify(queryData),
    );
  }

  static getCvrQueryData(): CvrQueryData | null {
    const data = window.localStorage.getItem(
      ALGOLIA_QUERY_DATA_LOCALSTORAGE_KEY,
    );
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  static clearCvrQueryData(): void {
    window.localStorage.removeItem(ALGOLIA_QUERY_DATA_LOCALSTORAGE_KEY);
  }
}
