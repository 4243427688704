import type { Request, Response } from '@algolia/requester-common';
import type { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

import {
  httpClient,
  type HttpRequestConfig,
} from '@/domains/core/httpClient/httpClient';
import { IS_CLIENT_SIDE } from '@/domains/core/settings/constants';

export const DEFAULT_ALGOLIA_AXIOS_TIMEOUT = 300;

export const send = (
  request: Request,
  requestConfig?: HttpRequestConfig,
): Readonly<Promise<Response>> =>
  new Promise((resolve) => {
    httpClient
      .request({
        ...requestConfig,
        method: request.method,
        url: request.url,
        headers: request.headers,
        data: request.data,
        timeout: requestConfig?.timeout || DEFAULT_ALGOLIA_AXIOS_TIMEOUT,
        transformRequest: ((data: any, headers: AxiosRequestHeaders): any => {
          if (IS_CLIENT_SIDE) {
            const headersToUpdate = headers;
            if ('Content-Type' in headersToUpdate) {
              delete headersToUpdate['Content-Type'];
            }
            if ('content-type' in headersToUpdate) {
              delete headersToUpdate['content-type'];
            }
          }
          return data;
        }) as AxiosRequestConfig['transformRequest'],
        transformResponse: (res) => res,
      })
      .then((response) => {
        resolve({
          status: response.status || 0,
          content: response.data,
          isTimedOut: false,
        });
      })
      .catch((error) => {
        resolve({
          status: 0,
          content: error.message,
          isTimedOut: false,
        });
      });
  });
