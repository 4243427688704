import { compile, match } from 'next/dist/compiled/path-to-regexp/index';
import qs from 'qs';

import { routes } from '@/domains/core/routing/routes/routes.default';

import type { RouteParams } from './interfaces/routing';

const isPageCache: Map<string, any> = new Map();

/**
 * @param pattern string representing the url pattern
 * @param url
 * @param useCache bypass LRU cache
 * @returns bool
 * @example
 *  isPage('/search/:query', '/search/drill?foo=bar') // => true
 */
export const isPage = (
  pattern: string,
  url: string,
  useCache = true,
): boolean => {
  let matcher: any;
  if (useCache) {
    matcher = isPageCache.get(pattern);
    if (matcher === undefined) {
      matcher = match(pattern);
      isPageCache.set(pattern, matcher);
    }
  } else {
    matcher = match(pattern);
  }
  return !!matcher(url.split('?')[0]);
};

const legacyRoutingPatterns = [routes.topProducts];

export const needsLegacyRouting = (href: string) => {
  return legacyRoutingPatterns.some((pattern) => isPage(pattern, href));
};

/**
 * Parse and decompose your URL
 *
 * @param url Can be full URL of just pathname like `/drill-1146`
 * @param pattern Your url pattern like: `/:category-:id(\\d+)`
 */
export const parseURL = (url: string, pattern: string): RouteParams => {
  const [pathname, search] = url.split('#')[0].split('?');
  const matcher = match(pattern);
  const matchedData = matcher(pathname);

  return {
    pathname,
    search,
    pathParams: (matchedData
      ? matchedData?.params
      : {}) as RouteParams['pathParams'],
    queryParams: qs.parse(search),
  };
};

export const createURL = (
  pattern: string,
  pathParams: { [key: string]: string | number },
  queryParams?: { [key: string]: string | number },
): string => {
  const toPath = compile(pattern, { validate: false });
  let path = toPath(pathParams);

  if (queryParams) {
    path += qs.stringify(queryParams, { addQueryPrefix: true });
  }
  return path;
};

export const omitParam = (params: string, param: string): string => {
  const searchParams = new URLSearchParams(params);
  searchParams.delete(param);
  return Array.from(searchParams.keys()).length
    ? `?${searchParams.toString()}`
    : '';
};
