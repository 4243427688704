/* eslint-disable camelcase */
import { Session } from 'next-auth';
import { combineReducers } from 'redux';

import {
  UPDATE_OPTIN_STATUS_SUCCESS,
  UpdateOptinStatusSuccess,
} from '@/domains/care/myAccount/myAccount.actions';
import createReducer from '@/domains/core/applicationState/store/createReducer';

import {
  CHECK_AUTH_TOKEN,
  CHECK_AUTH_TOKEN_ERROR,
  CHECK_AUTH_TOKEN_SUCCESS,
  GET_CUSTOMER_OPTIN_STATUS_SUCCESS,
  GET_CUSTOMER_SEGMENT_ERROR,
  GET_CUSTOMER_SEGMENT_SUCCESS,
  PATCH_CUSTOMER_SEGMENT_SUCCESS,
  type ActionsTypes,
  type GetCustomerOptinStatusSuccess,
  type GetCustomerSegmentSuccess,
  type PatchCustomerSegmentSuccess,
} from './actions';
import {
  Auth,
  CustomerOptinStatus,
  CustomerSegment,
  initialState,
  type HasRetrievedAuthStatus,
  type HasRetrievedCustomerSegments,
} from './model';

const createAuthReducer = <K extends keyof Auth>(
  key: K,
  successValue: (payload: Session) => Auth[K] | undefined,
  errorValue: Auth[K],
) =>
  createReducer<Auth[K], ActionsTypes>(initialState[key], {
    [CHECK_AUTH_TOKEN_SUCCESS]: (state, { payload }) =>
      successValue(payload) ?? state,
    [CHECK_AUTH_TOKEN_ERROR]: () => errorValue,
  });

const isAuthenticated = createAuthReducer('isAuthenticated', () => true, false);
const isAuthenticating = createAuthReducer(
  'isAuthenticating',
  () => false,
  false,
);
const isB2B = createAuthReducer(
  'isB2B',
  (payload) => payload.user.role === 'b2b',
  false,
);
const isB2C = createAuthReducer(
  'isB2C',
  (payload) => payload.user.role === 'b2c',
  false,
);
const isGuest = createAuthReducer(
  'isGuest',
  (payload) => payload.user.role === 'guest',
  false,
);
const userId = createAuthReducer('userId', (payload) => payload.user.id, null);
const email = createAuthReducer(
  'email',
  (payload) => payload.user?.email,
  null,
);

export const given_name = createAuthReducer(
  'given_name',
  (payload) => payload.user.given_name,
  null,
);

const createSegmentReducer = <K extends keyof CustomerSegment>(
  key: K,
  successValue: (
    payload: GetCustomerSegmentSuccess | PatchCustomerSegmentSuccess,
  ) => CustomerSegment[K],
) =>
  createReducer<CustomerSegment[K], ActionsTypes>(initialState[key], {
    [GET_CUSTOMER_SEGMENT_SUCCESS]: (
      state,
      action: GetCustomerSegmentSuccess,
    ) => successValue(action) ?? state,
    [PATCH_CUSTOMER_SEGMENT_SUCCESS]: (
      state,
      action: PatchCustomerSegmentSuccess,
    ) => successValue(action) ?? state,
    [CHECK_AUTH_TOKEN_ERROR]: () => null,
    [GET_CUSTOMER_SEGMENT_ERROR]: () => null,
  });

export const b2b_intention = createSegmentReducer(
  'b2b_intention',
  (action) => action.payload.b2b_intention,
);
export const b2b_welcome = createSegmentReducer(
  'b2b_welcome',
  (action) => action.payload.b2b_welcome,
);
export const b2b_vat_modal = createSegmentReducer(
  'b2b_vat_modal',
  (action) => action.payload.b2b_vat_modal,
);
export const b2b_suspected_pro = createSegmentReducer(
  'b2b_suspected_pro',
  (action) => action.payload.b2b_suspected_pro,
);
export const suspected_pro_type = createSegmentReducer(
  'suspected_pro_type',
  (action) => action.payload.suspected_pro_type,
);
export const profile = createSegmentReducer(
  'profile',
  (action) => action.payload.profile,
);
export const cluster = createSegmentReducer(
  'cluster',
  (action) => action.payload.cluster,
);
export const buy_number_cluster = createSegmentReducer(
  'buy_number_cluster',
  (action) => action.payload.buy_number_cluster,
);
export const audience_group = createSegmentReducer(
  'audience_group',
  (action) => action.payload.audience_group,
);
export const loyalty_level = createSegmentReducer(
  'loyalty_level',
  (action) => action.payload.loyalty_level,
);
export const loyalty_hvc_level = createSegmentReducer(
  'loyalty_hvc_level',
  (action) => action.payload.loyalty_hvc_level,
);
export const app_cluster = createSegmentReducer(
  'app_cluster',
  (action) => action.payload.app_cluster,
);
export const last_chance_churn = createSegmentReducer(
  'last_chance_churn',
  (action) => action.payload.last_chance_churn,
);
export const anti_churn = createSegmentReducer(
  'anti_churn',
  (action) => action.payload.anti_churn,
);
export const b2b_subscription = createSegmentReducer(
  'b2b_subscription',
  (action) => action.payload.b2b_subscription,
);
export const customer_value = createSegmentReducer(
  'customer_value',
  (action) => action.payload.customer_value,
);

const hasRetrievedAuthStatus = createReducer<
  HasRetrievedAuthStatus,
  ActionsTypes
>(initialState.hasRetrievedAuthStatus, {
  [CHECK_AUTH_TOKEN_SUCCESS]: () => true,
  [CHECK_AUTH_TOKEN_ERROR]: () => true,
  [CHECK_AUTH_TOKEN]: () => false,
});

export const hasRetrievedCustomerSegments = createReducer<
  HasRetrievedCustomerSegments,
  ActionsTypes
>(initialState.hasRetrievedCustomerSegments, {
  [CHECK_AUTH_TOKEN_ERROR]: () => true,
  [GET_CUSTOMER_SEGMENT_SUCCESS]: () => true,
  [GET_CUSTOMER_SEGMENT_ERROR]: () => false,
});

const createOptinReducer = <K extends keyof CustomerOptinStatus>(key: K) =>
  createReducer(initialState.optin_status[key], {
    [GET_CUSTOMER_OPTIN_STATUS_SUCCESS]: (
      state,
      action: GetCustomerOptinStatusSuccess,
    ) => action.payload[key],
    [CHECK_AUTH_TOKEN_ERROR]: () => null,
    [GET_CUSTOMER_SEGMENT_ERROR]: () => null,
    [UPDATE_OPTIN_STATUS_SUCCESS]: (state, action: UpdateOptinStatusSuccess) =>
      action.payload[key] ?? state,
  });

export const optin_email = createOptinReducer('email');
export const optin_sms = createOptinReducer('sms');

export const optin_status = combineReducers({
  email: optin_email,
  sms: optin_sms,
});

export default combineReducers({
  email,
  hasRetrievedAuthStatus,
  hasRetrievedCustomerSegments,
  isAuthenticated,
  isB2B,
  isB2C,
  isGuest,
  userId,
  isAuthenticating,
  b2b_intention,
  b2b_welcome,
  b2b_vat_modal,
  b2b_suspected_pro,
  suspected_pro_type,
  profile,
  cluster,
  buy_number_cluster,
  audience_group,
  loyalty_level,
  loyalty_hvc_level,
  app_cluster,
  last_chance_churn,
  anti_churn,
  given_name,
  b2b_subscription,
  optin_status,
  customer_value,
});
