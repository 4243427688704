import { select, take } from 'redux-saga/effects';

import type {
  SelfCareInvoice,
  SelfCareInvoiceState,
} from '@/domains/care/onlineSupport/SelfCareInvoicePage/interfaces/SelfCareInvoice';
import {
  GET_SELF_CARE_INVOICE_SUCCESS,
  type GetSelfCareInvoiceSuccess,
} from '@/domains/care/onlineSupport/SelfCareInvoicePage/selfCareInvoice.actions';
import { pageSelector } from '@/domains/core/core.selectors';
import type { PageState } from '@/domains/core/page/page.state';

export const selfCareInvoiceSelector = (state: SelfCareInvoiceState) =>
  state.invoice;

export default function* rootInvoiceSelfCareStatusSaga(): Generator<
  any,
  any,
  any
> {
  const { pageType } = (yield select(pageSelector)) as PageState;
  if (pageType !== 'self_care_invoice') return null;

  let selfCareInvoice = (yield select(selfCareInvoiceSelector)) as
    | SelfCareInvoice
    | undefined;

  if (!selfCareInvoice) {
    const selfCareInvoicePayload: GetSelfCareInvoiceSuccess = yield take(
      GET_SELF_CARE_INVOICE_SUCCESS,
    );
    selfCareInvoice = selfCareInvoicePayload.payload?.selfCareInvoice;
  }

  const invoiceStatus = selfCareInvoice?.status;

  return {
    ...(invoiceStatus && {
      invoice_status:
        (invoiceStatus === 'claimable' && 'not asked') ||
        (invoiceStatus === 'claimed' && 'asked') ||
        invoiceStatus,
    }),
  };
}
