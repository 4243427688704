import type {
  ApiProductHistoryItem,
  ProductHistory,
} from '../../ProductHistory.model';

export const adaptResponseFromProductHistoryService = (
  response: ApiProductHistoryItem[],
): ProductHistory =>
  response.map((product) => ({
    productId: product.product_id,
    viewedAt: product.viewed_at,
  }));

export const adaptBodyToProductHistoryService = (
  body: ProductHistory,
): ApiProductHistoryItem[] =>
  body.map((product) => ({
    product_id: product.productId,
    viewed_at: product.viewedAt,
  }));
