import { useSelector } from 'react-redux';

import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';

import type { Auth } from '../model';

export const useAuth = (): Auth => {
  const auth = useSelector(authSelector);

  return auth;
};
