import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';

import { authSelector } from '@/customerManagement/customerManagement.selectors';
import { convertCustomerSegmentFromGQL } from '@/customerManagement/utils/convertCustomerSegmentsFromGQL';
import { FlagValuesMap } from '@/domains/core/flags/flags.state';
import { waitForAndGetFlagValuesSaga } from '@/domains/core/flags/sagas/waitForAndGetFlagValues.saga';

import {
  CHECK_AUTH_TOKEN_SUCCESS,
  GET_CUSTOMER_SEGMENT_REQUEST,
  getCustomerOptinStatusSuccess,
  getCustomerSegmentError,
  getCustomerSegmentSuccess,
} from './actions';
import { fetchCustomerSegmentsFromGraphQL } from './services/customerSegment.service';
import { httpInterceptor } from './services/httpInterceptors/httpInterceptor';

export function* requestCustomerSegmentSaga() {
  const { isAuthenticated } = yield* select(authSelector);
  if (isAuthenticated) {
    const customerSegments = yield* call(fetchCustomerSegmentsFromGraphQL);

    if (customerSegments) {
      const convertedCustomerSegments =
        convertCustomerSegmentFromGQL(customerSegments);
      yield put(getCustomerSegmentSuccess(convertedCustomerSegments));
    } else {
      yield put(getCustomerSegmentError());
    }
  }
}

export function* requestCustomerOptinStatusSaga() {
  const customerOptinStatus = yield* call(() =>
    import('./services/customerOptinStatus.service').then((mod) =>
      mod.fetchCustomerOptinStatus(),
    ),
  );
  yield put(getCustomerOptinStatusSuccess(customerOptinStatus));
}

function* getFlagValuesPromise(): Generator {
  let resolve!: (value: FlagValuesMap) => void;
  const promise = new Promise<FlagValuesMap>((_resolve) => {
    resolve = _resolve;
  });
  yield fork(getFlagValuesUsingResolve, resolve);

  return promise;
}

function* getFlagValuesUsingResolve(
  resolve: (value: FlagValuesMap) => void,
): Generator<any, any, any> {
  const flags: FlagValuesMap = yield call(waitForAndGetFlagValuesSaga);
  resolve(flags);
}

export function* initHttpInterceptor(): Generator<any, any, any> {
  const promise = yield call(getFlagValuesPromise);
  yield call(httpInterceptor, promise);
}

export default function* rootAuthSaga() {
  yield all([
    fork(initHttpInterceptor),
    takeLatest(CHECK_AUTH_TOKEN_SUCCESS, requestCustomerSegmentSaga),
    takeLatest(GET_CUSTOMER_SEGMENT_REQUEST, requestCustomerSegmentSaga),
    takeLatest(CHECK_AUTH_TOKEN_SUCCESS, requestCustomerOptinStatusSaga),
  ]);
}
