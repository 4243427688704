import { merchandisingLabels } from '@/core/tamagoshi/MerchandisingTag/interfaces';
import logger from '@/domains/core/observability/logger';
import { PLATFORM } from '@/domains/core/settings/constants';
import { isB2B } from '@/domains/core/settings/utils';
import type { RawPricesByType } from '@/domains/productDiscovery/algolia/interfaces/hit';
import type { DetailedPrices } from '@/domains/productDiscovery/algolia/interfaces/product';
import {
  getTaxKey,
  invertedTaxMap,
} from '@/domains/productDiscovery/algolia/productProcessor';
import { filterResultsWithoutVatRate } from '@/domains/productDiscovery/Recommendation/utils/filterResultsWithoutVatRate/filterResultsWithoutVatRate.default';
import { getSecondHandInfo } from '@/domains/sustainability/secondHand/utils/secondHand';
import { processRetailPriceTimeLeftLabel } from '@/productDiscovery/CommercialAnimation/utils/commercialOperations.processor';

import type {
  CustomAlgoliaSearchResults,
  Hit,
  RawPrice,
} from '../../interfaces/algolia';
import type { Market, Price, Product } from '../../interfaces/product';
import type {
  RecommendationPlaylist,
  Recommendations,
} from '../../interfaces/productRecommendation';
import { generateBundlePrices } from './utils/bundlePrice';

export const sanitize = (obj: Product): Product => {
  const sanitized = { ...obj };
  Object.keys(sanitized).forEach(
    (key) =>
      sanitized[key as keyof Product] === undefined &&
      delete sanitized[key as keyof Product],
  );
  return { ...sanitized };
};

const processCommercialOperations = (
  commercialOperationIds?: string[] | number[],
): number[] | undefined =>
  commercialOperationIds?.map((commercialOperationId: string | number) =>
    typeof commercialOperationId === 'string'
      ? parseInt(commercialOperationId, 10)
      : commercialOperationId,
  );

export const processPrice = (price: RawPrice): Price => ({
  integer: price.integer || '0',
  ranges: price.ranges || [],
  decimal: price.decimal || '0',
  asString: price.as_string,
  asFloat: price.as_float,
});

export const processHitForProduct = (hit: Hit): Product => {
  const { secondHandFullTitle: updatedTitle, isSecondHand } = getSecondHandInfo(
    hit.default_title,
  );

  return sanitize({
    articleId: hit.article_id,
    banner: hit.banner,
    bannerAlternate: hit['banner.alternate'],
    bestOffer: hit.best_offer,
    brandId: hit.brand_id,
    brandImageFullpath: hit.brand_image_fullpath,
    brandName: hit.brand_name,
    bundlePrices: generateBundlePrices(hit),
    hasBulk: hit.bulk,
    categoriesL0: hit['categories.l0'],
    categoriesL1: hit['categories.l1'],
    categoriesL2: hit['categories.l2'],
    categoriesL3: hit['categories.l3'],
    categoriesLast: hit['categories.last'],
    categoriesL0Id: hit['categories.l0.id'],
    categoriesL1Id: hit['categories.l1.id'],
    categoriesL2Id: hit['categories.l2.id'],
    categoriesL3Id: hit['categories.l3.id'],
    categoriesLastId: hit['categories.last_id'],
    commercialOperationIds: processCommercialOperations(
      hit.commercial_operation_ids,
    ),
    coupon: hit.coupon,
    defaultTitle: updatedTitle,
    deliveryOffers:
      hit.delivery_offers && !Array.isArray(hit.delivery_offers)
        ? {
            minFee: processPrice(hit.delivery_offers.min_fee),
            minFeeTime: hit.delivery_offers.min_fee_time,
            minTime: hit.delivery_offers.min_time,
            minTimeFee: processPrice(hit.delivery_offers.min_time_fee),
          }
        : undefined,
    has1dayDelivery: hit.has_1day_delivery,
    has3xPayment: hit.has_3x_payment,
    hasFreeDelivery: hit.has_free_delivery,
    hasProConditions: hit.has_pro_conditions,
    hasRelayDelivery: hit.has_relay_delivery,
    hasSample: hit.has_sample,
    imagePath: hit.image_path,
    imageFullpath: hit.image_fullpath,
    indexable: hit.indexable,
    isB2b: hit.is_b2b,
    isCompetitive: hit.is_competitive,
    isEcoResponsible: !!hit.is_eco_responsible,
    isLocal: PLATFORM === 'fr' && !!hit.is_local,
    isMmf: hit.is_mmf,
    isSellerB2b: !!+hit.is_seller_b2b,
    isSecondHand,
    market: hit.market as Market,
    modelId: hit.model_id,
    modelMarkets: hit.model_markets as Market[],
    modelsCount: hit.models_count,
    productId: hit.objectID,
    prices: generatePrices(hit),
    price: hit.price,
    rating: hit.rating,
    ratingCount: hit.rating_count,
    sellerCountryId: hit.seller_country_id,
    sellerId: hit.seller_id,
    sellerName: hit.seller_name,
    title: hit.title,
    thumbnails: hit.thumbnails,
    url: hit.url,
    ...(hit.commercial_animation && {
      commercialAnimation: {
        tags: hit.commercial_animation.tags || [],
        promotionEndDate: hit.commercial_animation.promotion_end_at || null,
        retailPriceTimeLeftLabel: hit.commercial_animation.promotion_end_at
          ? processRetailPriceTimeLeftLabel(
              hit.commercial_animation.promotion_end_at,
            )
          : null,
      },
    }),
  });
};

function generatePrices(hit: Hit): DetailedPrices {
  const taxKey = getTaxKey();
  const invertedTaxKey = invertedTaxMap[taxKey];

  const main = hit.prices.per_item.actual_price[taxKey].as_float;

  const secondary = isB2B()
    ? hit.prices.per_item.actual_price[invertedTaxKey].as_float
    : getPriceByKey(hit, hit.prices.secondary_price)?.actual_price[taxKey]
        .as_float;

  const retail = getPriceByKey(hit, hit.prices.main_price)?.retail_price?.[
    taxKey
  ].as_float;

  return {
    main,
    secondary: secondary || null,
    retail: retail || null,
  };
}

function getPriceByKey(
  hit: Hit,
  key: 'per_item' | null,
): RawPricesByType | undefined {
  switch (key) {
    case 'per_item':
      return hit.prices.per_item;

    default:
      return undefined;
  }
}

export const processProducts = (hits: Hit[]): Product[] => {
  const products = hits
    .map((hit) => {
      try {
        return processHitForProduct(hit);
      } catch (error) {
        logger.error(error as Error);
        return undefined;
      }
    })
    .filter(Boolean);
  return products as Product[];
};

const processRecommendationProducts = (
  result: CustomAlgoliaSearchResults,
  index: number,
): RecommendationPlaylist => ({
  id: index,
  products: processProducts(result.hits),
});

const processProductsAddPromoLabel = (products: Product[]): Product[] =>
  products.map((product) => ({
    ...product,
    hasPromoLabel: !!product.commercialAnimation?.tags.includes(
      merchandisingLabels.PROMO,
    ),
  }));

const processRecommendationProductsAddPromoLabel = (
  recommendation: RecommendationPlaylist,
): RecommendationPlaylist => ({
  ...recommendation,
  products: processProductsAddPromoLabel(recommendation.products),
});

export const processSearchResult = (
  results: CustomAlgoliaSearchResults[],
): Recommendations => {
  const resultsFiltered = filterResultsWithoutVatRate(results);

  return resultsFiltered
    .map((recommendation, index) =>
      processRecommendationProducts(recommendation, index),
    )
    .map((recommendation) =>
      processRecommendationProductsAddPromoLabel(recommendation),
    );
};
