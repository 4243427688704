import {
  EXPIRATION_TIME_IN_MONTHS,
  MAX_HISTORY_LENGTH,
  PRODUCT_HISTORY_PREVIOUS_AUTH_STATUS,
  PRODUCT_HISTORY_STORAGE_KEY,
} from '../constants';
import type {
  ProductHistory,
  ProductHistoryItem,
} from '../ProductHistory.model';

export function isLocalStorageAvailable() {
  let storage;
  try {
    const testValue = '__storage_test__';
    storage = window.localStorage;
    storage.setItem(testValue, testValue);
    storage.removeItem(testValue);
    return true;
  } catch (e) {
    return false;
  }
}

export const createProductHistoryItem = (
  productId: number,
): ProductHistoryItem => ({
  productId,
  viewedAt: new Date().toISOString().toString(),
});

export const addItemToProductHistory = (
  id: number,
  productHistory: ProductHistory,
): ProductHistory => {
  const product = createProductHistoryItem(id);
  const products = [...productHistory];

  if (products.length >= MAX_HISTORY_LENGTH) {
    products.pop();
  }

  return [product, ...products.filter((prod) => prod.productId !== id)];
};

export const getLocalStorageProductHistory = (): ProductHistory | [] => {
  if (isLocalStorageAvailable()) {
    const storage = localStorage.getItem(PRODUCT_HISTORY_STORAGE_KEY);

    if (storage) {
      try {
        const products: ProductHistory = JSON.parse(storage);

        // Filter expired products
        const expiredTime: Date = new Date();
        expiredTime.setMonth(
          expiredTime.getMonth() - EXPIRATION_TIME_IN_MONTHS,
        );

        return products
          .map((product) => {
            const productDate: Date = new Date(product.viewedAt);

            if (+productDate > +expiredTime) {
              return product;
            }
            return undefined;
          })
          .filter(Boolean) as ProductHistory;
      } catch {
        return [];
      }
    }
  }
  return [];
};

export const buildLocalStorageProductHistory = (
  productId: number,
): ProductHistory => {
  const product: ProductHistoryItem = createProductHistoryItem(productId);
  if (isLocalStorageAvailable()) {
    const productHistory = getLocalStorageProductHistory();

    if (!productHistory) {
      const products = JSON.stringify([product]);
      localStorage.setItem(PRODUCT_HISTORY_STORAGE_KEY, products);

      return [product];
    }
    const updatedProductHistory = addItemToProductHistory(
      productId,
      productHistory,
    );
    return updatedProductHistory;
  }
  return [product];
};

export const addProductHistoryToLocalStorage = (
  productHistory: ProductHistory,
) => {
  localStorage.setItem(
    PRODUCT_HISTORY_STORAGE_KEY,
    JSON.stringify(productHistory),
  );
};

export const setPreviousAuthStatus = (value: boolean) => {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(
      PRODUCT_HISTORY_PREVIOUS_AUTH_STATUS,
      value.toString(),
    );
  }
};

export const getPreviousAuthStatus = () => {
  if (isLocalStorageAvailable()) {
    const previousAuthStatus = localStorage.getItem(
      PRODUCT_HISTORY_PREVIOUS_AUTH_STATUS,
    );

    if (previousAuthStatus === null) {
      setPreviousAuthStatus(false);
      return false;
    }
    if (previousAuthStatus === 'false') {
      return false;
    }
    if (previousAuthStatus === 'true') {
      return true;
    }
    return false;
  }
};
