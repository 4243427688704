import {
  SET_PAGE_DATA,
  SET_PAGE_REFERER,
  SET_PAGE_STATUS_CODE,
  type PageAction,
} from './page.actions';
import { pageInitialState, type PageState } from './page.state';

export default function pageReducer(
  state: PageState = pageInitialState,
  action: PageAction,
): PageState {
  switch (action.type) {
    case SET_PAGE_DATA: {
      const {
        pageName,
        pageType,
        pageViewCustomData = {},
        pageCustomData = {},
      } = action;
      return {
        ...state,
        pageName,
        pageType,
        pageViewCustomData,
        pageCustomData,
      };
    }

    case SET_PAGE_REFERER: {
      const { referer } = action;
      return { ...state, referer };
    }

    case SET_PAGE_STATUS_CODE: {
      const { statusCode } = action;
      return { ...state, statusCode };
    }

    default:
      return state;
  }
}
