import { IS_PROD } from '@/core/platform/constants';

import type { Logger } from './Logger';

export default {
  error(error: Error) {
    // We won't log errors in production for now, we'll analyze how many events of a certain type we have
    // and then decide if we want to log them or not
    if (!IS_PROD) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
} satisfies Logger;
