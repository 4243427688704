import type { PageType } from '../../interfaces/productRecommendation';

type StringObject = Record<string, string>;
type ABTEST_MS_RECO_POOL = 'aax' | 'axa' | 'xaa';

export const RECOMMENDATION_ENGINES_NAMES: StringObject = Object.freeze({
  ARTICLE_PAGE: 'reco-article-page',
  CART_PAGE: 'reco-cart-page',
  ADD_TO_CART: 'reco-add-to-cart',
  HOMEPAGE_B2C: 'reco-homepage-b2c',
});

export const RECOMMENDATION_ORIGINAL_NAMES: StringObject = Object.freeze({
  ARTICLE_PAGE: 'reco-article-page',
  CART_PAGE: 'reco-cart-page',
  ADD_TO_CART: 'reco-add-to-cart',
  HOMEPAGE_B2C: 'reco-homepage-b2c',
});

export const PRODUCT_RECOMMENDATION = 'productRecommendation';
export const CATEGORIES_RECOMMENDATION = 'categoriesRecommendation';

export const SIMILARITY_V2_RECOMMENDATION = 'SimilarityML_v2';

// Documentation:
// https://manomano.atlassian.net/wiki/spaces/RECO/pages/1963032663/A+B+tests
export const ABTEST_MS_RECO: Array<ABTEST_MS_RECO_POOL> = ['xaa', 'axa', 'aax'];

export const PAGE_TYPE_MS_RECO: Record<string, PageType> = Object.freeze({
  PRODUCT_PAGE: 'article_page',
  CART_PAGE_DYNAMIC: 'cart_page_dynamic',
  ADD_TO_CART_POPIN: 'add_to_cart_popin',
  HOMEPAGE_B2C: 'web_homepage_b2c',
  CATEGORY_LISTING: 'category_listing',
});

export const CONTENT_TYPE_MS_RECO: StringObject = Object.freeze({
  PRODUCT: 'product-list',
  // CATEGORY: 'category-list', // Not used at the moment
  // BUNDLE: 'bundle', // Not used at the moment
});

export const ERROR_MESSAGE: StringObject = Object.freeze({
  EMPTY_CONFIG: 'Recommendations configuration is empty',
});
