import type {
  MultipleQueriesQuery,
  SingleQuery,
} from '../../../../interfaces/algolia';
import type {
  MsRecommendationProcessedResponse,
  ProcessedRecommendationItem,
} from '../../../../interfaces/msRecommendation';
import { CONTENT_TYPE_MS_RECO } from '../../../../modules/ProductRecommendation/constants';
import { ATTRIBUTES_TO_RETRIEVES, RESPONSE_ATTRIBUTES } from '../../constants';

const getCorrectAlgoliaFilters = (item: ProcessedRecommendationItem) => {
  const res = item.productIds || item.categoryIds || item.modelIds || [];
  switch (item.displayType) {
    case CONTENT_TYPE_MS_RECO.PRODUCT: {
      if (item.modelIds) {
        const numericFilters = [res.map((id) => `model_id = ${id}`)];
        return { numericFilters };
      }
      const facetFilters = [res.map((id) => `objectID: ${id}`)];
      return { facetFilters };
    }
    default: {
      return {};
    }
  }
};

// algolia params
export const formatAlgoliaParams = (
  recommendationResponse: ProcessedRecommendationItem,
): SingleQuery => {
  const filters = getCorrectAlgoliaFilters(recommendationResponse);

  return {
    query: '',
    responseFields: RESPONSE_ATTRIBUTES,
    attributesToRetrieve: ATTRIBUTES_TO_RETRIEVES,
    attributesToHighlight: [],
    ...filters,
  };
};

export const formatMultipleAlgoliaParams = (
  recommendationResponse: MsRecommendationProcessedResponse,
  indexName: string,
): MultipleQueriesQuery[] =>
  recommendationResponse.map((reco) => {
    const filters = getCorrectAlgoliaFilters(reco);
    return {
      query: '',
      indexName,
      responseFields: RESPONSE_ATTRIBUTES,
      analyticsTags: ['RecommendationPlaylist', reco.name],
      params: {
        attributesToRetrieve: ATTRIBUTES_TO_RETRIEVES,
        attributesToHighlight: [],
        ...filters,
      },
    };
  });
