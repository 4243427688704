import type { IncomingMessage } from 'http';

import { HttpHeaders, X_OCDN_VALUES } from '@/core/httpClient/httpHeaders';
import logger from '@/domains/core/observability/logger';

import { IS_CLIENT_SIDE } from '../../settings/constants';

/**
 * This list comes from Mobile-Detect Symfony bundle as it was used for this
 * purpose in manomano-sf. Some entries have been added manually by ManoMano.
 *
 * @see https://github.com/serbanghita/Mobile-Detect
 */
const BOT_USER_AGENTS = [
  'AdsBot-Google',
  'AdsBot-Google-Mobile',
  'AhrefsBot',
  'bingbot',
  'Exabot',
  'Excel',
  'Ezooms',
  'facebookexternalhit',
  'Facebot',
  'GSLFbot',
  'Google Keyword Suggestion',
  'Googlebot',
  'Googlebot-Mobile',
  'ia_archiver',
  'ManoCrawler',
  'MJ12bot',
  'Mediapartners-Google',
  'msnbot',
  'PaperLiBot',
  'Pingdom',
  'TurnitinBot',
  'TweetmemeBot',
  'Twikle',
  'Twitterbot',
  'UnwindFetchor',
  'WBSearchBot',
  'WordPress',
  'Wotbox',
  'YahooSeeker/M1A1-R2D2',
  'YandexBot',
  'YandexImages',
  'YandexMobileBot',
];

/**
 * Detects if the requester is a crawler by comparing its user agent to a known
 * list.
 */
export function detectCrawler(req?: IncomingMessage): boolean | undefined {
  try {
    const userAgent = IS_CLIENT_SIDE
      ? window.navigator.userAgent
      : req?.headers['user-agent'];

    return BOT_USER_AGENTS.some((botUserAgent) =>
      userAgent?.includes(botUserAgent),
    );
  } catch (error) {
    logger.error(error);
  }
}

/**
 * Detects if the requester is a crawler by checking the value of header x-ocdn
 */
export function detectCrawlerWithHeader(req?: IncomingMessage): boolean {
  return !req?.headers
    ? false
    : req?.headers[HttpHeaders.X_OCDN] === X_OCDN_VALUES.SEA_BOT;
}
