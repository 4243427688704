/* eslint-disable camelcase */
import { httpClient } from '../../httpClient/httpClient';
import { type StandardResponse } from '../../httpClient/StandardResponse';
import { getAppSetting } from '../../settings/appSettings';
import { getMarket, getPlatform } from '../../settings/utils';
import type {
  FlagValuesMap,
  FlagVariation,
  FlagVariationType,
} from '../flags.state';

interface FlagValuesAndFlagVariations {
  flags: FlagValuesMap;
  campaignVariations: FlagVariation[];
}

interface FlagValuesAndFlagVariationsPayload {
  context: {
    CHANNEL: string;
    MARKET: string;
    PLATFORM: string;
  };
  user_id?: string;
  visitor_id: string;
}

interface FlagValuesAndFlagVariationsResponse {
  flags: FlagValuesMap;
  campaign_variations: {
    campaign_id: string;
    variation_id: string;
    type: FlagVariationType;
  }[];
}

export async function fetchFlagValuesAndFlagVariations(
  visitorId: string,
  userId: string | undefined,
): Promise<FlagValuesAndFlagVariations> {
  const timeout = Number(
    getAppSetting('FETCH_FLAG_VALUES_AND_FLAG_VARIATIONS_TIMEOUT'),
  );

  const url = `${getAppSetting('MS_EXPERIMENTATION_API')}/ab-test/api/v1/user/flags`;

  const payload: FlagValuesAndFlagVariationsPayload = {
    context: {
      CHANNEL: 'web',
      MARKET: getMarket(),
      PLATFORM: getPlatform(),
    },
    visitor_id: visitorId,
  };

  if (userId !== undefined) {
    payload.user_id = userId;
  }

  const response = await httpClient.post<
    StandardResponse<FlagValuesAndFlagVariationsResponse>
  >(url, payload, { timeout });

  const { campaign_variations, flags } = response.data.content;

  return {
    campaignVariations: campaign_variations.map(
      ({ campaign_id: campaignId, variation_id: variationId, type }) => ({
        campaignId,
        variationId,
        type,
      }),
    ),
    flags,
  };
}
