import Script from 'next/script';
import { useEffect, type FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { getAppSetting } from '../settings/appSettings';
import {
  ABTASTY_SCRIPT_CANCELLED,
  ABTASTY_SCRIPT_LOADED,
  ABTASTY_SCRIPT_LOADING,
  ABTASTY_TAG_EXECUTED,
} from './ABTasty.actions';

export const ABTastyScript: FunctionComponent = () => {
  const AB_TASTY_ID = getAppSetting('EXTERNAL_TAG_AB_TASTY_ID');
  const dispatch = useDispatch();

  useEffect(() => {
    const onTagContentExecuted = () => dispatch({ type: ABTASTY_TAG_EXECUTED });

    window.addEventListener('abtasty_tagContentExecuted', onTagContentExecuted);
    return () =>
      window.removeEventListener(
        'abtasty_tagContentExecuted',
        onTagContentExecuted,
      );
  }, [dispatch]);

  if (AB_TASTY_ID) {
    let timeout: any;
    const onScriptLoad = () => {
      clearTimeout(timeout);

      let tries = 20;
      const waitForUnlock = () => {
        tries -= 1;
        if (window.unlockABTasty || tries <= 0) {
          dispatch({ type: ABTASTY_SCRIPT_LOADED });
        } else {
          setTimeout(waitForUnlock, 50);
        }
      };
      waitForUnlock();
    };

    const onScriptCancel = () => {
      if (!window.ABTasty) {
        dispatch({ type: ABTASTY_SCRIPT_CANCELLED });
      }
    };

    if (typeof window !== 'undefined' && !('abtiming' in window)) {
      (window as any).abtiming = Date.now();
      window.lockABTastyTag = true;
      timeout = setTimeout(onScriptCancel, 8000);
      dispatch({ type: ABTASTY_SCRIPT_LOADING });
    }

    return (
      <Script
        async
        id="external-tag-ab-tasty"
        data-testid="external-tag-ab-tasty"
        src={`https://try.abtasty.com/${AB_TASTY_ID}.js`}
        onLoad={onScriptLoad}
      />
    );
  }

  return null;
};
