import { shallowEqual, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { type SpartacuxCoreState } from '../core.state';
import { type JSONValue } from '../data/interfaces/JSONValue';
import { selectFlagValuesMap } from './flags.selectors';
import { type FlagValue } from './flags.state';
import type { FlagValueType } from './interfaces';

export interface UseFlagsOptions<
  FlagTypeToCheck extends FlagValueType = FlagValueType,
> {
  flagTypeToCheck?: FlagTypeToCheck;
  parseJson?: boolean;
}

export function useBooleanFlags(flagIds: string[]): boolean[] {
  return useFlags(flagIds, { flagTypeToCheck: 'booleanOrUndefined' }).map(
    (flagValue) => flagValue || false,
  );
}

export function useFlags(
  flagIds: string[],
  options?: UseFlagsOptions<'booleanOrUndefined'>,
): (boolean | undefined)[];

export function useFlags(
  flagIds: string[],
  options?: UseFlagsOptions<'jsonOrUndefined'>,
): (JSONValue | undefined)[];

export function useFlags(
  flagIds: string[],
  options?: UseFlagsOptions<'numberOrUndefined'>,
): (number | undefined)[];

export function useFlags(
  flagIds: string[],
  options?: UseFlagsOptions<'stringOrUndefined'>,
): (string | undefined)[];

export function useFlags(
  flagIds: string[],
  options?: UseFlagsOptions,
): (boolean | JSONValue | number | string | undefined)[];

export function useFlags(flagIds: string[], options: UseFlagsOptions = {}) {
  return useSelector(
    (state: SpartacuxCoreState) => flagsSelector(state, flagIds, options),
    shallowEqual,
  ) as (boolean | JSONValue | number | string | undefined)[];
}

export const flagsSelector = createSelector(
  [
    (state: SpartacuxCoreState) => selectFlagValuesMap(state),
    (state: SpartacuxCoreState, flagIds: string[]) => flagIds,
    (state: SpartacuxCoreState, flagIds: string[], options: UseFlagsOptions) =>
      options,
  ],
  (flagValuesMap, flagIds, { flagTypeToCheck, parseJson }) => {
    return flagIds.map((id) => {
      const value: FlagValue | undefined = flagValuesMap[id];
      const parsedValue =
        flagTypeToCheck === 'jsonOrUndefined' || parseJson
          ? tryParsingJson(value)
          : undefined;

      if (
        flagTypeToCheck !== undefined &&
        value !== undefined &&
        !isFlagValueTypeValid(value, parsedValue, flagTypeToCheck)
      ) {
        throw new Error(
          `Invalid value found for flag ${id}: ${value} should be of type ${flagTypeToCheck}.`,
        );
      }

      return parseJson && parsedValue !== undefined ? parsedValue : value;
    });
  },
);

function isFlagValueTypeValid(
  flagValue: FlagValue | undefined,
  flagParsedValue: JSONValue | undefined,
  expectedType: FlagValueType,
): unknown | undefined {
  return expectedType === 'jsonOrUndefined'
    ? flagParsedValue !== undefined
    : // We will revamp abtasty with a config file specifying key - type
      // Meanwhile just disable the rule here
      // eslint-disable-next-line valid-typeof
      typeof flagValue === expectedType.replace('OrUndefined', '');
}

function tryParsingJson(input: FlagValue | undefined): JSONValue | undefined {
  if (typeof input === 'string') {
    try {
      return JSON.parse(input);
    } catch {
      // Ignored
    }
  }
}
