export const RESPONSE_ATTRIBUTES = [
  'hits',
  'hitsPerPage',
  'index',
  'nbHits',
  'nbPages',
  'page',
  'processingTimeMS',
];

export const ATTRIBUTES_TO_RETRIEVES = [
  'default_title',
  'title',
  'objectID',
  'market',
  'model_markets',
  'url',
  'models_count',
  'article_id',
  'categories.l0.id',
  'categories.l1.id',
  'categories.l2.id',
  'categories.l3.id',
  'categories.last',
  'categories.last_id',
  'categories.l0',
  'categories.l1',
  'categories.l2',
  'categories.l3',
  'price',
  'has_free_delivery',
  'has_relay_delivery',
  'has_1day_delivery',
  'has_pro_conditions',
  'has_sample',
  'model_id',
  'is_mmf',
  'has_3x_payment',
  'image_fullpath',
  'brand_name',
  'brand_id',
  'banner',
  'banner.alternate',
  'best_offer',
  'bulk',
  'prices',
  'rating',
  'rating_count',
  'image_path',
  'thumbnails',
  'brand_image_fullpath',
  'seller_country_id',
  'seller_name',
  'seller_id',
  'delivery_offers',
  'indexable',
  'is_competitive',
  'commercial_operation_ids',
  'coupon',
  'commercial_animation',
];
