import { createSelector } from 'reselect';

import { initialState } from './auth/model';
import type { SpartacuxCustomerManagementState } from './customerManagement.model';

export const authSelector = (state: SpartacuxCustomerManagementState) =>
  state.customerManagement?.auth ?? initialState;

export const customerSegmentSelector = createSelector(
  [authSelector],
  (auth) => auth.cluster,
);

export const selectSavedCardModalState = (
  state: SpartacuxCustomerManagementState,
) => state.customerManagement?.savedCardModal;
