export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  REDIRECTION = 301,
  TMP_REDIRECTION = 307,
  PERMANENT_REDIRECTION = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  GONE = 410,
  UNPROCESSABLE_ENTITY = 422,
  FAILED_TRANSACTION_METHOD = 424,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_ERROR = 500,
  GATEWAY_TIMEOUT_ERROR = 504,
}
