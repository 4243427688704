import { SessionProvider as NextAuthSessionProvider } from 'next-auth/react';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { SessionStore } from './components/SessionStore';

export const SessionProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <NextAuthSessionProvider>
      <SessionStore />
      {children}
    </NextAuthSessionProvider>
  );
};

SessionProvider.displayName = 'SessionProvider';
