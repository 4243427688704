import Script from 'next/script';
import { useEffect, useState } from 'react';

import { BATCH_ENABLED_FLAG } from '@/domains/core/Batch/constants';
import {
  negativeBtnLabel,
  positiveSubBtnLabel,
  title,
} from '@/domains/core/Batch/translations';
import { useBatchTracking } from '@/domains/core/Batch/useBatchTracking';
import { hashedUserId } from '@/domains/core/Batch/utils';
import { useFlags } from '@/domains/core/flags/flags.hooks';
import { IS_PROD } from '@/domains/core/platform/constants';
import { getAppSetting } from '@/domains/core/settings/appSettings';
import {
  getPlatform,
  isB2B,
  isB2C,
  isFrancePlatform,
} from '@/domains/core/settings/utils';
import { useAuth } from '@/domains/customerManagement/auth/hooks/useAuth';
import { waitForAndGetConsents } from '@/domains/legal/GDPR/utils/waitForAndGetConsents';

const BATCH_SDK_READY = 'batch-sdk-ready';

function getIcon() {
  if (isB2B()) {
    return 'https://cdn.manomano.com/homepage/icons/default-mmp.png';
  }

  return 'https://cdn.manomano.com/homepage/icons/default-mm.png';
}

export function BatchScript() {
  const [initialized, setInitialized] = useState(false);
  const [enabled = false] = useFlags([BATCH_ENABLED_FLAG]);
  const {
    userId,
    cluster,
    app_cluster,
    last_chance_churn,
    anti_churn,
    hasRetrievedAuthStatus,
    hasRetrievedCustomerSegments,
  } = useAuth();

  const safariSubdomain = getAppSetting('BATCH_SAFARI_SUBDOMAIN');
  const apiKey = getAppSetting('BATCH_API_KEY');
  const subdomain = getAppSetting('BATCH_SUBDOMAIN');
  const authKey = getAppSetting('BATCH_AUTH_KEY');
  const vapidPublicKey = getAppSetting('BATCH_VAPID_PUBLIC_KEY');

  useBatchTracking(initialized);

  useEffect(() => {
    const handleOnLoad = async () => {
      let batchSDKUIConfig: BatchSDK.ISDKUIConfiguration = {
        native: {},
      };

      // Firefox and Safari specific configuration
      if (
        navigator.userAgent.indexOf('Firefox') !== -1 ||
        (navigator.userAgent.indexOf('Safari') !== -1 &&
          navigator.userAgent.indexOf('Chrome') === -1)
      ) {
        batchSDKUIConfig = {
          alert: {
            icon: getIcon(),
            text: title,
            positiveBtnStyle: {
              backgroundColor: isB2B() ? '#FFB950' : '#29B9AD',
              textColor: '#0C193A',
            },
            positiveSubBtnLabel,
            negativeBtnLabel,
          },
        };
      }
      // end Firefox and Safari specific configuration

      const safariUrl = 'https://' + window.location.hostname;
      const safariObj = {
        [safariUrl]: safariSubdomain,
      };

      const registration = navigator.serviceWorker.register(
        '/assets/js/batchsdk-worker-loader.js',
        { scope: '/assets/js/' },
      );

      window.batchSDK?.('setup', {
        serviceWorker: {
          automaticallyRegister: false,
          registration,
        },
        apiKey,
        subdomain,
        authKey,
        dev: !IS_PROD || !isFrancePlatform(), // yes, this is counterintuitive. There is a single Batch application for each of the markets, FR is the main domain, and the others are called dev domains, more details here: https://manomano.atlassian.net/wiki/spaces/LOYALTY/pages/4698865701/Batch+migration#Website-Management
        vapidPublicKey,
        ui: batchSDKUIConfig,
        defaultIcon: getIcon(),
        smallIcon: 'https://cdn.manomano.com/homepage/icons/small-mm.png',
        safari: safariObj,
      });

      setInitialized(true);
    };

    window.addEventListener(BATCH_SDK_READY, handleOnLoad);

    return () => {
      window.removeEventListener(BATCH_SDK_READY, handleOnLoad);
    };
  }, [apiKey, authKey, safariSubdomain, subdomain, userId, vapidPublicKey]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    const identify = async () => {
      const consents = await waitForAndGetConsents();

      if (consents.includes('personalisedAdvertising')) {
        const userIdHash = await hashedUserId(userId);

        window.batchSDK?.((api) => {
          api.profile().then((profile) => {
            profile.identify(userIdHash ? { customId: userIdHash } : null);
            profile.edit((editor) => {
              editor.setRegion(getPlatform().toUpperCase());
              if (isB2C()) {
                editor.setAttribute('b2c_cluster', userIdHash ? cluster : null);
              }
              if (isB2B()) {
                editor.setAttribute('b2b_cluster', userIdHash ? cluster : null);
              }
              editor.setAttribute(
                'app_cluster',
                userIdHash ? app_cluster : null,
              );
              editor.setAttribute(
                'last_chance_churn',
                userIdHash ? last_chance_churn : null,
              );
              editor.setAttribute('anti_churn', userIdHash ? anti_churn : null);
            });
          });
        });
      }
    };

    identify();
  }, [
    anti_churn,
    app_cluster,
    cluster,
    initialized,
    last_chance_churn,
    userId,
  ]);

  if (
    !enabled ||
    !apiKey ||
    !hasRetrievedAuthStatus ||
    !hasRetrievedCustomerSegments
  ) {
    return null;
  }

  return (
    <Script id="batch-sdk" strategy="lazyOnload">
      {`
        (function(b,a,t,c,h,e,r){h='batchSDK';b[h]=b[h]||function() {
              (b[h].q=b[h].q||[]).push(arguments)};e=a.createElement(t),r=a.getElementsByTagName(t)[0];
              e.async=1;e.src=c;r.parentNode.insertBefore(e,r);})(window,document,'script','https://via.batch.com/v4/bootstrap.min.js');

        window.dispatchEvent(new Event('${BATCH_SDK_READY}'));
      `}
    </Script>
  );
}

BatchScript.displayName = 'BatchScript';
