/* eslint-disable no-param-reassign */
import type { Agent as HttpAgent } from 'http';
import type { Agent as HttpsAgent } from 'https';

import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios';

import { getAppSetting } from '@/domains/core/settings/appSettings';
import { shouldRequestHaveCredentials } from '@/domains/customerManagement/auth/services/httpInterceptors/shouldRequestHaveCredentials';

import { HttpHeaders } from './httpHeaders';

let httpAgent: HttpAgent;
let httpsAgent: HttpsAgent;

export interface HttpClient extends AxiosInstance {
  request<T = any, R = AxiosResponse<T>>(config: HttpRequestConfig): Promise<R>;
  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: HttpRequestConfig,
  ): Promise<R>;
  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: HttpRequestConfig,
  ): Promise<R>;
  head<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: HttpRequestConfig,
  ): Promise<R>;
  options<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: HttpRequestConfig,
  ): Promise<R>;
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: HttpRequestConfig,
  ): Promise<R>;
  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: HttpRequestConfig,
  ): Promise<R>;
  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: HttpRequestConfig,
  ): Promise<R>;
}

export interface HttpRequestConfig
  extends Omit<AxiosRequestConfig, 'httpAgent' | 'httpsAgent'> {
  errorContextStack?: string;
  /** Enables keep alive on server side. */
  keepAlive?: boolean;
}

export interface HttpError extends AxiosError {
  config: HttpRequestConfig;
}

/**
 * Exports a custom instance of axios to be able to add some default config
 * as well as global interceptors.
 *
 * Here is the API documentation: https://github.com/axios/axios#axios-api
 */
export const httpClient: HttpClient = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

httpClient.interceptors.request.use(async (config: HttpRequestConfig) => {
  const { keepAlive = true, timeout } = config;
  const customConfig: AxiosRequestConfig & HttpRequestConfig = {
    ...config,
    errorContextStack: new Error().stack?.split('\n').slice(1).join('\n') ?? '',
    timeout: timeout || Number(getAppSetting('REQUEST_TIMEOUT')),
  };

  if (typeof window === 'undefined' && keepAlive) {
    if (httpAgent === undefined) {
      const http = (await import('http')).default;
      httpAgent = new http.Agent({ keepAlive: true });
    }
    if (httpsAgent === undefined) {
      const https = (await import('https')).default;
      httpsAgent = new https.Agent({ keepAlive: true });
    }
    customConfig.httpAgent = httpAgent;
    customConfig.httpsAgent = httpsAgent;
  }

  if (!customConfig.headers) {
    customConfig.headers = {};
  }

  const msApiUrl = getAppSetting('MS_API_URL');
  if (
    getAppSetting('DD_VERSION') !== undefined &&
    msApiUrl !== undefined &&
    config.url?.includes(`${msApiUrl}`)
  ) {
    customConfig.headers[HttpHeaders.X_MM_USER_AGENT] = `site-spartacux/${
      getAppSetting('DD_VERSION') as string
    }`;
  }

  return customConfig;
});

httpClient.interceptors.request.use((config) => {
  const customConfig: AxiosRequestConfig = {
    ...config,
  };

  if (shouldRequestHaveCredentials(config)) {
    customConfig.withCredentials = true;
  }

  return customConfig;
});

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.isAxiosError) {
      const { config, message } = error as HttpError;
      error.message = `${message} ${config.url}`;
      error.stack = `${error.stack}\nCall stack:\n${config.errorContextStack}`;
    }
    throw error;
  },
);
