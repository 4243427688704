type RegisterConsentChangeListener = (callback: () => unknown) => () => void;

/**
 * Register the provided CALLBACK to be called on every consent changes.
 * Return a cleanup function to remove the listener from the Didomi array.
 */
export const registerConsentChangeListener: RegisterConsentChangeListener = (
  callback,
) => {
  const listener = { event: 'consent.changed', listener: callback };
  window.didomiEventListeners = window.didomiEventListeners || [];
  window.didomiEventListeners.push(listener);
  return () => {
    if (Array.isArray(window.didomiEventListeners)) {
      window.didomiEventListeners = window.didomiEventListeners.filter(
        (it) => it !== listener,
      );
    }
  };
};
