import { type FlagValuesMap } from '../flags.state';

export function generateFlagsOverrideCookieValue(values: FlagValuesMap) {
  return JSON.stringify(values);
}

export function parseFlagsOverrideCookieValue(
  overrideCookieValue: string | undefined,
): FlagValuesMap {
  return overrideCookieValue !== undefined
    ? JSON.parse(overrideCookieValue)
    : {};
}
