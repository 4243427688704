import type { SearchOptions, SearchResponse } from '@algolia/client-search';
import type { SearchClient } from 'algoliasearch';

import type { ProductDocument } from '@/domains/productDiscovery/algolia/interfaces/hit';
import {
  getClientAppKey,
  getOptions,
} from '@/domains/productDiscovery/algolia/settings/settings.client';
import {
  getAppId,
  getDefaultIndex,
  getDefaultTimeout,
  getRequestOptions,
} from '@/domains/productDiscovery/algolia/settings/settings.common';

import type { MultipleQueriesQuery } from '../../interfaces/algolia';
import type { MsRecommendationProcessedResponse } from '../../interfaces/msRecommendation';
import { ATTRIBUTES_TO_RETRIEVES, RESPONSE_ATTRIBUTES } from './constants';
import { formatMultipleAlgoliaParams } from './utils/formatAlgoliaParams/formatAlgoliaParams';

let client: SearchClient | null;

const searchClientInstance = async (): Promise<SearchClient> => {
  if (!client) {
    const { default: algoliasearch } = await import('algoliasearch');
    client = algoliasearch(
      getAppId(),
      getClientAppKey(),
      getOptions(getDefaultTimeout()),
    );
  }
  return client;
};

export const searchMultiple = async (
  multipleSearchParameters: MultipleQueriesQuery[],
  userToken: string,
) => {
  const algoliaClient = await searchClientInstance();
  const requestOptions = getRequestOptions({ userToken });
  const { results } = await algoliaClient.search<ProductDocument>(
    multipleSearchParameters,
    requestOptions,
  );
  return results;
};

export const search = async (
  searchOptions: SearchOptions,
  userToken: string,
): Promise<SearchResponse<ProductDocument>[]> => {
  const analyticsTags = [...(searchOptions.analyticsTags ?? [])];
  const defaultIndex = getDefaultIndex();
  const searchParameters: MultipleQueriesQuery = {
    query: '',
    indexName: defaultIndex.value,
    responseFields: RESPONSE_ATTRIBUTES,
    analyticsTags,
    params: {
      attributesToRetrieve: ATTRIBUTES_TO_RETRIEVES,
      attributesToHighlight: [],
      ...searchOptions,
    },
  };
  const response = searchMultiple([searchParameters], userToken);
  return response as Promise<SearchResponse<ProductDocument>[]>;
};

export const algoliaSearchFromRecommendation = (
  params: MsRecommendationProcessedResponse,
  userToken: string,
) => {
  const index = getDefaultIndex();
  const algoliaParams = formatMultipleAlgoliaParams(params, index.value);
  return searchMultiple(algoliaParams, userToken);
};
