import { Router } from 'next/router';
import { useMemo, type FunctionComponent } from 'react';
import type { Reducer } from 'redux';

import { ApplicationState } from '@/domains/core/applicationState/components/ApplicationState';
import type { Action } from '@/domains/core/applicationState/interfaces';
import { FlagValuesMap } from '@/domains/core/flags/flags.state';
import type { PageProps } from '@/domains/core/page/interfaces/PageProps';
import type { SpartacuxState } from '@/domains/core/shell/applicationState/root.state';
import { eventsWithPurposes } from '@/domains/core/tracking/customData/custom.model';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';

type Props = Partial<PageProps> &
  React.PropsWithChildren & {
    startupActions?: Action[];
    startupReducers?: Map<
      string,
      Record<string, Reducer<any, any>> | Reducer<any, any>
    >;
    startupSagas?: () => Generator;
    router?: Router;
    flags?: FlagValuesMap;
  };

export const BaseProvider: FunctionComponent<Props> = ({
  children,
  initialReduxState = {},
  reducersPaths = [],
  startupActions,
  startupReducers,
  startupSagas,
  router,
  flags,
}) => {
  useMemo(() => {
    const gtmSplitPurposesEnabled = Boolean(flags?.[eventsWithPurposes]);
    Gtm.setSplitPurposes(gtmSplitPurposesEnabled);
  }, [flags]);

  return (
    <ApplicationState
      initialReduxState={initialReduxState as Partial<SpartacuxState>}
      reducersPaths={reducersPaths}
      startupActions={startupActions}
      startupReducers={startupReducers}
      startupSagas={startupSagas}
      router={router}
    >
      {children}
    </ApplicationState>
  );
};
