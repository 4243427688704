import { registerConsentChangeListener } from '@/domains/legal/GDPR/utils/registerConsentChangeListener';

export type Consent =
  | 'personalisedAdvertising'
  | 'statistics'
  | 'strictlyNecessary'
  | 'userPreferences';

/** Provides a list of allowed consent categories when available. */
export async function waitForAndGetConsents(): Promise<Consent[]> {
  let cleanConsentChangeListener: () => void = () => {};

  await new Promise<void>((resolve) => {
    cleanConsentChangeListener = registerConsentChangeListener(resolve);
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push((Didomi) => {
      if (Didomi.shouldConsentBeCollected() === false) {
        resolve();
      }
    });
  });

  cleanConsentChangeListener();

  return window.Didomi?.getUserConsentStatusForAll().purposes.enabled ?? [];
}
