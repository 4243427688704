export const platformConfigInitialState = {
  isLoading: false,
};

export interface PlatformConfigState {
  [key: string]: any;
  isLoading: boolean;
  /* eslint-disable camelcase */
  mainPrice?: {
    include_vat: boolean;
  };
  freeReturnsB2B?: {
    begin_date: string;
    end_date: string;
    default_withdrawal_limit: number;
  };
  coupon?: {
    [key: string]: string;
  };

  welcomeOffer?: {
    amount: string;
    coupon: string;
    end_date: string;
    start_date: string;
    max_usages: string;
    min_purchase: string;
    conditions: string;
  };
  /* eslint-enable camelcase */
  welcomeOfferHvc?: {
    amount: number;
  };
}
