import { all, fork } from 'redux-saga/effects';

import { clientFlagsSaga } from '@/domains/core/flags/flags.saga.client';
import { IS_CLIENT_SIDE } from '@/domains/core/settings/constants';
import rootTrackingSaga from '@/domains/core/tracking/tracking.saga';
import rootAuthSaga from '@/domains/customerManagement/auth/saga';
import rootCustomerProfileSaga from '@/domains/customerManagement/customerProfile/saga';
import productHistorySaga from '@/domains/productDiscovery/Recommendation/modules/ProductHistory/ProductHistory.saga';
import { watchFlashMessage } from '@/domains/shoppingCart/FlashMessages/FlashMessages.saga';

export const buildRootSaga = (): (() => Generator) =>
  function* rootSaga() {
    yield all(
      [
        IS_CLIENT_SIDE && fork(rootAuthSaga),
        IS_CLIENT_SIDE && fork(rootTrackingSaga),
        IS_CLIENT_SIDE && fork(watchFlashMessage),
        IS_CLIENT_SIDE && fork(rootCustomerProfileSaga),
        IS_CLIENT_SIDE && fork(productHistorySaga),
        IS_CLIENT_SIDE && fork(clientFlagsSaga),
      ].filter(Boolean),
    );
  };
