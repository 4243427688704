import type { PlatformConfigState } from './platformConfig.state';

export const INIT_CONFIG = 'platformConfig/INIT_CONFIG';
export const SET_CONFIG = 'platformConfig/SET_CONFIG';
export const FAILED_CONFIG = 'platformConfig/FAILED_CONFIG';

export interface InitConfigAction {
  type: typeof INIT_CONFIG;
}

export interface FailedConfigAction {
  type: typeof FAILED_CONFIG;
}

export interface SetConfigAction {
  type: typeof SET_CONFIG;
  payload: PlatformConfigState;
}

export const setPlatformConfig = (
  content: PlatformConfigState,
): SetConfigAction => ({
  type: SET_CONFIG,
  payload: content,
});

export const platformConfigFailed = (): FailedConfigAction => ({
  type: FAILED_CONFIG,
});

export type Action = InitConfigAction | SetConfigAction | FailedConfigAction;
