import { isB2B } from '../settings/utils';
import { DEVSPACE_URL, IS_DEVSPACE, IS_LOCAL } from './constants';
import { getDomains } from './domains/getDomains';

export const getCurrentBaseUrl = (domain?: string) => {
  if (IS_LOCAL) {
    return `http://localhost:${process.env.PORT || 3000}`;
  }

  if (domain) {
    return `https://${domain}`;
  }

  if (IS_DEVSPACE) {
    return DEVSPACE_URL;
  }

  const { domainPro, domainWww } = getDomains();

  return `https://${isB2B() ? domainPro : domainWww}`;
};
