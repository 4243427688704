/* eslint-disable no-underscore-dangle */
import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore, type Reducer, type Store } from 'redux';
import createSagaMiddleware, {
  type SagaMiddleware,
  type Task,
} from 'redux-saga';

import logger from '@/domains/core/observability/logger';

import type { ReducerMap } from '../interfaces';
import SagaRegistry, { type SagaEvent } from '../saga/SagaRegistry';
import SagaRegistryError from '../saga/SagaRegistryError';
import ReducerRegistry from './ReducersRegistry';

class StoreManager {
  readonly _store: Store;

  protected saga: () => Generator | undefined;

  public sagaTask?: Task;

  private sagaMiddleware: SagaMiddleware<object> | undefined;

  private sagaRegistry: SagaRegistry | undefined;

  private reducerRegistry = new ReducerRegistry();

  private replaceableReducers: string[] = [];

  constructor(
    rootSaga: () => Generator | undefined = function* saga() {},
    replaceableReducers: ReducerMap,
    reducers: ReducerMap,
    overrides: Record<string, unknown>,
  ) {
    this.saga = rootSaga;
    this._store = this.init(replaceableReducers, reducers, overrides);
  }

  private init(
    replaceableReducers: ReducerMap,
    reducers: ReducerMap,
    overrides: Record<string, unknown>,
  ) {
    reducers.forEach((value, key) => {
      this.reducerRegistry.registerReducer(key, value);
    });
    replaceableReducers.forEach((value, key) => {
      this.reducerRegistry.registerReducer(key, value);
    });
    this.declareReplaceableReducers(Array.from(replaceableReducers.keys()));
    return this.initializeStore(overrides);
  }

  public get store() {
    return this._store;
  }

  private declareReplaceableReducers(reducersPaths: string[]) {
    this.replaceableReducers = Array.from(
      new Set(this.replaceableReducers.concat(reducersPaths)),
    );
  }

  private declareReplacedReducer(reducerPath: string) {
    if (this.replaceableReducers.includes(reducerPath)) {
      this.replaceableReducers = this.replaceableReducers.filter(
        (replaceableReducerPath) => replaceableReducerPath !== reducerPath,
      );
    }
  }

  public injectReducer(path: string, reducer: Reducer) {
    this.injectReducers(new Map([[path, reducer]]));
    this.declareReplacedReducer(path);
  }

  private injectReducers(reducers: ReducerMap) {
    let count = 0;

    reducers?.forEach((value, key) => {
      const status = this.getReducerStatus(key);
      if (status !== 'registered') {
        count += 1;
        this.reducerRegistry.registerReducer(key, value);
      }
    });

    if (count > 0) {
      this.store.replaceReducer(this.reducerRegistry.rootReducer);
    }
  }

  public injectReplaceableReducers(reducers: ReducerMap) {
    this.declareReplaceableReducers(Array.from(reducers.keys()));
    this.injectReducers(reducers);
  }

  public removeReducer(path: string) {
    if (this.reducerRegistry.hasReducer(path)) {
      this.reducerRegistry.deregisterReducer(path);
      this.store.replaceReducer(this.reducerRegistry.rootReducer);
      this.declareReplacedReducer(path);
    }
  }

  public requireSaga(
    key: string,
    asyncSaga: () => Generator,
    subscriberId: string,
  ) {
    if (this.sagaRegistry !== undefined && asyncSaga) {
      this.sagaRegistry.injectSaga(key, asyncSaga, subscriberId);
    } else {
      throw new SagaRegistryError(
        "sagaRegistry or your async saga doesn't exist",
      );
    }
  }

  public addSagaEventListener(event: SagaEvent, fn: () => void) {
    this.sagaRegistry?.addEventListener(event, fn);
  }

  public removeSagaEventListener(event: SagaEvent, handler: () => void) {
    this.sagaRegistry?.removeEventListener(event, handler);
  }

  public releaseSaga(key: string, subscriberId: string) {
    if (this.sagaRegistry !== undefined) {
      this.sagaRegistry.unsubscribe(key, subscriberId);
    } else {
      throw new SagaRegistryError("sagaRegistry doesn't exist");
    }
  }

  public isSagaInjected(key: string) {
    if (this.sagaRegistry !== undefined) {
      return this.sagaRegistry.isInjected(key);
    }
    throw new SagaRegistryError("sagaRegistry doesn't exist");
  }

  get injectedSagaKeys() {
    return this.sagaRegistry?.registry.keys;
  }

  public getReducerStatus(key: string) {
    if (this.reducerRegistry.hasReducer(key)) {
      if (this.replaceableReducers.includes(key)) {
        return 'replaceable';
      }
      return 'registered';
    }
    return 'not-registered';
  }

  private initializeStore(preloadedState?: Record<string, unknown>): Store {
    // After navigating to a page with an initial Redux state, merge that state
    // with the current state in the store, and create a new store
    const initializedStore = this.initStore(
      this.store
        ? {
            ...this.store.getState(),
            ...preloadedState,
          }
        : preloadedState,
    );

    this.runSaga();
    return initializedStore;
  }

  private initStore(preloadedState?: Record<string, unknown>) {
    if (this.saga === undefined) {
      throw Error(
        'Store must be bootstrapped with a root saga with bootstrap().',
      );
    }

    this.sagaMiddleware = createSagaMiddleware({
      onError: (error, { sagaStack }) => {
        // eslint-disable-next-line no-param-reassign
        error.stack = `${sagaStack}\n${error.stack || ''}`;
        logger.error(error);
      },
    });

    return createStore(
      this.reducerRegistry.rootReducer,
      preloadedState,
      composeWithDevTools(applyMiddleware(this.sagaMiddleware)),
    );
  }

  private runSaga() {
    if (this.sagaMiddleware) {
      this.sagaRegistry = new SagaRegistry(
        this.sagaMiddleware.run,
        this.saga as () => Generator,
      );

      this.sagaTask = this.sagaRegistry.registry.get('root')?.task;
    }
  }
}

export default StoreManager;
