import {
  SET_REQUESTER_TYPE,
  SET_VISITOR_ID,
  type TrackingRequesterTypeAction,
  type TrackingVisitorIdSuccessAction,
} from './tracking.actions';
import { visitorInitialState, type VisitorState } from './tracking.state';

export default function trackingReducer(
  state: VisitorState = visitorInitialState,
  action: TrackingVisitorIdSuccessAction | TrackingRequesterTypeAction,
) {
  switch (action.type) {
    case SET_VISITOR_ID:
      return {
        ...state,
        visitorId: action.payload?.visitorId || '',
      };
    case SET_REQUESTER_TYPE:
      return {
        ...state,
        requesterType: action.payload?.requesterType,
      };
    default:
      return state;
  }
}
