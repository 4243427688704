export interface VisitorState {
  visitorId: string;
  requesterType: string;
}

export const visitorInitialState: VisitorState = {
  visitorId: '',
  requesterType: 'user',
};

export interface TrackingState {
  user: VisitorState;
}

export const trackingInitialState: TrackingState = {
  user: visitorInitialState,
};

export interface SpartacuxTrackingState {
  tracking: TrackingState;
}
