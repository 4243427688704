/* eslint-disable camelcase */
import type { AdvertiserInformation } from '@/domains/productDiscovery/CommercialAnimation/interfaces/AdvertiserInformation';
import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';

export interface CommercialOperationInfoFromApi {
  id: number;
  endAt?: number | null;
}

export interface CommercialOperationInfo {
  id: number;
  endAt?: number | null;
  retailPriceTimeLeftLabel?: string | null;
}

export interface PromoHubFromApi {
  promoHub: CommercialOperationInfoFromApi[];
}

export interface MarketingCampaignsState {
  fetching: boolean;
  error?: string;
  items?: BannerCampaign[];
}
export interface StepCoupon {
  amount: number;
  code: string;
  target: number;
}

export interface AdvertiserInformationState {
  data?: AdvertiserInformation;
}

export const initialState: CommercialAnimationState = {
  commercialOperations: {
    items: [],
    fetched: false,
  },
  marketingCampaigns: {
    fetching: false,
  },
  advertiserInformation: {},
};

export interface CommercialOperationsState {
  items: CommercialOperationInfo[];
  fetched: boolean;
}

export interface CommercialAnimationState {
  commercialOperations: CommercialOperationsState;
  marketingCampaigns?: MarketingCampaignsState;
  advertiserInformation?: AdvertiserInformationState;
}

export type PromoByProducts = Record<
  string,
  CommercialOperationInfo | undefined
>;

export interface CashbackOffer {
  name: string;
  link: {
    url: string;
    target: string;
  };
  displayAmount?: number;
  displayLabel?: string;
}
