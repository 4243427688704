import { getPlatformCurrency } from '@/domains/core/localization/utils/getPlatformCurrency';
import { getPlatformLocale } from '@/domains/core/localization/utils/getPlatformLocale';
import { getPlatformTimezone } from '@/domains/core/localization/utils/getPlatformTimezone';
import {
  MARKET,
  PLATFORM,
  PLATFORMS,
  TLDS,
} from '@/domains/core/settings/constants';
import type { Platform } from '@/domains/core/settings/interfaces/Platform';

/**
 * Get the current market.
 * You should use conditional builds instead of this if possible
 * docs/guides/conditional-build.md
 */
export const getMarket = () => MARKET;
/**
 * Check if the current build is B2B or not.
 * You should use conditional builds instead of this if possible
 * docs/guides/conditional-build.md
 */
export const isB2B = () => MARKET === 'b2b';
/**
 * Check if the current build is B2C or not.
 * You should use conditional builds instead of this if possible
 * docs/guides/conditional-build.md
 */
export const isB2C = () => MARKET === 'b2c';

/**
 * Return the current extension aka TLD for the website
 */
export const getTLD = () => TLDS[PLATFORM];
/**
 * Return the current locale based on the platform
 */
export const getLocale = () => getPlatformLocale(PLATFORM);
/**
 * Return the current currency based on the platform
 */
export const getCurrency = () => getPlatformCurrency(PLATFORM);
/**
 * Get the timezone for the current platform.
 */
export const getTimezone = () => getPlatformTimezone(PLATFORM);
/**
 * Check if the current build is UK or not.
 * You should use conditional builds instead of this if possible
 * docs/guides/conditional-build.md
 */
export const isUkPlatform = () => PLATFORM === PLATFORMS.GREAT_BRITAIN;
/**
 * Check if the current build is IT or not.
 * You should use conditional builds instead of this if possible
 * docs/guides/conditional-build.md
 */
export const isItalyPlatform = () => PLATFORM === PLATFORMS.ITALY;
/**
 * Check if the current build is FR or not.
 * You should use conditional builds instead of this if possible
 * docs/guides/conditional-build.md
 */
export const isFrancePlatform = () => PLATFORM === PLATFORMS.FRANCE;
/**
 * Check if the current build is DE or not.
 * You should use conditional builds instead of this if possible
 * docs/guides/conditional-build.md
 */
export const isGermanyPlatform = () => PLATFORM === PLATFORMS.GERMANY;
/**
 * Check if the current build is ES or not.
 * You should use conditional builds instead of this if possible
 * docs/guides/conditional-build.md
 */
export const isSpainPlatform = () => PLATFORM === PLATFORMS.SPAIN;
/**
 * Check if the current platform match one of the platform given as parameter
 * You should use conditional builds instead of this if possible
 * docs/guides/conditional-build.md
 * @param platforms A list of platforms to match for
 */
export const isPlatformIn = (platforms: Platform[]) =>
  platforms.includes(PLATFORM);
/**
 * Get the current platform.
 * You should use conditional builds instead of this if possible
 * docs/guides/conditional-build.md
 */
export const getPlatform = () => PLATFORM;
