export function registerDidomiBannerDecorator(): void {
  window.didomiEventListeners = window.didomiEventListeners || [];
  window.didomiEventListeners.push({
    event: 'preferences.shown',
    async listener() {
      const [
        bannerBodyElement,
        reactDOM,
        reactDOMClient,
        { DidomiUserInformation },
      ] = await Promise.all([
        import('@/domains/core/ui/utils/waitForElement').then(
          ({ waitForElement }) =>
            waitForElement('.didomi-consent-popup-body', document.body),
        ),
        import('react-dom'),
        import('react-dom/client'),
        import('../components/DidomiUserInformation'),
      ]);

      const userInformationContainer = document.createElement('div');
      bannerBodyElement.appendChild(userInformationContainer);

      const root = reactDOMClient.createRoot(userInformationContainer);

      root.render(
        reactDOM.createPortal(
          <DidomiUserInformation />,
          userInformationContainer,
          'didomi-user-information',
        ),
      );
    },
  });
}
