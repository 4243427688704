import type { Router, RouterEvent } from 'next/router';
import { useEffect, useMemo, useRef } from 'react';

import StoreManager from '@/domains/core/applicationState/store/StoreManager';
import { getStateFromPath } from '@/domains/core/applicationState/util/state';

export const useReducersManager = (
  storeManager: StoreManager,
  reducersPaths: string[],
  initialReduxState: Record<string, unknown>,
  router?: Router,
) => {
  const mustInject = useRef(false);

  useEffect(() => {
    const handleRouteChangeStart = (
      _: RouterEvent,
      { shallow }: { shallow: boolean },
    ) => {
      mustInject.current = !shallow;
    };

    router?.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router?.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router?.events]);

  useMemo(() => {
    if (mustInject.current) {
      const reducers = new Map();
      reducersPaths.forEach((key) => {
        const reducerStatus = storeManager.getReducerStatus(key);
        if (
          reducerStatus === 'not-registered' ||
          reducerStatus === 'replaceable'
        ) {
          // We create a dumb reducer with an initial value coming from the new state.
          // This is required:
          // - to have the data in the state as soon as possible
          // - to let the Hydrate effect be dispatched once the children have rendered.
          reducers.set(
            key,
            (state = getStateFromPath(key, initialReduxState)) => state,
          );
        }
      });
      storeManager.injectReplaceableReducers(reducers);
      mustInject.current = false;
    }
  }, [initialReduxState, reducersPaths, storeManager]);
};
