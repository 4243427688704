import type { SearchOptions } from '@algolia/client-search';

import { ATTRIBUTES_TO_RETRIEVES } from './constants';

export const buildProductHistorySearchOptions = (
  productIds: number[] | string[] = [],
): SearchOptions => ({
  facetFilters: [
    productIds.map(
      (id, index) => `objectID: ${id}<score=${productIds.length - index}>`,
    ),
  ],
  ruleContexts: ['feature-product-history'],
  attributesToRetrieve: ATTRIBUTES_TO_RETRIEVES,
  responseFields: ['hits'],
  attributesToHighlight: [],
});
