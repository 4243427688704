import { getAppSetting } from '@/domains/core/settings/appSettings';
import { IS_CLIENT_SIDE } from '@/domains/core/settings/constants';
import type { AppSettingName } from '@/domains/core/settings/interfaces';

import type * as AlgoliaModel from '../interfaces/interfaces';

export const getAppId = (): string => {
  const appId = 'ALGOLIA_APP_ID' as AppSettingName;
  return getAppSetting(appId) as string;
};

export const getDefaultIndex = (): AlgoliaModel.Index => {
  const appDefaultId = 'ALGOLIA_APP_DEFAULT_INDEX' as AppSettingName;
  return JSON.parse(
    (getAppSetting(appDefaultId) || '{}') as string,
  ) as AlgoliaModel.Index;
};

export const getIndexes = (): AlgoliaModel.Index[] => {
  const appIndexesKey = 'ALGOLIA_APP_INDEXES' as AppSettingName;
  return JSON.parse(
    (getAppSetting(appIndexesKey) || '[]') as string,
  ) as AlgoliaModel.Index[];
};

export const getAlgoliaAppSuggestionsIndexes = () => {
  const appSuggestionsKey = 'ALGOLIA_APP_SUGGESTIONS_INDEXES' as AppSettingName;
  return JSON.parse(
    (getAppSetting(appSuggestionsKey) || '{}') as string,
  ) as AlgoliaModel.SuggestionsIndexes;
};

export const getDefaultTimeout = (): number =>
  parseInt(getAppSetting('ALGOLIA_APP_TIMEOUT') as string, 10);

export const getSuggestionsRedirectionsTimeout = (): number =>
  parseInt(
    getAppSetting('ALGOLIA_SUGGESTIONS_REDIRECTIONS_TIMEOUT') as string,
    10,
  );

export const getRequestOptions = (options: {
  userToken: string;
}): AlgoliaModel.AlgoliaRequestOptions => {
  const { userToken } = options;
  return {
    headers: {
      'X-Algolia-UserToken': userToken,
    },
  };
};

export const getProxySettings = (
  searchEngineProxyEnabled = false,
): AlgoliaModel.ProxySettings =>
  searchEngineProxyEnabled
    ? getSearchEngineProxySettings()
    : getLegacyProxySettings();

function getLegacyProxySettings() {
  return {
    host: getAppSetting('INFRA_ALGOLIA_PROXY_HOST') as string,
    port: getAppSetting('INFRA_ALGOLIA_PROXY_PORT') as string,
    scheme: getAppSetting('INFRA_ALGOLIA_PROXY_SCHEME') as string,
    enabled: (getAppSetting('ALGOLIA_USE_PROXY') as string) === 'true',
  };
}

function getSearchEngineProxySettings() {
  return {
    host: IS_CLIENT_SIDE
      ? (getAppSetting('MS_API_URL') as string)
          .replace('http://', '')
          .replace('https://', '')
      : (getAppSetting('MS_SEARCH_ENGINE_PROXY_API_HOST') as string),
    port: getAppSetting('MS_SEARCH_ENGINE_PROXY_API_PORT') as string,
    scheme: IS_CLIENT_SIDE
      ? 'https'
      : (getAppSetting('MS_SEARCH_ENGINE_PROXY_API_SCHEME') as string),
    enabled: true,
  };
}
