export enum ABTests {
  APP_B2B_TOUCHPOINT = 'appB2bTouchpoint',
  SUSPECTED_PRO_MODAL_REVAMP = 'suspectedProModalRevamp',
  AUTOCOMPLETION_B2B_SIGN_UP = 'pepeAutocompletionB2bSignup',
  PEPE_AUTOCOMPLETE_ADDRESS = 'pepeAutocompleteAddress',
  PEPE_LAST_VIEWED_CATEGORIES_HP_B2B = 'pepeLastViewedCategoriesHpB2b',
  PEPE_SPLIT_COMPANY_SEARCH_FR_UK = 'pepeSplitCompanySearchFrUk',
  PEPE_COMPANY_SEARCH_ES_IT = 'pepeCompanyNameSearchEsIt',
  ENABLE_MF_REBRANDING = 'enableMfRebranding',
  PEPE_UNIFY_AUTH = 'pepeUnifyAuth',
  PEPE_LAST_BOUGHT_B2B_HP_V2 = 'pepeLastBoughtB2bHpV2',
}
