import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import logger from '@/domains/core/observability/logger';
import { selectVisitorId } from '@/domains/core/tracking/selectors/selectVisitorId';
import {
  CHECK_AUTH_TOKEN_ERROR,
  CHECK_AUTH_TOKEN_SUCCESS,
} from '@/domains/customerManagement/auth/actions';
import type { Auth, UserId } from '@/domains/customerManagement/auth/model';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';
import { publishPageviewProductEvent } from '@/domains/loyalty/services/proEvent.service';
import { AlgoliaSearchInsights } from '@/domains/productDiscovery/algolia/searchInsights/AlgoliaSearchInsights';
import { recentlyViewedTitle } from '@/domains/productDiscovery/Recommendation/components/PlaylistGraphQL/components/PlaylistProductHistory/playlistProductHistory.translations';
import { selectCategoryId } from '@/domains/productDiscovery/Recommendation/modules/ProductHistory/ProductHistory.selectors';

import { processSearchResult } from '../../services/algolia/processor';
import { search } from '../../services/algolia/proxy.client';
import { buildProductHistorySearchOptions } from '../../services/algolia/searchParameters';
import { RECENTLY_VIEWED_PRODUCTS_MIN_NUMBER } from './constants';
import {
  ADD_TO_PRODUCT_HISTORY,
  addProductToProductHistorySuccess,
  FETCH_PRODUCT_HISTORY,
  fetchPlaylistProductHistoryError,
  fetchPlaylistProductHistorySuccess,
  fetchProductHistoryPending,
  fetchProductHistorySuccess,
  mergeProductHistoryFailed,
  mergeProductHistorySuccess,
  productHistoryFailed,
  type AddProductToProductHistory,
} from './ProductHistory.actions';
import type { PlaylistHistory, ProductHistory } from './ProductHistory.model';
import {
  getProductHistoryService,
  mergeToProductHistoryService,
} from './services/productHistory';
import {
  addProductHistoryToLocalStorage,
  buildLocalStorageProductHistory,
  getLocalStorageProductHistory,
  getPreviousAuthStatus,
  setPreviousAuthStatus,
} from './utils';

/* ---------------------------------- MERGE --------------------------------- */

export function* resetProductHistory(): any {
  const newProductHistory = [] as ProductHistory;

  yield addProductHistoryToLocalStorage(newProductHistory);
  yield put(mergeProductHistoryFailed(newProductHistory));
}

export function* mergeProductHistory(): any {
  const localProductHistory = yield call(getLocalStorageProductHistory);

  try {
    const updatedProductHistory = yield call(
      mergeToProductHistoryService,
      localProductHistory,
    );

    if (updatedProductHistory?.length > 0) {
      addProductHistoryToLocalStorage(updatedProductHistory);
      yield put(mergeProductHistorySuccess(updatedProductHistory));
    } else {
      yield resetProductHistory();
    }
  } catch (e) {
    yield resetProductHistory();
  }
}

export function* userJustLoggedIn(
  currentAuthStatus: Auth,
): Generator<any, any, any> {
  const { isAuthenticated, isB2B } = currentAuthStatus;
  if (isB2B) {
    return false;
  }
  const previousAuthStatus = yield call(getPreviousAuthStatus);
  if (previousAuthStatus === false && isAuthenticated === true) {
    yield setPreviousAuthStatus(true);
    return true;
  }
  if (previousAuthStatus === true && isAuthenticated === false) {
    yield setPreviousAuthStatus(false);
  }
  return false;
}

/* ----------------------------------- GET ---------------------------------- */

function* initProductHistory(productHistory: ProductHistory): any {
  try {
    const { userId } = yield select(authSelector);
    const visitorId = yield select(selectVisitorId);
    const userToken = userId
      ? yield call(AlgoliaSearchInsights.calculateUserIdHash, String(userId))
      : visitorId;

    const productHistoryIds = productHistory.map(({ productId }) =>
      String(productId),
    );
    const searchParameters =
      buildProductHistorySearchOptions(productHistoryIds);
    const response = yield call(search, searchParameters, userToken);

    const playlistHistory: PlaylistHistory[] =
      yield processSearchResult(response);

    if (playlistHistory?.length > 0) {
      playlistHistory[0].title = recentlyViewedTitle;
      yield put(fetchPlaylistProductHistorySuccess([playlistHistory[0]]));
    } else {
      yield put(fetchPlaylistProductHistorySuccess([]));
    }
  } catch (err) {
    yield put(fetchPlaylistProductHistoryError(err));
  }
}

export function* getProductHistory(): Generator<any, any, any> {
  yield put(fetchProductHistoryPending());
  const auth: Auth = yield call(waitForAndGetAuthSaga);

  const isNecessaryMergeProductHistory = yield call(userJustLoggedIn, auth);
  if (isNecessaryMergeProductHistory) {
    yield mergeProductHistory();
  }

  try {
    const productHistory = yield call(
      auth.isAuthenticated
        ? getProductHistoryService
        : getLocalStorageProductHistory,
    );

    if (productHistory?.length > 0) {
      addProductHistoryToLocalStorage(productHistory);
      if (productHistory.length >= RECENTLY_VIEWED_PRODUCTS_MIN_NUMBER) {
        yield initProductHistory(productHistory);
      }
      yield put(fetchProductHistorySuccess(productHistory));
    } else {
      addProductHistoryToLocalStorage(productHistory);
      yield put(fetchProductHistorySuccess(productHistory));
    }
  } catch (err) {
    yield put(productHistoryFailed(err));
  }
}

/* ----------------------------------- ADD ---------------------------------- */

export function* publish(
  customerId: UserId,
  productId: number,
): Generator<any, any, any> {
  const visitorId = yield select(selectVisitorId);
  const categoryId = yield select(selectCategoryId);
  if (productId && customerId) {
    yield call(publishPageviewProductEvent, {
      productId,
      customerId,
      visitorId,
      categoryId,
    });
  }
}

export const RETRIEVE_DATA_TIMEOUT_MS = 5000;

export function* waitForAndGetAuthSaga(): Generator<any, Auth, any> {
  let auth: Auth = yield select(authSelector);

  if (!auth.hasRetrievedAuthStatus) {
    const waitForTimeout = delay(RETRIEVE_DATA_TIMEOUT_MS);
    const waitForAuthStatus = race([
      take(CHECK_AUTH_TOKEN_SUCCESS),
      take(CHECK_AUTH_TOKEN_ERROR),
    ]);
    // Waits for all necessary data to be available or timeout to be reached
    const result = yield race({
      waitForAuthStatus,
      waitForTimeout,
    });

    if (result.waitForTimeout) {
      logger.error(new Error('Timeout reached when retrieving auth data.'));
    } else {
      auth = yield select(authSelector);
    }
  }

  return auth;
}

export function* addProductToProductHistory(
  action: AddProductToProductHistory,
): any {
  const { productId } = action;
  const auth: Auth = yield call(waitForAndGetAuthSaga);

  const isNecessaryMergeProductHistory = yield call(userJustLoggedIn, auth);
  if (isNecessaryMergeProductHistory) {
    yield mergeProductHistory();
  }

  if (auth.isAuthenticated === true) {
    try {
      yield call(publish, auth.userId, productId);
    } catch (err) {
      yield productHistoryFailed(err);
    }
  }
  // The product history on the Local Storage and Redux should be the same because
  // if there is a need to merge it the localhost will be updated with the data at this point
  const productHistory = yield buildLocalStorageProductHistory(productId);

  yield addProductHistoryToLocalStorage(productHistory);
  yield put(addProductToProductHistorySuccess(productHistory));
}

export default function* rootProductHistorySaga() {
  yield takeLatest(ADD_TO_PRODUCT_HISTORY, addProductToProductHistory);
  yield takeLeading(FETCH_PRODUCT_HISTORY, getProductHistory);
}
