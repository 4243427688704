/* eslint-disable no-restricted-properties */
import { IS_CLIENT_SIDE } from '@/domains/core/settings/constants';
import {
  GtmEvent,
  GtmEventListenerEventHandler,
  RemoveGtmEventListener,
  TypedGtmEvent,
  TypedGtmEventType,
} from '@/domains/core/tracking/utils/types';
import {
  Consent,
  waitForAndGetConsents,
} from '@/domains/legal/GDPR/utils/waitForAndGetConsents';

type EventListenersType = {
  [key in TypedGtmEventType]?: Array<
    readonly [GtmEventListenerEventHandler<any>, Consent[]]
  >;
};

/**
 * This helper abstracts GTM logic to add easily some new logic if necessary.
 *
 * It stores the events until the user consent has been retrieved and the
 * pageview event of the current page has been sent.
 */
export class Gtm {
  private static isRunningQueue = false;
  private static consentRetrieved = false;
  private static currentPageType: string | undefined;
  private static retrievedConsents: Consent[] | undefined;
  private static eventListeners: EventListenersType = {};
  private static splitPurposes = false;

  /**
   * This queue stores the events until the user consent has been retrieved
   * Or a pageview event has been send as it needs to be the 1st event
   */
  private static eventQueue: Array<[GtmEvent, { dispatchEvent?: true }?]> = [];

  /**
   * Enables or disables the split purposes feature.
   * @param value - Whether to enable the split purposes feature.
   */
  static setSplitPurposes(value: boolean) {
    Gtm.splitPurposes = value;
  }

  /**
   * Listen and wait for didomi to be loaded
   *  Then we got the consent to send the events
   *
   */
  static async listenForConsent() {
    Gtm.retrievedConsents = await waitForAndGetConsents();
    Gtm.consentRetrieved = true;

    Gtm.runQueue();
  }

  /**
   * As the tracking saga has many dependencies and is on a saga it can take
   * some time to run.
   * Meanwhile is running, an event can be dispatched of the new page before the
   * page_view.
   * It means that the event will have the wrong page_type.
   *
   * The first thing we do at navigation is to reset the currentPageType to
   * undefined to avoid sending a wrong value.
   */
  static handlePageChange() {
    Gtm.currentPageType = undefined;
  }

  /**
   * This function pushes your event object to the gtm data layer.
   * You can use the generic that can help you to the typing of your object.
   * @param event Your tracking object
   * @example
   *    interface MyTrackingObject {
   *      event: 'my_tracking_event',
   *    }
   *
   *    Gtm.push<MyTrackingObject>({ event: 'my_tracking_event' });
   */
  static push<T extends GtmEvent>(event: T): void {
    if (event.event === 'pageview') {
      /**
       * We store the context here but probably on url path change or
       * pageChangeSaga we should setup the proper one. Ideally on the
       * handlePageChange
       */
      Gtm.currentPageType = event.page_type;
    }

    const add = event.event === 'pageview' ? 'unshift' : 'push';
    if (event.event === 'pageview' || Gtm.splitPurposes) {
      // If we have split purposes we need to triple the event with the
      // different purposes. Since we have 3 different purposes we need to
      // only trigger the `dispatchEvent` on the last one.
      Gtm.eventQueue[add](
        [
          {
            ...event,
            purpose: 'marketing',
            page_type: Gtm.currentPageType,
          },
        ],
        [
          {
            ...event,
            purpose: 'google-analytics',
            page_type: Gtm.currentPageType,
          },
        ],
        [
          {
            ...event,
            purpose: 'amplitude',
            page_type: Gtm.currentPageType,
          },
          { dispatchEvent: true },
        ],
      );
    } else {
      // If we don't have split purposes we just push the event to the queue
      // and trigger the `dispatchEvent` on it.
      Gtm.eventQueue[add]([
        {
          ...event,
          page_type: Gtm.currentPageType,
        },
        { dispatchEvent: true },
      ]);
    }

    if (IS_CLIENT_SIDE && !('scheduler' in window)) {
      // If the polyfill has not been loaded yet, we need to wait for it
      // to be loaded before running the queue.
      return;
    }

    Gtm.runQueue();
  }

  /**
   * Pushes a typed event to the Google Tag Manager data layer.
   *
   * This method allows for type-safe pushing of events to GTM, ensuring that
   * the event object matches the expected structure for the given event type.
   *
   * @template Type - The type of the GTM event, extending from
   * TypedGtmEventType.
   * @param event - The event object to push to GTM.
   */
  static pushEvent<Type extends TypedGtmEventType>(
    event: TypedGtmEvent<Type>,
  ): void {
    Gtm.push(event);
  }

  private static async dispatchEvent(gtmEvent: TypedGtmEvent) {
    const listeners = Gtm.eventListeners[gtmEvent.event];

    if (listeners && Gtm.retrievedConsents !== undefined) {
      listeners.forEach(([listener, consentsNeeded]) => {
        if (
          consentsNeeded.length === 0 ||
          consentsNeeded.every((consent) =>
            Gtm.retrievedConsents?.includes(consent),
          )
        ) {
          listener(gtmEvent);
        }
      });
    }
  }

  static resetDataLayer() {
    if (
      window.dataLayer !== undefined &&
      window.google_tag_manager !== undefined
    ) {
      for (const gtmKey of Object.keys(window.google_tag_manager)) {
        if (
          Gtm.isGTMContainerKey(gtmKey) &&
          window.google_tag_manager[gtmKey].dataLayer?.reset
        ) {
          window.google_tag_manager[gtmKey].dataLayer.reset();
        }
      }
    }

    const gtmEventsToKeep = [
      'gtm.js',
      'gtm.dom',
      'gtm.load',
      'didomi-consent',
      'didomi-ready',
    ];
    const appliedResetDataLayer = window.dataLayer.filter(
      ({ event }) => event && gtmEventsToKeep.includes(event),
    );
    window.dataLayer.length = 0;
    window.dataLayer.push(...appliedResetDataLayer);
    window.dataLayer.length = appliedResetDataLayer.length;
  }

  private static isGTMContainerKey(key: string): key is `GTM-${string}` {
    const gtmContainerReg = /gtm-/i;
    return gtmContainerReg.test(key);
  }

  private static async runQueue() {
    if (
      Gtm.currentPageType === undefined ||
      Gtm.consentRetrieved === false ||
      Gtm.isRunningQueue
    ) {
      return;
    }

    performance.mark('runQueue-start');
    Gtm.isRunningQueue = true;

    scheduler.postTask(Gtm.runQueueBody, {
      priority: 'background',
    });

    Gtm.isRunningQueue = false;
    performance.mark('runQueue-end');
  }

  private static async runQueueBody() {
    while (Gtm.eventQueue.length > 0) {
      performance.mark('runQueueBody-start');
      const entry = Gtm.eventQueue.shift();

      if (!entry) {
        break;
      }

      const [event, settings = {}] = entry;

      if (event.page_type === undefined) {
        /**
         * Some events are send before the pageview event, so we need to set the
         * page_type here.
         */
        event.page_type = Gtm.currentPageType;
      }

      await scheduler.yield();

      performance.mark('runQueueBody-before-push', {
        detail: event.event + ' ' + event.purpose,
      });
      window.dataLayer.push(event);
      performance.mark('runQueueBody-after-push', {
        detail: event.event + ' ' + event.purpose,
      });

      if (settings.dispatchEvent) {
        await scheduler.yield();
        Gtm.dispatchEvent(event as TypedGtmEvent);
      }
      performance.mark('runQueueBody-end');
      await scheduler.yield();
    }
  }

  static addEventListener<EventType extends TypedGtmEventType>(
    event: EventType,
    callback: GtmEventListenerEventHandler<EventType>,
    consentsNeeded: Consent[] | 'no-consents-needed',
    abortSignal?: AbortSignal,
  ): RemoveGtmEventListener {
    if (!Gtm.eventListeners[event]) {
      Gtm.eventListeners[event] = [];
    }

    if (
      process.env.NODE_ENV !== 'production' &&
      Array.isArray(consentsNeeded) &&
      consentsNeeded.length === 0
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        "No consents were provided for this event, this means it will always be triggered. While it's possible, make sure the code that is executed in the callback is GDPR compliant.",
      );
      // eslint-disable-next-line no-console
      console.warn(
        'To make sure it\'s intended, please use the string "no-consents-needed" instead of an empty array.',
      );
    }

    const consents = typeof consentsNeeded === 'string' ? [] : consentsNeeded;

    const args = [callback, consents] as const;
    Gtm.eventListeners[event]?.push(args);

    const removeEventListener = () => {
      const listeners = Gtm.eventListeners[event];
      const index = listeners?.indexOf(args);

      if (index !== undefined && index !== -1) {
        listeners?.splice(index, 1);
      }
    };

    abortSignal?.addEventListener('abort', removeEventListener);

    return removeEventListener;
  }
}
