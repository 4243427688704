import type { Formatters } from './ABTasty.model';

export const processVariationName = (variationName: string) => {
  if (variationName.toLowerCase() === 'original') {
    return '0';
  }
  return variationName.split(' ').pop();
};

export const processABTests: Formatters['current']['formatAbTests'] = (
  history,
  allTests,
) => {
  const testsIDs = Object.keys(history).map((id: any) => allTests[id]);

  return testsIDs
    .filter((test) => test.type === 'ab')
    .map((test) => {
      const variationId: number = parseInt(history[test.id], 10);
      return {
        ab_test_id: test.id.toString(),
        ab_test_variant: test.asyncVariationInfoById[variationId]
          ? processVariationName(test.asyncVariationInfoById[variationId].name)
          : '0',
      };
    });
};

export const processPersoCampaignABTasty: Formatters['current']['formatPersoCampaign'] =
  (history, allTests) => {
    const testsIDs = Object.keys(history).map((id) => allTests[id]);

    return testsIDs
      .filter((test) => test.type === 'subsegment')
      .map((test) => {
        const variationId: number = parseInt(history[test.id], 10);

        return {
          master_campaign_id: test.parentID?.toString(),
          subsegment_id: test.id.toString(),
          variation_name: test.asyncVariationInfoById[variationId]
            ? test.asyncVariationInfoById[variationId].name
            : 'Original',
        };
      });
  };

export const processABTestsNext: Formatters['next']['formatAbTests'] = (
  history,
  allTests,
) => {
  const currentFormat = processABTests(history, allTests);

  return currentFormat.map(
    ({ ab_test_id, ab_test_variant }) =>
      `${ab_test_id}_${ab_test_variant}` satisfies `${string}_${string}`,
  );
};

export const processPersoCampaignABTastyNext: Formatters['next']['formatPersoCampaign'] =
  (history, allTests) => {
    const currentFormat = processPersoCampaignABTasty(history, allTests);

    return currentFormat.map(
      ({ master_campaign_id, subsegment_id, variation_name }) =>
        `${master_campaign_id}_${subsegment_id}_${variation_name}` satisfies `${string}_${string}_${string}`,
    );
  };
