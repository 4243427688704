/* eslint-disable camelcase */
import { queryData } from '@/domains/core/graphqlClient/queryClient';
import { httpClient } from '@/domains/core/httpClient/httpClient';
import logger from '@/domains/core/observability/logger';
import { getAppSetting } from '@/domains/core/settings/appSettings';
import {
  CustomerSegmentsDocument,
  type CustomerSegmentsQuery,
} from '@/domains/customerManagement/auth/services/generated/customerSegments.query.generated';

import { CustomerSegmentFromGQL, type B2BSuspectedPro } from '../model';

const getCustomerSegmentsUrl = () => {
  const MS_API_URL = getAppSetting('MS_API_URL');
  return `${MS_API_URL}/api/v2/customer-segment/customers`;
};

export const fetchCustomerSegmentsFromGraphQL = (): Promise<
  CustomerSegmentFromGQL | undefined | null
> => {
  const response = queryData<CustomerSegmentsQuery>(
    CustomerSegmentsDocument,
    {},
  )
    .then((result) => result?.GetCustomerSegments)
    .catch((e) => {
      logger.error(e.message);
      return undefined;
    });
  return response;
};

export const updateCustomerSegment = async ({
  b2b_suspected_pro,
  b2b_welcome,
  b2b_vat_modal,
}: {
  b2b_suspected_pro?: B2BSuspectedPro;
  b2b_welcome?: boolean;
  b2b_vat_modal?: boolean;
}): Promise<boolean> => {
  try {
    await httpClient.patch(getCustomerSegmentsUrl(), {
      ...(b2b_suspected_pro !== undefined && { b2b_suspected_pro }),
      ...(b2b_welcome !== undefined && { b2b_welcome }),
      ...(b2b_vat_modal !== undefined && { b2b_vat_modal }),
    });
    return true;
  } catch (error) {
    return false;
  }
};
