import { createContext, useContext } from 'react';

import type StoreManager from '../store/StoreManager';

export const StoreManagerContext = createContext<StoreManager | null>(null);

export function useStoreManager() {
  const storeManager = useContext(StoreManagerContext);
  return { storeManager };
}
