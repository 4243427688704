import type { AlertMessage } from '../FlashMessages.actions';

export function queueToList(
  alertMessagesObject: Record<number, AlertMessage[]>,
): Array<AlertMessage> {
  const returnList: AlertMessage[] = [];
  const orderedKeys = Object.keys(alertMessagesObject).sort(
    (a, b) => parseInt(b, 10) - parseInt(a, 10),
  );
  orderedKeys.forEach((priorityIndex) => {
    const alertMessagesObj: AlertMessage[] =
      alertMessagesObject[parseInt(priorityIndex, 10)];
    alertMessagesObj.forEach((alertMsg: AlertMessage) => {
      returnList.push(alertMsg);
    });
  });
  return returnList;
}
