import { queryData } from '@/domains/core/graphqlClient/queryClient';
import logger from '@/domains/core/observability/logger';
import {
  GetCustomerProfileDocument,
  type GetCustomerProfileQuery,
} from '@/domains/customerManagement/services/msCustomer/queries/generated/getCustomerProfile.query.generated';

import { toCustomerProfile } from './utils/toCustomerProfile';

export const getCustomerProfile = async () => {
  try {
    const result = await queryData<GetCustomerProfileQuery>(
      GetCustomerProfileDocument,
    );

    if (result?.customer) {
      return toCustomerProfile(result.customer);
    } else {
      throw new Error('Could not provide the customer profile');
    }
  } catch (error) {
    logger.error(error.message);
    throw error(error);
  }
};
