export const ADD_FLASH_MESSAGE = 'shoppingCart/flashMessages/ADD_FLASH_MESSAGE';
export const SHOW_FLASH_MESSAGE =
  'shoppingCart/flashMessages/SHOW_FLASH_MESSAGE';
export const CLEAR_FLASH_MESSAGE =
  'shoppingCart/flashMessages/CLEAR_FLASH_MESSAGE';
export const FLASH_MESSAGE_ACTION =
  'shoppingCart/flashMessages/FLASH_MESSAGE_ACTION';

export enum AlertIDs {
  CLIPBOARD_COPIED = 'shoppingCart/flashMessages/id/CLIPBOARD_COPIED',
  CART_PRODUCT_REMOVED = 'shoppingCart/flashMessages/id/CART_PRODUCT_REMOVED',
  MAIN_CART_ITEM_ADDITION = 'shoppingCart/flashMessages/id/MAIN_CART_ITEM_ADDITION',
  SAVEDCART_PRODUCT_REMOVED = 'shoppingCart/flashMessages/id/SAVEDCART_PRODUCT_REMOVED',
  ASIDE_CART_PRODUCT_DELETION = 'shoppingCart/flashMessages/id/ASIDE_CART_PRODUCT_DELETION',
  MOVED_FROM_SAVEDCART_TO_CART = 'shoppingCart/flashMessages/id/MOVED_FROM_SAVEDCART_TO_CART',
  MOVED_FROM_ASIDECART_TO_CART = 'shoppingCart/flashMessages/id/MOVED_FROM_ASIDECART_TO_CART',
  MOVED_ITEM_FROM_MAINCART_TO_ASIDECART = 'shoppingCart/flashMessages/id/MOVED_FROM_MAINCART_TO_ASIDECART',
  MAIN_CART_ITEM_EDITION_FAIL = 'shoppingCart/flashMessages/id/MAIN_CART_ITEM_EDITION_FAIL',
  CHANGE_SAVED_PRODUCT_QUANTITY_FAIL = 'shoppingCart/flashMessages/id/CHANGE_SAVED_PRODUCT_QUANTITY_FAIL',
  ASIDE_CART_ITEM_EDITION_FAIL = 'shoppingCart/flashMessages/id/ASIDE_CART_ITEM_EDITION_FAIL',
  VAT_NUMBER_NOT_VALID = 'shoppingCart/flashMessages/id/VAT_NUMBER_NOT_VALID',
  VAT_NUMBER_VALID = 'shoppingCart/flashMessages/id/VAT_NUMBER_VALID',
  VAT_NUMBER_VALIDATION_PENDING = 'shoppingCart/flashMessages/id/VAT_NUMBER_VALIDATION_PENDING',
  SAVE_FURTHER_INFORMATION_FAILED = 'customerManagement/flashMessages/id/SAVE_FURTHER_INFORMATION_FAILED',
  FIRST_COMMAND_FORM_SUBMIT_FAILED = 'customerManagement/flashMessages/id/FIRST_COMMAND_FORM_SUBMIT_FAILED',
  ADDRESS_SETTINGS_FORM_SUBMIT_FAILED = 'addressSettings/flashMessages/id/ADDRESS_SETTINGS_FORM_SUBMIT_FAILED',
  ADDRESS_SETTINGS_ADDRESS_MODIFY_SUCCESS = 'addressSettings/flashMessages/id/ADDRESS_SETTINGS_ADDRESS_MODIFY_SUCCESS',
  ADDRESS_SETTINGS_ADDRESS_CREATE_SUCCESS = 'addressSettings/flashMessages/id/ADDRESS_SETTINGS_ADDRESS_CREATE_SUCCESS',
  ADDRESS_FORM_CREATE_SUCCESS = 'customerManagement/flashMessages/id/ADDRESS_FORM_CREATE_SUCCESS',
  ADDRESS_FORM_UPDATE_SUCCESS = 'customerManagement/flashMessages/id/ADDRESS_FORM_UPDATE_SUCCESS',
  ADDRESS_FORM_SUBMIT_FAILED = 'customerManagement/flashMessages/id/ADDRESS_FORM_SUBMIT_FAILED',
  ADDRESS_SELECT_SUCCESS = 'customerManagement/flashMessages/id/ADDRESS_SELECT_SUCCESS',
  SWITCH_PRODUCT_SUCCESS = 'shoppingCart/flashMessages/id/SWITCH_PRODUCT_SUCCESS',
  ADD_OTHER_MF_PRODUCT_SUCCESS = 'shoppingCart/flashMessages/id/ADD_OTHER_MF_PRODUCT_SUCCESS',
  SWITCH_PRODUCT_FAILED = 'shoppingCart/flashMessages/id/SWITCH_PRODUCT_FAILED',
  ADD_OTHER_MF_PRODUCT_FAILED = 'shoppingCart/flashMessages/id/ADD_OTHER_MF_PRODUCT_FAILED',
  SELECT_DELIVERY_OPTION_ERROR = 'deliveryPage/flashMessages/id/SELECT_DELIVERY_OPTION_ERROR',
  PRODUCTS_PUT_ASIDE_SUCCESS = 'deliveryPage/flashMessages/id/PRODUCTS_PUT_ASIDE_SUCCESS',
  PRODUCTS_REMOVED_SUCCESS = 'deliveryPage/flashMessages/id/PRODUCTS_REMOVED_SUCCESS',
  ADDRESS_BOOK_ERROR = 'customerManagement/flashMessages/id/ADDRESS_BOOK_ERROR',
  REQUEST_FOR_HELP_SUCCESS = 'shoppingCart/flashMessages/id/REQUEST_FOR_HELP_SUCCESS',
  REQUEST_FOR_HELP_MULTIPRODUCT_SUCCESS = 'shoppingCart/flashMessages/id/REQUEST_FOR_HELP_MULTIPRODUCT_SUCCESS',
  REQUEST_FOR_HELP_MULTIPRODUCT_FAILED = 'shoppingCart/flashMessages/id/REQUEST_FOR_HELP_MULTIPRODUCT_FAILED',
  ORDER_RECAP_OPTIN_FORM_FAILED = 'customerFinanceOperations/flashMessages/id/ORDER_RECAP_OPTIN_FORM_FAILED',
  ORDER_RECAP_OPTIN_FORM_SUCCESS = 'customerFinanceOperations/flashMessages/id/ORDER_RECAP_OPTIN_FORM_SUCCESS',
  ADD_TO_CART_SUCCESS = 'productDiscovery/flashMessages/id/ADD_TO_CART_SUCCESS',
  ADD_TO_CART_OUT_OF_STOCK = 'productDiscovery/flashMessages/id/ADD_TO_CART_OUT_OF_STOCK',
  ADD_TO_CART_FAILED = 'productDiscovery/flashMessages/id/ADD_TO_CART_FAILED',
  REMOVE_CREDIT_CARD_SUCCEED = 'savedCards/flashMessages/id/REMOVE_CREDIT_CARD_SUCCEED',
  REMOVE_CREDIT_CARD_FAILED = 'savedCards/flashMessages/id/REMOVE_CREDIT_CARD_FAILED',
  GET_MAIN_CART_SHARE_KEY_FAILED = 'shoppingCart/flashMessages/id/GET_MAIN_CART_SHARE_KEY_FAILED',
  VALIDATE_ALL_DELIVERY_OPTIONS_ERROR = 'deliveryPage/flashMessages/id/VALIDATE_ALL_DELIVERY_OPTIONS_ERROR',
  ADD_INVOICE_TAG_SUCCESS = 'invoices/flashMessages/id/ADD_INVOICE_TAG_SUCCESS',
  ADD_INVOICE_TAG_FAILED = 'invoices/flashMessages/id/ADD_INVOICE_TAG_FAILED',
  NO_TRANSACTIONS_SELECTED = 'invoices/flashMessages/id/NO_TRANSACTIONS_SELECTED',
  REQUEST_HUMAN_SUPPORT_EMAIL_SUCCESS = 'productPage/flashMessages/id/REQUEST_HUMAN_SUPPORT_EMAIL_SUCCESS',
  REQUEST_HUMAN_SUPPORT_EMAIL_FAILED = 'productPage/flashMessages/id/REQUEST_HUMAN_SUPPORT_EMAIL_FAILED',
  UPDATE_CONTACT_PREFERENCES_SUCCESS = 'myAccount/flashMessages/id/UPDATE_CONTACT_PREFERENCES_SUCCESS',
  EXCHANGE_PRODUCTS = 'shoppingCart/flashMessages/id/EXCHANGE_PRODUCTS',
  EXCHANGE_PRODUCTS_LOGGED = 'shoppingCart/flashMessages/id/EXCHANGE_PRODUCTS_LOGGED',
  EXCHANGE_PRODUCTS_FAILED = 'shoppingCart/flashMessages/id/EXCHANGE_PRODUCTS_FAILED',
  MISSING_DELIVERY_CARRIER_CHOICES = 'customerFinanceOperations/flashMessages/id/MISSING_DELIVERY_CARRIER_CHOICES',
  MISSING_DELIVERY_RELAY_POINT = 'customerFinanceOperations/flashMessages/id/MISSING_DELIVERY_RELAY_POINT',
  DELIVERY_WAREHOUSE_CHANGED = 'customerFinanceOperations/flashMessages/id/DELIVERY_WAREHOUSE_CHANGED',
  BLACKLISTED_ZIPCODE = 'customerFinanceOperations/flashMessages/id/BLACKLISTED_ZIPCODE',
  NO_DELIVERY_OPTION = 'customerFinanceOperations/flashMessages/id/NO_DELIVERY_OPTION',
  INCOMPLETE_DELIVERY_OPTION = 'customerFinanceOperations/flashMessages/id/INCOMPLETE_DELIVERY_OPTION',
  DELIVERY_POSTAL_CODE_BLACKLISTED = 'customerFinanceOperations/flashMessages/id/DELIVERY_POSTAL_CODE_BLACKLISTED',
  UPDATE_SUBSCRIPTION_SAVED_CARD_FAILED = 'proB2BAnimation/flashMessages/subscriptionManagement/UPDATE_SUBSCRIPTION_SAVED_CARD_FAILED',
  UPDATE_SUBSCRIPTION_SAVED_CARD_SUCCESS = 'proB2BAnimation/flashMessages/subscriptionManagement/UPDATE_SUBSCRIPTION_SAVED_CARD_SUCCESS',
  ADD_NEW_SUBSCRIPTION_CARD_FAILED = 'proB2BAnimation/flashMessages/subscriptionManagement/ADD_NEW_SUBSCRIPTION_CARD_FAILED',
  ADD_NEW_SUBSCRIPTION_CARD_SUCCESS = 'proB2BAnimation/flashMessages/subscriptionManagement/ADD_NEW_SUBSCRIPTION_CARD_SUCCESS',
}

export enum AlertSeverityType {
  INFO = 'info',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
}

export const severityLowToHighOrder: Array<AlertSeverityType> = [
  AlertSeverityType.INFO,
  AlertSeverityType.WARNING,
  AlertSeverityType.SUCCESS,
  AlertSeverityType.DANGER,
];

export interface AlertMessageActionConfig {
  type: string;
  payload: any;
}

export interface AlertMessage {
  id: string;
  severity: AlertSeverityType;
  title?: string;
  message: string;
  actionText?: string;
  actionUrl?: string;
  actionConfig?: AlertMessageActionConfig;
  onActionClick?: () => void;
  onClose?: (...arg: any) => never;
  closeButtonLabel?: string;
}

export interface AddAlertMessageAction {
  type: typeof ADD_FLASH_MESSAGE;
  alertMessage: AlertMessage;
}

export interface AlertMessageVisualStateAction {
  type: typeof SHOW_FLASH_MESSAGE | typeof CLEAR_FLASH_MESSAGE;
  visible: boolean;
}

export interface AlertMessageActionCallbackAction {
  type: typeof FLASH_MESSAGE_ACTION;
  actionConfig: AlertMessageActionConfig;
}

export type AlertMessageActions =
  | AddAlertMessageAction
  | AlertMessageVisualStateAction
  | AlertMessageActionCallbackAction;

export const addAlertMessage = (alertMessage: AlertMessage) => ({
  type: ADD_FLASH_MESSAGE,
  alertMessage,
});

export const closeCurrentAlertMessage = () => ({
  type: CLEAR_FLASH_MESSAGE,
});

export const handleAlertMessageAction = (
  actionConfig: AlertMessageActionConfig | undefined,
) => ({
  type: FLASH_MESSAGE_ACTION,
  actionConfig,
});
