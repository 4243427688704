/* eslint-disable camelcase */
import type { Product } from '@/productDiscovery/Recommendation/interfaces/product';

export const initialState: ProductHistoryState = {
  fetching: false,
  fetched: false,
  productHistory: [],
};

export interface PlaylistHistory {
  id: number;
  products: Product[];
  title?: string;
}

export interface ProductHistoryItem {
  productId: number;
  viewedAt: string;
}

interface RecommendationsFromProductHistory {
  [key: string]: number[];
}

export type ProductHistory = ProductHistoryItem[];

export interface ApiProductHistoryItem {
  viewed_at: string;
  product_id: number;
}

export interface ProductHistoryState {
  error?: string | boolean;
  fetching: boolean;
  fetched: boolean;
  playlistHistory?: PlaylistHistory[]; // to change when we know what we use use btw algolia and rhino;
  productHistory: ProductHistory;
  errorPlaylist?: string | boolean | null;
  recommendationsFromProductHistory?: RecommendationsFromProductHistory;
}
