export interface SecondHandProductInfo {
  isSecondHand: boolean;
  secondHandTitle: string;
}

export enum ProductCondition {
  VERY_GOOD = 'VERY_GOOD',
  GOOD = 'GOOD',
}

export enum ProductSecondHandContext {
  NEVER_USED = 'NEVER_USED',
  REFURBISHED = 'REFURBISHED',
  USED = 'USED',
}

const productSecondHandContexts = [
  {
    regexp: 'PRODUIT RECONDITIONN(E|É)',
    replacement: '[RECONDITIONNÉ]',
    value: ProductSecondHandContext.REFURBISHED,
  },
  {
    regexp: "PRODUIT D('|’)OCCASION",
    replacement: '[OCCASION]',
    value: ProductSecondHandContext.USED,
  },
  {
    regexp: 'JAMAIS UTILIS(E|É)',
    replacement: '[JAMAIS UTILISÉ]',
    value: ProductSecondHandContext.NEVER_USED,
  },
];

const productConditions = [
  {
    stringToFind: 'Bon état',
    value: ProductCondition.GOOD,
  },
  {
    stringToFind: 'Très bon état',
    value: ProductCondition.VERY_GOOD,
  },
];
const productConditionsRegexps = productConditions.map((conditon) => ({
  regexp: new RegExp(`(\\s)*(-\\s*${conditon.stringToFind}\\s*)`),
  value: conditon.value,
}));

const secondHandRegexp = productSecondHandContexts.map((context) => {
  const regexpWithBrackets = `(\\[|\\()(\\s*${context.regexp}\\s*)(\\]|\\))`;
  return {
    ...context,
    regexpWithBrackets: new RegExp(regexpWithBrackets),
    regexpWithSpaces: new RegExp(`( )*${regexpWithBrackets}( )*`, 'i'),
  };
});

export const isSecondHand = (title: string | undefined) =>
  title
    ? secondHandRegexp.some((check) => check.regexpWithSpaces.test(title))
    : false;

const findSecondHandContext = (title: string) =>
  secondHandRegexp.find((check) => check.regexpWithSpaces.test(title));

export const getSecondHandInfo = (title: string) => {
  const secondHandContext = findSecondHandContext(title);

  if (!secondHandContext) {
    return {
      isSecondHand: false,
      secondHandTitle: title,
      secondHandFullTitle: title,
    };
  }
  let secondHandTitle = title.replace(secondHandContext.regexpWithSpaces, ' ');

  const productCondition = productConditionsRegexps.find((conditionRegexp) =>
    conditionRegexp.regexp.test(title),
  );

  if (productCondition) {
    secondHandTitle = secondHandTitle.replace(productCondition.regexp, ' ');
  }

  return {
    isSecondHand: true,
    productCondition: productCondition?.value,
    context: secondHandContext.value,
    secondHandTitle: secondHandTitle.trim(),
    secondHandFullTitle: title.replace(
      secondHandContext.regexpWithBrackets,
      secondHandContext.replacement,
    ),
  };
};

export const hasSecondHandProduct = (
  productNames: (string | undefined)[] | undefined,
) => !!productNames?.some(isSecondHand);
