import Cookie from 'js-cookie';
import { useSession } from 'next-auth/react';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useBooleanFlags } from '@/core/flags/flags.hooks';
import {
  checkAuthTokenError,
  checkAuthTokenSuccess,
} from '@/customerManagement/auth/actions';
import {
  isCurrentPageProtected,
  signIn,
  signOut,
} from '@/customerManagement/auth/services/client';
import {
  MISSING_TOKEN,
  REFRESH_ACCESS_TOKEN_ERROR,
} from '@/customerManagement/auth/services/constants';
import { getCookieDomain } from '@/domains/core/httpClient/getCookieDomain';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { ABTests } from '@/domains/proB2BAnimation/abtests';

import { NEW_SESSION_EVENT } from '../contants';
import { LOGIN_SUCCESS_EVENT } from '../gtm';

export const SessionStore: FunctionComponent = () => {
  const session = useSession();
  const dispatch = useDispatch();
  const [isUnifiedPlatformEnabled] = useBooleanFlags([ABTests.PEPE_UNIFY_AUTH]);

  useEffect(() => {
    if (
      session?.data?.error &&
      [REFRESH_ACCESS_TOKEN_ERROR, MISSING_TOKEN].includes(session.data.error)
    ) {
      if (isCurrentPageProtected()) {
        signIn({ unified: isUnifiedPlatformEnabled }); // Force sign in to obtain a new set of access and refresh tokens
      } else {
        signOut();
      }
    }
  }, [session?.data?.error, isUnifiedPlatformEnabled]);

  useEffect(() => {
    if (
      session.status === 'authenticated' &&
      session.data &&
      !session?.data?.error
    ) {
      dispatch(checkAuthTokenSuccess(session.data));

      // If session is new, send event to GTM
      if (Cookie.get(NEW_SESSION_EVENT)) {
        Gtm.push(LOGIN_SUCCESS_EVENT);

        Cookie.remove(NEW_SESSION_EVENT, {
          domain: getCookieDomain(),
          path: '/',
          sameSite: 'Lax',
        });
      }
    }
    if (session.status === 'unauthenticated' || session?.data?.error) {
      dispatch(checkAuthTokenError());
    }
  }, [dispatch, session.data, session.status]);

  return null;
};

SessionStore.displayName = 'SessionStore';
