export enum Cookies {
  MANOMANO_AB_TEST_ID = 'manomano_ab_test_id',
  MASS_LANDING_VERSION = 'mass_landing_version',
  /** Used to redirect to B2B website if it's `true` */
  MM_B2B = 'mm_b2b',
  MM_FLAG_OVERRIDES = 'mm_flag_overrides',
  MM_FLAGS = 'mm_flags',
  MM_FLAGS_VERSION = 'mm_flags_version',
  MM_OVERRIDE = 'mm_override',

  // AUTH
  /** Used to authenticate the user in the backend (micro-services). */
  MM_ACCESS_TOKEN = 'mm_access_token',
  /**
   * Handled by Next-Auth to redirect the user to the correct page after
   * the authentication process.
   */
  MM_AUTH_CALLBACK_URL = 'mm_auth_callback_url',
  /** Handled by Next-Auth to store the session token. */
  MM_NEXTAUTH_SESSION_TOKEN = 'next-auth.session-token',
  MM_SESSION_ID = 'mm_session_id',
  MM_VISITOR_ID = 'mm_visitor_id',
}
