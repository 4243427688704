import type { Router } from 'next/router';
import React, { useState } from 'react';
import { Provider } from 'react-redux';
import type { AnyAction, Reducer } from 'redux';

import { useHydrateManager } from '@/domains/core/applicationState/hooks/useHydrateManager';
import { useReducersManager } from '@/domains/core/applicationState/hooks/useReducersManager';
import { getStateFromPath } from '@/domains/core/applicationState/util/state';
import { getLocale } from '@/domains/core/settings/utils';
import { useIsomorphicLayoutEffect } from '@/domains/core/tamagoshi/hooks/useIsomorphicLayoutEffect';
import { DesignSystemProvider } from '@/domains/core/tamagoshiTailwind/context/context';

import { StoreManagerContext } from '../hooks/useStoreManager';
import StoreManager from '../store/StoreManager';

interface Props extends React.PropsWithChildren {
  initialReduxState: Record<string, unknown>;
  reducersPaths: string[];
  startupActions?: AnyAction[];
  startupReducers?: Map<
    string,
    Record<string, Reducer<any, any>> | Reducer<any, any>
  >;
  startupSagas?: () => Generator;
  router?: Router;
}

const createStoreManager = (
  startupReducers: Props['startupReducers'],
  startupSagas: Props['startupSagas'],
  reducersPaths: Props['reducersPaths'],
  initialReduxState: Props['initialReduxState'],
) => {
  const replaceableReducers = new Map();

  reducersPaths.forEach((key) => {
    replaceableReducers.set(
      key,
      (state = getStateFromPath(key, initialReduxState)) => state,
    );
  });

  const reducers = new Map();
  startupReducers?.forEach((value, key) => {
    reducers.set(key, value);
  });

  return new StoreManager(
    startupSagas,
    replaceableReducers,
    reducers,
    initialReduxState,
  );
};

export const ApplicationState: React.FC<Props> = ({
  initialReduxState,
  reducersPaths = [],
  startupReducers,
  startupSagas,
  startupActions = [],
  children,
  router,
}) => {
  const [storeManager] = useState(() =>
    createStoreManager(
      startupReducers,
      startupSagas,
      reducersPaths,
      initialReduxState,
    ),
  );

  // We cannot use useRouter to get the router here because it won't be available in federated apps
  // That's why it has to be passed as a prop
  // TODO: once we get rid of module federation we can remove the router prop and use useRouter again
  useReducersManager(storeManager, reducersPaths, initialReduxState, router);
  useHydrateManager(storeManager, initialReduxState, reducersPaths, router);

  useIsomorphicLayoutEffect(() => {
    startupActions.map((action) => storeManager.store.dispatch(action));
  }, [storeManager, startupActions]);

  return (
    <StoreManagerContext.Provider value={storeManager}>
      <Provider store={storeManager.store}>
        <DesignSystemProvider locale={getLocale()}>
          {children}
        </DesignSystemProvider>
      </Provider>
    </StoreManagerContext.Provider>
  );
};
