import { CSideScript } from '@cside.dev/next';

import { useFlags } from '@/domains/core/flags/flags.hooks';
import { IS_PROD } from '@/domains/core/platform/constants';

const CSIDE_ENABLED_FLAG = 'csideEnabled';

export function CSide() {
  const [enabled = false] = useFlags([CSIDE_ENABLED_FLAG]);

  if (!enabled || !IS_PROD) {
    return null;
  }

  return <CSideScript />;
}

CSide.displayName = 'CSide';
