import type { Session } from 'next-auth';
import type { Action } from 'redux';

import { UpdateOptinStatusSuccess } from '@/domains/care/myAccount/myAccount.actions';

import type { CustomerOptinStatus, CustomerSegment } from './model';

export const domain = 'customerManagement/auth';

// Token
export const CHECK_AUTH_TOKEN = `${domain}/CHECK_AUTH_TOKEN`;
export const CHECK_AUTH_TOKEN_SUCCESS = `${domain}/CHECK_AUTH_TOKEN_SUCCESS`;
export const CHECK_AUTH_TOKEN_ERROR = `${domain}/CHECK_AUTH_TOKEN_ERROR`;

// Customer Segment
export const GET_CUSTOMER_SEGMENT_REQUEST = `${domain}/GET_CUSTOMER_SEGMENT_REQUEST`;
export const GET_CUSTOMER_SEGMENT_SUCCESS = `${domain}/GET_CUSTOMER_SEGMENT_SUCCESS`;
export const GET_CUSTOMER_SEGMENT_ERROR = `${domain}/GET_CUSTOMER_SEGMENT_ERROR`;
export const PATCH_CUSTOMER_SEGMENT_SUCCESS = `${domain}/PATCH_CUSTOMER_SEGMENT_SUCCESS`;

export type GetCustomerSegmentRequestAction = Action<
  typeof GET_CUSTOMER_SEGMENT_REQUEST
>;

export const getCustomerSegment = (): GetCustomerSegmentRequestAction => ({
  type: GET_CUSTOMER_SEGMENT_REQUEST,
});

// Customer Marketing Consent
export const GET_CUSTOMER_OPTIN_STATUS_SUCCESS = `${domain}/GET_CUSTOMER_OPTIN_STATUS_SUCCESS`;

export interface CheckAuthToken {
  type: typeof CHECK_AUTH_TOKEN;
}

export const checkAuthToken = (): CheckAuthToken => ({
  type: CHECK_AUTH_TOKEN,
});

export interface CheckAuthTokenSuccess {
  type: typeof CHECK_AUTH_TOKEN_SUCCESS;
  payload: Session;
}

export const checkAuthTokenSuccess = (
  session: Session,
): CheckAuthTokenSuccess => ({
  type: CHECK_AUTH_TOKEN_SUCCESS,
  payload: session,
});

export interface CheckAuthTokenError {
  type: typeof CHECK_AUTH_TOKEN_ERROR;
}

export const checkAuthTokenError = (): CheckAuthTokenError => ({
  type: CHECK_AUTH_TOKEN_ERROR,
});

export interface GetCustomerSegmentSuccess {
  type: typeof GET_CUSTOMER_SEGMENT_SUCCESS;
  payload: CustomerSegment;
}

export const getCustomerSegmentSuccess = (
  customerSegment: CustomerSegment,
): GetCustomerSegmentSuccess => ({
  type: GET_CUSTOMER_SEGMENT_SUCCESS,
  payload: customerSegment,
});

export interface GetCustomerSegmentError {
  type: typeof GET_CUSTOMER_SEGMENT_ERROR;
}

export const getCustomerSegmentError = (): GetCustomerSegmentError => ({
  type: GET_CUSTOMER_SEGMENT_ERROR,
});

export interface PatchCustomerSegmentSuccess {
  type: typeof PATCH_CUSTOMER_SEGMENT_SUCCESS;
  payload: CustomerSegment;
}

export const patchCustomerSegmentSuccess = (
  customerSegment: CustomerSegment,
): PatchCustomerSegmentSuccess => ({
  type: PATCH_CUSTOMER_SEGMENT_SUCCESS,
  payload: customerSegment,
});

export interface GetCustomerOptinStatusSuccess {
  type: typeof GET_CUSTOMER_OPTIN_STATUS_SUCCESS;
  payload: CustomerOptinStatus;
}

export const getCustomerOptinStatusSuccess = (
  customerOptinStatus: CustomerOptinStatus,
): GetCustomerOptinStatusSuccess => ({
  type: GET_CUSTOMER_OPTIN_STATUS_SUCCESS,
  payload: customerOptinStatus,
});

type ActionTypeToPayloadMap<T extends { type: string; payload?: any }> = {
  [K in T['type']]: T extends { type: K } ? T['payload'] : never;
};

export type ActionsTypes = ActionTypeToPayloadMap<
  | CheckAuthToken
  | CheckAuthTokenSuccess
  | CheckAuthTokenError
  | GetCustomerSegmentSuccess
  | GetCustomerSegmentError
  | PatchCustomerSegmentSuccess
  | GetCustomerOptinStatusSuccess
  | UpdateOptinStatusSuccess
>;
