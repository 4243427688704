import {
  createContext,
  useContext,
  useMemo,
  type PropsWithChildren,
} from 'react';

import { type Locale } from '@/core/tamagoshiTailwind/interfaces';

interface DesignSystemContextType {
  locale: Locale;
}

const DesignSystemContext = createContext<DesignSystemContextType | undefined>(
  undefined,
);

export const DesignSystemProvider = ({
  locale,
  children,
}: PropsWithChildren<{ locale: Locale }>) => {
  const value = useMemo(() => ({ locale }), [locale]);

  return (
    <DesignSystemContext.Provider value={value}>
      {children}
    </DesignSystemContext.Provider>
  );
};

export const useDesignSystem = (): DesignSystemContextType => {
  const context = useContext(DesignSystemContext);
  if (!context) {
    throw new Error(
      'useDesignSystem must be used within a DesignSystemProvider',
    );
  }
  return context;
};
