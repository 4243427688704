import type { Reducer } from 'redux';

import coreReducer from '@/domains/core/core.reducer';
import trackingReducer from '@/domains/core/tracking/tracking.reducer';
import authReducer from '@/domains/customerManagement/auth/reducer';
import customerProfileReducer from '@/domains/customerManagement/customerProfile/reducer';
import platformConfigReducer from '@/domains/proB2BServices/platformConfig/platformConfig.reducer';
import { reducer as commercialAnimationReducer } from '@/domains/productDiscovery/CommercialAnimation/CommercialAnimation.reducer';
import { reducer as headerReducer } from '@/domains/productDiscovery/HeaderLeonidas/Header.static.reducer';
import { PRODUCT_HISTORY } from '@/domains/productDiscovery/Recommendation/modules/ProductHistory/constants';
import productHistoryReducer from '@/domains/productDiscovery/Recommendation/modules/ProductHistory/ProductHistory.reducer';
import flashMessageReducer from '@/domains/shoppingCart/FlashMessages/FlashMessages.reducer';
import { partnerAdThemeReducer } from '@/sellerAdsManagement/PartnerAdTheme/partnerAdTheme.reducer';

export const staticReducers = new Map<
  string,
  Record<string, Reducer<any, any>> | Reducer<any, any>
>();
staticReducers.set('core', coreReducer);
staticReducers.set('customerManagement.auth', authReducer);
staticReducers.set(
  'customerManagement.customerProfile',
  customerProfileReducer,
);
staticReducers.set('proB2BServices.platformConfig', platformConfigReducer);
staticReducers.set('productDiscovery.header', headerReducer); // needed for 404 page
staticReducers.set(
  'productDiscovery.commercialAnimation',
  commercialAnimationReducer,
);
staticReducers.set('sellerAdsManagement.partnerAdTheme', partnerAdThemeReducer);
staticReducers.set('shoppingCart.flashMessage', flashMessageReducer);
staticReducers.set('tracking.user', trackingReducer);
staticReducers.set(
  `productDiscovery.${PRODUCT_HISTORY}`,
  productHistoryReducer,
);
