import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import type {
  FetchHeaderBannersFailed,
  FetchHeaderBannersSuccess,
  FetchingHeaderBanners,
} from '@/domains/productDiscovery/HeaderLeonidas/components/HeaderBanner/HeaderBanners.actions';
import type { PreHeaderActions } from '@/domains/productDiscovery/HeaderLeonidas/components/PreHeader/PreHeader.actions';
import type { HeaderMenuModelFromApi } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/data.model';
import type {
  HeaderInitModel,
  QuickAccessLinkModel,
} from '@/domains/productDiscovery/HeaderLeonidas/interfaces/HeaderLeonidas.server.model';

export const GET_HEADER_INIT = 'productDiscovery/Header/GET_HEADER_INIT';
export const GET_HEADER_INIT_SUCCESS =
  'productDiscovery/HeaderLeonidas/GET_HEADER_INIT_SUCCESS';
export const GET_HEADER_INIT_FAILURE =
  'productDiscovery/HeaderLeonidas/GET_HEADER_INIT_FAILURE';

export const GET_MENU_CAMPAIGNS = 'productDiscovery/Header/GET_MENU_CAMPAIGNS';
export const GET_MENU_CAMPAIGNS_SUCCESS =
  'productDiscovery/Header/GET_MENU_CAMPAIGNS_SUCCESS';
export const GET_MENU_CAMPAIGNS_FAILED =
  'productDiscovery/Header/GET_MENU_CAMPAIGNS_FAILED';

export const GET_QUICK_ACCESS_LINKS =
  'productDiscovery/HeaderLeonidas/GET_QUICK_ACCESS_LINKS';
export const GET_QUICK_ACCESS_LINKS_SUCCESS =
  'productDiscovery/HeaderLeonidas/GET_QUICK_ACCESS_LINKS_SUCCESS';
export const GET_QUICK_ACCESS_LINKS_FAILURE =
  'productDiscovery/HeaderLeonidas/GET_QUICK_ACCESS_LINKS_FAILURE';

export const GET_HEADER_MENU = 'productDiscovery/Header/GET_HEADER_MENU';
export const GET_HEADER_MENU_SUCCESS =
  'productDiscovery/Header/GET_HEADER_MENU_SUCCESS';
export const GET_HEADER_MENU_FAILED =
  'productDiscovery/Header/GET_HEADER_MENU_FAILED';

export interface GetHeaderInit {
  type: typeof GET_HEADER_INIT;
}

export interface GetHeaderInitSuccess {
  type: typeof GET_HEADER_INIT_SUCCESS;
  data: HeaderInitModel;
}

export interface GetHeaderInitFailure {
  type: typeof GET_HEADER_INIT_FAILURE;
  error: string;
}

export interface GetQuickAccessLinks {
  type: typeof GET_QUICK_ACCESS_LINKS;
}

export interface GetQuickAccessLinksSuccess {
  type: typeof GET_QUICK_ACCESS_LINKS_SUCCESS;
  data: QuickAccessLinkModel[];
}

export interface GetQuickAccessLinksFailure {
  type: typeof GET_QUICK_ACCESS_LINKS_FAILURE;
  error: string;
}

export interface GetHeaderMenu {
  type: typeof GET_HEADER_MENU;
}

export interface GetHeaderMenuSuccess {
  type: typeof GET_HEADER_MENU_SUCCESS;
  data: HeaderMenuModelFromApi;
}

export interface GetHeaderMenuFailed {
  type: typeof GET_HEADER_MENU_FAILED;
  error: string;
}

export interface GetMenuCampaigns {
  type: typeof GET_MENU_CAMPAIGNS;
  categoryId: number;
  familyId: number;
}

export interface GetMenuCampaignsSuccess {
  type: typeof GET_MENU_CAMPAIGNS_SUCCESS;
  categoryId: number;
  campaigns: BannerCampaign[];
}

export interface GetMenuCampaignsFailed {
  type: typeof GET_MENU_CAMPAIGNS_FAILED;
  error: string;
}

export const headerInitActions: Action[] = [
  {
    type: GET_HEADER_INIT,
  },
];

export const getMenuCampaigns = (
  categoryId: number,
  familyId: number,
): GetMenuCampaigns => ({
  type: GET_MENU_CAMPAIGNS,
  categoryId,
  familyId,
});

export type Action =
  | GetHeaderMenu
  | GetHeaderMenuSuccess
  | GetHeaderMenuFailed
  | GetHeaderInit
  | GetHeaderInitSuccess
  | GetHeaderInitFailure
  | GetQuickAccessLinks
  | GetQuickAccessLinksSuccess
  | GetQuickAccessLinksFailure
  | FetchHeaderBannersSuccess
  | FetchHeaderBannersFailed
  | FetchingHeaderBanners
  | PreHeaderActions
  | GetMenuCampaigns
  | GetMenuCampaignsSuccess
  | GetMenuCampaignsFailed;
