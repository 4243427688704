import type { Action } from '@/domains/core/applicationState/interfaces';
import { fetchAndOverrideFlagValuesAndFlagVariationsClientAction } from '@/domains/core/flags/flags.actions';
import { Cookies } from '@/domains/core/httpClient/cookies';
import { IS_CLIENT_SIDE } from '@/domains/core/settings/constants';
import { checkAuthToken } from '@/domains/customerManagement/auth/actions';

const getCookieValue = (name: string) => {
  const rawValue =
    document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';

  return decodeURIComponent(rawValue).trim();
};

export function getRootActions(): Action[] {
  const actions: Action[] = [checkAuthToken()];

  if (IS_CLIENT_SIDE) {
    const flagOverrideCookieValue = getCookieValue(Cookies.MM_FLAG_OVERRIDES);
    const visitorId = getCookieValue(Cookies.MM_VISITOR_ID);
    actions.push(
      fetchAndOverrideFlagValuesAndFlagVariationsClientAction({
        flagOverrideCookieValue,
        visitorId,
      }),
    );
  }

  return [...(IS_CLIENT_SIDE ? actions : [])];
}
