export const ABTASTY_SCRIPT_LOADING = 'core/tracking/ABTASTY_SCRIPT_LOADING';
export const ABTASTY_SCRIPT_LOADED = 'core/tracking/ABTASTY_SCRIPT_LOADED';
export const ABTASTY_SCRIPT_CANCELLED =
  'core/tracking/ABTASTY_SCRIPT_CANCELLED';
export const ABTASTY_TAG_EXECUTED = 'core/tracking/ABTASTY_TAG_EXECUTED';

export interface ABTastyLoadAction {
  type: typeof ABTASTY_SCRIPT_LOADING;
}

export interface ABTastyLoadedAction {
  type: typeof ABTASTY_SCRIPT_LOADED;
}

export interface ABTastyCancelledAction {
  type: typeof ABTASTY_SCRIPT_CANCELLED;
}

export type ABTastyActions =
  | ABTastyLoadAction
  | ABTastyLoadedAction
  | ABTastyCancelledAction;
